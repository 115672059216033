import { MentalHealthReport } from "./MentalHealthReport";
import { PrakritiTestResult } from "./PrakritiTestResult";
import VikrutiTestResult from "./VikrutiTestResult";

interface Props {
  type: string;
  data: any;
}

export function TestResultFactory({ type, data }: Props) {
  const getTestComponent = () => {
    const normalizedType = type.toLowerCase().replace(/[^a-z_]/g, "");

    console.log("normalizedType", normalizedType);
    switch (normalizedType) {
      case "prakriti":
        return <PrakritiTestResult data={data} />;
      case "vikriti":
        return <VikrutiTestResult data={data} />;
      case "vikriti_light":
        return <VikrutiTestResult data={data} />;
      case "manas":
        return <MentalHealthReport data={data} />;
      default:
        return (
          <div className="p-4 bg-yellow-100 rounded-lg">
            <p>Unknown test type: {type}</p>
          </div>
        );
    }
  };

  return getTestComponent();
}
