import { useApi } from "@/hooks/useApi";

export const useSpeechService = () => {
  const api = useApi();

  const sendAudioForTranscriptionGoogleV1 = async (
    audioBlob: Blob, 
    options?: {
      languageCode?: string;
      alternativeLanguageCodes?: string[];
      enableSpeakerDiarization?: boolean;
    }
  ): Promise<string> => {
    const formData = new FormData();
    formData.append('audio', audioBlob, 'audio.mp3');
    
    // Add optional parameters if provided
    if (options?.languageCode) {
      formData.append('languageCode', options.languageCode);
    }
    if (options?.alternativeLanguageCodes?.length) {
      options.alternativeLanguageCodes.forEach(code => {
        formData.append('alternativeLanguageCodes', code);
      });
    }
    if (options?.enableSpeakerDiarization !== undefined) {
      formData.append('enableSpeakerDiarization', options.enableSpeakerDiarization.toString());
    }

    try {
      const response = await api.post('/speech-transcribe/speech-to-text', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return response.data.transcription;
    } catch (error) {
      console.error('Error in speech to text conversion:', error);
      throw error;
    }
  };

  const sendAudioForTranscriptionGoogleV2 = async (audioBlob: Blob): Promise<string> => {
    const formData = new FormData();
    formData.append('audio', audioBlob, 'audio.webm');

    try {
      const response = await api.post('/speech-transcribe/google-speech-v2', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return response.data.transcription;
    } catch (error) {
      console.error('Error in speech to text conversion:', error);
      throw error;
    }
  };

  return {
    sendAudioForTranscriptionGoogleV1,
    sendAudioForTranscriptionGoogleV2,
  };
};
