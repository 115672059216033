import React from 'react'
import { Button } from '../ui/button';
import { ChatMode, ModeButton } from '@/types/global';
interface ModesProps {
    availableModes: ModeButton[];
    currentModeState: ChatMode;
    activeModeRef: React.RefObject<HTMLButtonElement>;
    onModeChange?: (mode: ChatMode) => void;
}

export const Modes: React.FC<ModesProps> = ({ availableModes, currentModeState, activeModeRef, onModeChange }) => {
    if (!availableModes || availableModes.length === 0) return null;

    return (
        <div className="flex flex-row-reverse gap-2 px-2 py-1">
            {availableModes.map((modeBtn) => (
                <Button
                    key={modeBtn.mode}
                    ref={currentModeState === modeBtn.mode ? activeModeRef : null}
                    onClick={() => {
                        if (modeBtn.mode !== currentModeState) {
                            onModeChange?.(modeBtn.mode);
                        }
                    }}
                    disabled={modeBtn.modeDisabled}
                    className={`
                transition-all duration-200 flex items-center gap-2 whitespace-nowrap shrink-0
                hover:bg-[var(--primary)] hover:text-[var(--secondary-text)]
                ${currentModeState === modeBtn.mode
                            ? 'bg-[var(--primary)] text-[var(--secondary-text)] shadow-sm'
                            : 'bg-[var(--background)] text-[var(--primary-text)]'
                        }
              `}
                    variant={currentModeState === modeBtn.mode ? "default" : "ghost"}
                    size="sm"
                >
                    <modeBtn.icon className="h-4 w-4" />
                    {modeBtn.label}
                </Button>
            ))}
        </div>
    );
};
