import { useChatService } from "@/services/useChatService";
import { useState, useCallback, useEffect, useMemo, useRef } from "react";
import { useGetPatientById, usePatientService, useAISessionInteractions } from "@/services/Patient/patientService";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Symptom, AIInteraction, CaseData, AIResponse, DiagnosisItem, PatientDetails } from "@/types/doctor/globals";
import { useImmer } from 'use-immer';
import { useAuth } from "@/contexts/AuthContext";
import { ChatMode, ChatPayload } from "@/types/global";

export const useAnalysis = () => {
  const { patientId } = useParams<{ patientId: string }>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { user } = useAuth();

  const [chatHistory, setChatHistory] = useState<AIInteraction[]>([]);
  const [isAITyping, setIsAITyping] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);

  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedDiagnosis, setSelectedDiagnosis] = useState<DiagnosisItem[]>([]);

  // Track sessions for each mode
  const [modeSessions, setModeSessions] = useState<Record<string, string>>({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('patientInfo');

  const chatService = useChatService();
  const { data: patientResponse, isLoading: isPatientLoading, error: patientError } = useGetPatientById(patientId || '');
  const { updatePatient, analyzeSymptoms } = usePatientService();
  const [currentMode, setCurrentMode] = useState<ChatMode>('patientInfo');

  const tabsRef = useRef<Record<string, HTMLButtonElement | null>>({});

  const [caseData, updateCaseDataImmer] = useImmer<CaseData>({
    patientDetails: {
      firstName: "",
      lastName: "",
      age: "",
      gender: "",
      weight: "",
      height: "",
      status: "",
      visitDate: "",
      symptoms: [],
      medicalHistory: [],
      familyHistory: [],
      diagnosis: '',
      treatment: '',
    },
    symptoms: [],
    medicalHistory: [],
    familyHistory: [],
    diagnosis: '',
    treatment: '',
    initialAnalysis: '',
    examination: '',
    investigation: '',
  });
  const unloadRef = useRef<((event: BeforeUnloadEvent) => void) | null>(null);

  const symptomAnalysisMutation = useMutation({
    mutationFn: async (symptoms: [string, string, string, string][]) => {
      // First attempt
      const result = await analyzeSymptoms({
        symptoms,
        includeIntensity: true,
        includePrognosis: true,
        includeOnset: true,
      });
      
      // If first attempt returns empty array, do ONE retry with simplified params
      if (Array.isArray(result.data) && result.data.length === 0) {
        // Second and final attempt - this result will be returned regardless of being empty or not
        const retryResult = await analyzeSymptoms({
          symptoms,
          includeIntensity: false,
          includePrognosis: false,
          includeOnset: false,
        });
        return retryResult; // Final result - no more calls after this
      }
      
      return result; // First attempt was successful, return it
    },
    onSuccess: async (data) => {
      const prompt = `
      ### patient Symptom ### 
      ${JSON.stringify(caseData.symptoms, null, 2)}
      ### patient Medical History ### 
      ${JSON.stringify(caseData.medicalHistory, null, 2)}
      ### patient Family History ### 
      ${JSON.stringify(caseData.familyHistory, null, 2)}

      #### protocol filtered diseases ####
      ${JSON.stringify(data, null, 2)}
      `;
      const result = await chatService.processInput({
        doctorInput: [{
          type: 'text',
          text: prompt
        }],
        sessionId: modeSessions[currentMode] || '',
        mode: currentMode,
        variables: {}
      });

       // Update chat history
       setChatHistory(prev => [
        ...prev,
        { role: 'ai', content: result.aiResponse }
      ]);

      // Update case data
      updateCaseDataImmer(draft => {
        draft.initialAnalysis = result.aiResponse;
      });

      if (patientId && patientResponse?.data) {
        const patientSessions = patientResponse.data.aiSessions || [];
        const currentSession = patientSessions.find(session => session.type === currentMode);
        let updatedPatientDetails: PatientDetails = {
          ...patientResponse.data,
          aiSessions: [
            ...(patientResponse.data.aiSessions || []),
            {
              sessionId: result.sessionId || modeSessions[currentMode],
              type: currentMode,
              interactions: [
                ...(currentSession?.interactions || []),
                {
                  role: 'ai',
                  content: result.aiResponse,
                  timestamp: new Date().toISOString()
                }
              ]
            }
          ]
        };

        updatedPatientDetails = {
          ...updatedPatientDetails,
          initialAnalysis: result.aiResponse
        }
        updatePatient.mutate({
          patientId,
          patientDetails: updatedPatientDetails
        });
        setIsAITyping(false);
      }
    },
    onError: (error) => {
      console.error("Error analyzing symptoms:", error);
      toast.error("Failed to analyze symptoms. Please try again.");
      setChatHistory((prev) => [
        ...prev,
        { role: 'ai', content: "Sorry, I encountered an error analyzing the symptoms. Please try again." },
      ]);
      setIsAITyping(false);
    },
  });

  
  // Modified AI interaction mutation
  const aiInteractionMutation = useMutation<AIResponse, Error, string>({
    mutationFn: async (doctorInput: string) => {
      const contextData = {
        patientDetails: {
          firstName: caseData.patientDetails.firstName,
          lastName: caseData.patientDetails.lastName,
          age: caseData.patientDetails.age,
          gender: caseData.patientDetails.gender,
          weight: caseData.patientDetails.weight,
          height: caseData.patientDetails.height,
        },
        symptoms: caseData.symptoms,
        history: caseData.medicalHistory,
        familyHistory: caseData.familyHistory,
        initialAnalysis: caseData.initialAnalysis,
        diagnosis: caseData.diagnosis,
        treatment: caseData.treatment,
      };

      const processedInput: ChatPayload = {
        type: 'text',
        text: doctorInput
      };

      // Use mode-specific session ID
      const currentSessionId = modeSessions[currentMode] || '';

      const result = await chatService.processInput({
        doctorInput: [processedInput],
        sessionId: currentSessionId,
        mode: currentMode,
        variables: {
          ['latestdata']: JSON.stringify(contextData, null, 2),
        }
      });

      return result as AIResponse;
    },
    onMutate: (doctorInput) => {
      setIsAITyping(true);
      setChatHistory((prev) => [
        ...prev,
        { role: 'user', content: doctorInput },
      ]);
      setUnsavedChanges(true);
    },
    onSuccess: (result: AIResponse, doctorInput: string) => {
      // Update session ID for current mode
      if (result.sessionId) {
        setModeSessions(prev => ({
          ...prev,
          [currentMode]: result.sessionId || ''
        }));
      }

      // Update chat history
      setChatHistory(prev => [
        ...prev,
        { role: 'ai', content: result.aiResponse.response }
      ]);

      // Update case data
      updateCaseDataImmer(draft => {
        Object.assign(draft.patientDetails, result.aiResponse.patientDetails);
        draft.symptoms = result.aiResponse.symptoms;
        draft.medicalHistory = result.aiResponse.medicalhistory;
        draft.familyHistory = result.aiResponse.familyHistory;
        draft.diagnosis = result.aiResponse.diagnosis;
        draft.treatment = result.aiResponse.treatment;
      });

      // Update patient data
      if (patientId && patientResponse?.data) {
        const patientSessions = patientResponse.data.aiSessions || [];
        const currentSession = patientSessions.find(session => session.type === currentMode);
        let updatedPatientDetails: PatientDetails = {
          ...patientResponse.data,
          aiSessions: [
            ...(patientResponse.data.aiSessions || []),
            {
              sessionId: result.sessionId || modeSessions[currentMode],
              type: currentMode,
              interactions: [
                ...(currentSession?.interactions || []),
                {
                  role: 'user',
                  content: doctorInput,
                  timestamp: new Date().toISOString()
                },
                {
                  role: 'ai',
                  content: result.aiResponse.response,
                  timestamp: new Date().toISOString()
                }
              ]
            }
          ]
        };

        switch (currentMode) {
          case 'patientInfo':
            updatedPatientDetails = {
              ...updatedPatientDetails,
              ...result.aiResponse.patientDetails
            };
            break;
          case 'symptoms':
            updatedPatientDetails.symptoms = result.aiResponse.symptoms;
            updatedPatientDetails.medicalHistory = result.aiResponse.medicalhistory;
            updatedPatientDetails.familyHistory = result.aiResponse.familyHistory;
            break;
        }
        updatePatient.mutate({
          patientId,
          patientDetails: updatedPatientDetails
        });
      }
    },
    onError: (error) => {
      console.error("Error processing AI interaction:", error);
      toast.error("Failed to process AI interaction. Please try again.");
      setChatHistory((prev) => [
        ...prev,
        { role: 'ai', content: "Sorry, I encountered an error. Please try again." },
      ]);
    },
    onSettled: () => {
      setIsAITyping(false);
      queryClient.invalidateQueries({ queryKey: ['patient', patientId] });
    },
  });

  const handleAIInteraction = useCallback(
    (doctorInput: string) => {
      aiInteractionMutation.mutate(doctorInput);
    },
    [aiInteractionMutation]
  );

  const toggleChat = () => {
    setIsChatOpen(prevState => !prevState);
  };

  const breadcrumbItems = useMemo(() => {
    const patientName = `${caseData.patientDetails.firstName ?? ''} ${caseData.patientDetails.lastName ?? ''}`.trim();
    return [
      { label: 'Dashboard', href: '/doctor/dashboard' },
      { label: `${patientName || 'Loading...'}`, href: '#' },
    ];
  }, [caseData.patientDetails.firstName, caseData.patientDetails.lastName]);


  const updateSymptoms = useCallback((newSymptoms: Symptom[]) => {
    updateCaseDataImmer(draft => {
      draft.symptoms = newSymptoms;
    });
  }, [updateCaseDataImmer]);

  const updateCaseData = useCallback((updater: (draft: CaseData) => void) => {
    updateCaseDataImmer(draft => {
      updater(draft);
      setUnsavedChanges(true);
    });
  }, [updateCaseDataImmer]);

  const saveChanges = useCallback(() => {
    if (patientId && patientResponse?.data) {
      setIsSaving(true);
      const updatedData = {
        ...patientResponse.data,
        ...caseData.patientDetails,
        symptoms: caseData.symptoms,
        medicalHistory: caseData.medicalHistory,
        familyHistory: caseData.familyHistory,
        diagnosis: caseData.diagnosis,
        treatment: caseData.treatment
      };

      updatePatient.mutate({
        patientId,
        patientDetails: updatedData,
      }, {
        onSuccess: () => {
          setUnsavedChanges(false);
          toast.success("Changes saved successfully!");
        },
        onError: (error) => {
          console.error("Error saving changes:", error);
          toast.error("Failed to save changes. Please try again.");
        },
        onSettled: () => {
          setIsSaving(false);
        }
      });
    }
  }, [patientId, patientResponse, caseData, updatePatient]);

  const checkUnsavedChanges = useCallback(() => {
    return unsavedChanges;
  }, [unsavedChanges]);

  const handleSave = useCallback(() => {
    saveChanges();
    setIsDialogOpen(false);
    if (unloadRef.current) {
      window.removeEventListener('beforeunload', unloadRef.current);
    }
  }, [saveChanges]);

  const handleDiscard = useCallback(() => {
    setIsDialogOpen(false);
    if (unloadRef.current) {
      window.removeEventListener('beforeunload', unloadRef.current);
    }
  }, []);


  const handleModeChange = (newMode: ChatMode) => {
    setCurrentMode(newMode);
    // Map chat modes to tab values
    switch (newMode) {
      case 'patientInfo':
        setActiveTab('patientInfo');
        scrollToTab('patientInfo');
        break;
      case 'symptoms':
        setActiveTab('symptoms');
        scrollToTab('symptoms');
        break;
      case 'initialAnalysis':
        setActiveTab('initialAnalysis');
        scrollToTab('initialAnalysis');
        break;
      case 'examination':
        setActiveTab('examination');
        scrollToTab('examination');
        break;
      case 'investigation':
        setActiveTab('investigation');
        scrollToTab('investigation');
        break;
      case 'diagnosis':
        setActiveTab('diagnosis');
        scrollToTab('diagnosis');
        break;
      case 'treatment':
        setActiveTab('treatment');
        scrollToTab('treatment');
        break;
    }
  };

  const scrollToTab = useCallback((tabValue: string) => {
    const selectedTab = tabsRef.current[tabValue];
    if (selectedTab) {
      selectedTab.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center'
      });
    }
  }, []);

  const handleActiveTabs = (tab: string) => {
    setActiveTab(tab);
    scrollToTab(tab);
    switch (tab) {
      case 'patientInfo':
        setCurrentMode('patientInfo');
        break;
      case 'symptoms':
        setCurrentMode('symptoms');
        break;
      case 'initialAnalysis':
        setCurrentMode('initialAnalysis');
        break;
      case 'examination':
        setCurrentMode('examination');
        break;
      case 'investigation':
        setCurrentMode('investigation');
        break;
      case 'diagnosis':
        setCurrentMode('diagnosis');
        break;
      case 'treatment':
        setCurrentMode('treatment');
        break;
    }
  };

  useEffect(() => {
    if (patientResponse?.data) {
      updateCaseDataImmer(draft => {
        draft.patientDetails = patientResponse.data;
        draft.symptoms = patientResponse.data.symptoms || [];
        draft.medicalHistory = patientResponse.data.medicalHistory || [];
        draft.familyHistory = patientResponse.data.familyHistory || [];
        draft.diagnosis = patientResponse.data.diagnosis || '';
        draft.treatment = patientResponse.data.treatment || '';
        draft.initialAnalysis = patientResponse.data.initialAnalysis || '';
      });
    }
  }, [patientResponse, updateCaseDataImmer]);



  useEffect(() => {
    const handleBeforeUnloadWrapper = (event: BeforeUnloadEvent) => {
      if (checkUnsavedChanges()) {
        event.preventDefault();
        setIsDialogOpen(true);
        event.returnValue = '';
        return '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnloadWrapper);
    unloadRef.current = handleBeforeUnloadWrapper;

    return () => {
      if (unloadRef.current) {
        window.removeEventListener('beforeunload', unloadRef.current);
      }
    };
  }, [checkUnsavedChanges]);


  useEffect(() => {
    if (!patientId) {
      toast.error("Patient ID is missing. Redirecting to dashboard.");
      navigate("/doctor/dashboard");
    }
  }, [patientId, navigate]);

  useEffect(() => {
    if (patientError) {
      toast.error("Failed to load patient data. Please try again.");
      console.error("Error loading patient data:", patientError);
      navigate("/doctor/dashboard");
    }
  }, [patientError]);

  const doInitialAnalysis = () => {
    chatHistory.push({role:'ai',content:"performing initial analysis..."});
    setIsAITyping(true);
    symptomAnalysisMutation.mutate(caseData.symptoms.map(symptom => [symptom.name, symptom.intensity, symptom.onset, symptom.prognosis]) as [string, string, string, string][]);
  };

  useEffect(() => {
    if (patientId && currentMode && patientResponse) {
      let chatHistory: AIInteraction[] = [];
      const selectedSession = patientResponse.data.aiSessions?.find(session => session.type === currentMode);
      if (selectedSession) {
        if (selectedSession.interactions.length > 0) {
          selectedSession.interactions.forEach((interaction: AIInteraction) => {
            chatHistory.push(interaction);
          });
        }
      }else{
        switch(currentMode){
          case 'initialAnalysis':
            doInitialAnalysis();
            break;
        }
      }
      setChatHistory(chatHistory);
    }
  }, [currentMode, patientId, patientResponse]);

  // Initialize sessions from patient data
  useEffect(() => {
    if (patientResponse?.data?.aiSessions) {
      const sessions: Record<string, string> = {};
      patientResponse.data.aiSessions.forEach(session => {
        sessions[session.type] = session.sessionId;
      });
      setModeSessions(sessions);
    }
  }, [patientResponse]);

  const performAction = (command: string) => {
    if (command === 'doInitialAnalysis') {
      doInitialAnalysis();
    }
  };

  return {
    caseData,
    updateCaseData,
    saveChanges,
    unsavedChanges,
    chatHistory,
    isLoading: isPatientLoading || aiInteractionMutation.isPending,
    isAITyping,
    handleAIInteraction,
    isChatOpen,
    toggleChat,
    breadcrumbItems,
    updateSymptoms,
    isSaving,
    checkUnsavedChanges,
    isDialogOpen,
    setIsDialogOpen,
    handleSave,
    handleDiscard,
    isAnalyzing: symptomAnalysisMutation.isPending,
    selectedDiagnosis,
    setSelectedDiagnosis,
    currentMode,
    handleModeChange,
    modeSessions,
    activeTab,
    handleActiveTabs,
    tabsRef,
    performAction
  };
};
