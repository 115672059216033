import { AIInteraction, PatientDetails } from '@/types/doctor/globals';
import { AxiosResponse } from 'axios';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useApi } from '@/hooks/useApi';
import { PatientData } from '@/pages/Patient/Profile/useProfile';
import { DashboardData } from '@/types/patient/dashboard';

export const useAISessionInteractions = (patientId: string, sessionId: string) => {
  const api = useApi();
  return useQuery({
    queryKey: ['aiSession', patientId, sessionId],
    queryFn: () => api.get<AIInteraction[]>(`/patients/${patientId}/ai-sessions/${sessionId}`),
    retry: 0,
  });
};

export const usePatientService = (currentPage?: number, pageSize?: number) => {
  const api = useApi();
  const queryClient = useQueryClient();

  const createNewPatient = useMutation({
    mutationFn: (patientDetails: Partial<PatientDetails>): Promise<AxiosResponse<PatientDetails>> => {
      return api.post('/patients', patientDetails);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['patients'] });
    },
  });

  const getPatients = useQuery({
    queryKey: ['patients', currentPage, pageSize],
    queryFn: () => api.get<{
      items: PatientDetails[];
      pagination: {
        currentPage: number;
        perPage: number;
        total: number;
        totalPages: number;
        nextPage: number | null;
        previousPage: number | null;
      }
    }>(`/patients?page=${currentPage}&limit=${pageSize}`),
    enabled: currentPage !== undefined && pageSize !== undefined,
  });

  const updatePatient = useMutation({
    mutationFn: ({ patientId, patientDetails }: { patientId: string; patientDetails: Partial<PatientDetails> }): Promise<AxiosResponse<PatientDetails>> => {
      return api.patch(`/patients/${patientId}`, patientDetails);
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ['patient', variables.patientId] });
    },
  });

  const analyzeSymptoms = async (payload: {
    symptoms: [string, string, string, string][];
    includeIntensity: boolean;
    includePrognosis: boolean;
    includeOnset: boolean;
  }): Promise<AxiosResponse<any>> => {
    return api.post('/symptom-matcher/match-diseases', payload);
  };

  const getPatientProfile = useQuery({
    queryKey: ['patientProfile'],
    queryFn: (): Promise<AxiosResponse<PatientData>> => api.get('/profile'),
  });

  const updatePatientProfile = useMutation({
    mutationFn: (profileData: PatientData): Promise<AxiosResponse<PatientData>> => {
      return api.put('/profile', profileData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['patientProfile'] });
    },
  });



  const getDashboardData = useQuery({
    queryKey: ['patientDashboard'],
    queryFn: (): Promise<AxiosResponse<DashboardData>> => api.get('/dashboard'),
  });

  const addAIInteraction = useMutation({
    mutationFn: ({ patientId, interaction }: {
      patientId: string;
      interaction: {
        role: 'user' | 'ai';
        content?: string;
        action?: string;
        type: string;
        sessionId: string;
      }
    }) => {
      return api.post(`/patients/${patientId}/ai-interaction`, interaction);
    },
  });

  const deletePatient = useMutation({
    mutationFn: (patientId: string) => {
      return api.delete(`/patients/${patientId}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['patients'] });
    },
  });

  return {
    createNewPatient,
    getPatients,
    updatePatient,
    analyzeSymptoms,
    getPatientProfile,
    updatePatientProfile,
    getDashboardData,
    api,
    addAIInteraction,
    useAISessionInteractions,
    deletePatient,
  };
};

export const useGetPatientById = (patientId: string) => {
  const { api } = usePatientService(1, 10);
  
  return useQuery({
    queryKey: ['patient', patientId],
    queryFn: (): Promise<AxiosResponse<PatientDetails>> => api.get(`/patients/${patientId}`),
  });
};
