import React, { useRef, useState } from "react";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../ui/card";
import { ScrollArea, ScrollBar } from "../ui/scroll-area";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { Button } from "../ui/button";
import { SendIcon, X, Mic, MicOff, Loader2, User } from "lucide-react";
import { useChatUi } from "./useChatUi";
import { Textarea } from "@/components/ui/Textarea";
import { useEffect } from "react";
import { useAuth } from "@/contexts/AuthContext";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../ui/tooltip";
import { AIInteraction } from "@/types/doctor/globals";
import { CIRCLE_LOGO_URL, LOADING_LOGO_URL } from "@/constants";
import { Modes } from "../ChatComponents/Modes";
import { ChatMode } from "@/types/global";
import { ActionButton } from "@/types/global";
import { ModeButton } from "@/types/global";
import { Skeleton } from "../ui/skeleton";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

interface ChatUIProps {
  chatHistory: AIInteraction[];
  isLoading: boolean;
  isAITyping: boolean;
  onSendMessage: (message: string) => void;
  onClose: () => void;
  performAction: (action: string) => void;
  title?: string;
  description?: string;
  availableActions?: ActionButton[];
  availableModes?: ModeButton[];
  currentMode?: ChatMode;
  onModeChange?: (mode: ChatMode) => void;
  isSessionLoading?: boolean;
}

const TypingIndicator = () => (
  <div className="flex space-x-1">
    <div className="w-2 h-2 bg-muted-foreground rounded-full animate-bounce" style={{ animationDelay: '0s' }}></div>
    <div className="w-2 h-2 bg-muted-foreground rounded-full animate-bounce" style={{ animationDelay: '0.2s' }}></div>
    <div className="w-2 h-2 bg-muted-foreground rounded-full animate-bounce" style={{ animationDelay: '0.4s' }}></div>
  </div>
);

export const ChatUI: React.FC<ChatUIProps> = ({
  chatHistory,
  isLoading,
  isAITyping,
  onSendMessage,
  onClose,
  performAction,
  title = "Vedita your AI Assistant",
  description = "Chat with the AI about patient analysis",
  availableActions = [],
  availableModes = [],
  currentMode = "chat",
  onModeChange = (mode: ChatMode) => { },
  isSessionLoading = false
}) => {
  const {
    inputMessage,
    scrollAreaRef,
    messagesEndRef,
    sendMessage,
    isRecording,
    startRecording,
    stopRecording,
    recordingTime,
    canvasRef,
    isProcessing,
    MAX_RECORDING_TIME,
    textareaHeight,
    textareaRef,
    handleTextareaChange,
    containerRef,
    handleActionClick,
  } = useChatUi(onSendMessage, performAction, chatHistory);
  const { user } = useAuth();
  const activeModeRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatHistory, isAITyping]);
  useEffect(() => {
    if (activeModeRef.current) {
      activeModeRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center'
      });
    }
  }, [currentMode]);

  return (
    <Card className="flex-grow flex flex-col h-full">
      <CardHeader className="flex flex-row items-center justify-between">
        <div>
          <CardTitle>{title}</CardTitle>
          <CardDescription>{description}</CardDescription>
        </div>
        <Button
          variant="ghost"
          size="icon"
          className="md:hidden"
          onClick={onClose}
        >
          <X className="h-6 w-6" />
        </Button>
      </CardHeader>
      <CardContent className="flex-grow flex flex-col h-full overflow-hidden">

        <ScrollArea className="flex-grow pr-4 py-16" ref={scrollAreaRef}>
          {
            isSessionLoading ? (
              <div className="space-y-4">
                {[...Array(3)].map((_, index) => (
                  <div key={index} className="flex items-start space-x-2">
                    <Skeleton className="h-8 w-8 rounded-full" variant="default" />
                    <div className="flex-1 space-y-2">
                      <Skeleton className="h-4 rounded w-3/4" variant="content" />
                      <Skeleton className="h-4 rounded w-1/2" variant="content" />
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <>
                {chatHistory.map((message, index) => (
                  <div key={index} className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'} mb-4`}>
                    {index === chatHistory.length - 1 && <div ref={messagesEndRef} />}
                    <div className={`flex items-start ${message.role === 'user' ? 'flex-row-reverse' : ''}`}>
                      <Avatar className="h-8 w-8">
                        <AvatarImage
                          src={message.role === 'user' ? (' ') : CIRCLE_LOGO_URL}
                          alt={message.role === 'user' ? 'User Avatar' : 'Vedita Avatar'}
                        />
                        <AvatarFallback className="text-sm text-[var(--primary)] bg-[var(--secondary)]">   {message.role === 'user' ? (
                          <User />
                        ) : (
                          'V'
                        )}</AvatarFallback>
                      </Avatar>
                      <div className={`mx-2 p-3 rounded-lg overflow-x-auto 
                    bg-[hsl(var(--book-explanation-bg))] text-[hsl(var(--book-explanation-text))] border-l-2 border-[hsl(var(--book-explanation-border))]'
                    `}>
                        <ReactMarkdown
                          className={`prose prose-sm max-w-none whitespace-pre-wrap break-words dark:prose-invert`}
                          remarkPlugins={[remarkGfm]}
                          components={{
                            p: ({ children }) => <p className="mb-1 last:mb-0 min-w-fit">{children}</p>,
                            pre: ({ children }) => <pre className={`p-2 rounded-md overflow-x-auto ${message.role === 'user' ? 'bg-primary-foreground/10' : 'bg-secondary'}`}>{children}</pre>,
                            code: ({ children }) => <code className={`px-1 rounded-sm ${message.role === 'user' ? 'bg-primary-foreground/10' : 'bg-secondary'}`}>{children}</code>
                          }}
                        >
                          {message.content}
                        </ReactMarkdown>
                      </div>
                    </div>
                  </div>
                ))}
                {isAITyping && (
                  <div className="flex justify-start mb-4">
                    <div ref={messagesEndRef} />
                    <div className="flex items-start">
                      <Avatar className="h-9 w-9">
                        <AvatarImage
                          src={LOADING_LOGO_URL} // Replace this with your GIF URL
                          alt="Vedita Avatar"
                        />
                        <AvatarFallback className="text-sm">AI</AvatarFallback>
                      </Avatar>
                      <div className="mx-2 p-3 rounded-lg bg-muted">
                        <TypingIndicator />
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
        </ScrollArea>

        <div className="relative z-10">
          <div className="absolute bottom-full left-0 right-0 mb-2 bg-background">
            {availableActions.map((action) => (
              <Button
                key={action.command}
                onClick={() => performAction(action.command)}
                className={`
                transition-all duration-200 flex items-center gap-2 whitespace-nowrap shrink-0
                hover:bg-[var(--primary)] hover:text-[var(--secondary-text)]
                bg-[var(--background)] text-[var(--primary-text)]
                `}
                variant="ghost"
                size="sm"
              >
                {action.icon && <action.icon className="h-4 w-4" />}
                {action.label}
              </Button>
            ))}
            {/* Modes */}
            <ScrollArea className="w-full h-[40px] bg-[var(--secondary)] rounded-lg">
              <Modes availableModes={availableModes} currentModeState={currentMode as ChatMode} activeModeRef={activeModeRef} onModeChange={onModeChange} />
              <ScrollBar orientation="horizontal" className="h-1 bg-[var(--secondary)] text-[var(--primary-text)]" />
            </ScrollArea>


          </div>
        </div>


        {/* Chat Input */}
        <div className="mt-auto sticky bottom-0 bg-background">
          <div className="flex items-start w-full">
            <div className="flex-grow relative">
              {isRecording || isProcessing ? (
                <div className="w-full bg-muted rounded-lg flex items-center justify-between">
                  {isRecording ? (
                    <>
                      <div ref={containerRef} className="flex-grow h-6 flex items-center justify-center relative overflow-hidden">
                        <canvas ref={canvasRef} className="absolute inset-0 w-full h-full"></canvas>
                        <span className="z-10">
                          Recording... {recordingTime}s / {MAX_RECORDING_TIME}s
                        </span>
                      </div>
                      <Button
                        onClick={() => stopRecording(false)}
                        variant="ghost"
                        size="icon"
                      >
                        <MicOff className="h-4 w-4" />
                        <span className="sr-only">Stop Recording</span>
                      </Button>
                    </>
                  ) : (
                    <div className="flex items-center justify-center w-full h-10">
                      <Loader2 className="animate-spin h-6 w-6" />
                      <span className="ml-2">Processing...</span>
                    </div>
                  )}
                </div>
              ) : (
                <Textarea
                  ref={textareaRef}
                  title="Ctrl+Space to toggle recording, Enter to send"
                  placeholder="Type here..."
                  value={inputMessage}
                  onChange={handleTextareaChange}
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault();
                      sendMessage();
                    }
                  }}
                  className="w-full pr-10 resize-none"
                  style={{ height: textareaHeight, minHeight: "40px", maxHeight: "120px", overflowY: "auto" }}
                  disabled={isLoading || isAITyping || currentMode === 'initialAnalysis'}
                  rows={1}
                />
              )}
              {!isRecording && !isProcessing && (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        onClick={startRecording}
                        className="absolute right-2 bottom-2 p-1 top-1 rounded-full transition-colors"
                        disabled={isLoading || isAITyping || currentMode === 'initialAnalysis'}
                        variant="ghost"
                        size="icon"
                      >
                        <Mic className="h-4 w-4" />
                        <span className="sr-only">Start Recording</span>
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Double Ctrl/Cmd to start/stop recording</p>
                      <p>Ctrl/Cmd + Space to stop and send</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
            </div>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    onClick={() => {
                      if (isRecording) {
                        stopRecording(true);
                      } else {
                        sendMessage();
                      }
                    }}
                    className="ml-2 self-end bg-[var(--primary)] hover:bg-[var(--primary-hover)] text-[var(--secondary-text)]"
                    disabled={isLoading || isAITyping || isProcessing || currentMode === 'initialAnalysis'}
                    size="icon"
                  >
                    <SendIcon className="h-4 w-4" />
                    <span className="sr-only">Send message</span>
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Ctrl/Cmd + Space to send</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
