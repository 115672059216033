import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import GuestLayout from "@/layouts/GuestLayout";
import withLayout from "@/hoc/withLayout";
import { useState, useRef } from "react";
import ScrollReveal from '../../components/ScrollReveal/ScrollReveal';
import Footer from '../../components/Footer/Footer';
import AIInsights from '../../components/AIInsights/AIInsights';
import VeditaFeatures from '../../components/VeditaFeatures/VeditaFeatures';
import AyurvedicEducation from '../../components/AyurvedicEducation/AyurvedicEducation';
import HealthJourney from '../../components/HealthJourney/HealthJourney';
import { CIRCLE_LOGO_URL,NEW_HERO_IMAGE_URL } from "@/constants";
const NewLandingPage: React.FC = () => {
 



    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const aiInsightsRef = useRef<HTMLDivElement>(null);
    const veditaFeaturesRef = useRef<HTMLDivElement>(null);
    const ayurvedicEducationRef = useRef<HTMLDivElement>(null);
    const healthJourneyRef = useRef<HTMLDivElement>(null);

    const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
        ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const navItems = [
        { label: 'AI Insights', onClick: () => scrollToSection(aiInsightsRef) },
        { label: 'Features', onClick: () => scrollToSection(veditaFeaturesRef) },
        { label: 'Education', onClick: () => scrollToSection(ayurvedicEducationRef) },
        { label: 'Health Journey', onClick: () => scrollToSection(healthJourneyRef) },
    ];


    

    return (
        <div className="min-h-screen bg-[#FDF6F4] flex flex-col w-full overflow-x-hidden">
            {/* Navbar */}
            <nav className="fixed top-0 left-0 right-0 z-50 bg-white/95 backdrop-blur-sm shadow-sm w-full">
                <div className="container mx-auto px-4 max-w-7xl">
                    <div className="flex items-center justify-between py-2 md:py-4">
                        {/* Logo */}
                        <Link to="/" className="flex items-start">
                            <img
                                src={require('../../assets/vedita-ai-circle-logo.png')}
                                alt="Vedita AI Logo"
                                className="h-8 sm:h-9 md:h-11 w-auto object-contain mr-2"
                            />
                            <div className="flex flex-col items-start">
                                <span className="text-sm sm:text-base md:text-lg font-semibold text-gray-800">
                                    Vedita.AI
                                </span>
                                <span className="text-xs font-light text-gray-600">
                                    Future Of Ayurveda
                                </span>
                            </div>
                        </Link>

                        {/* Desktop Navigation */}
                        <div className="hidden md:flex items-center space-x-4 lg:space-x-8">
                            {navItems.map((item, index) => (
                                <Button
                                    key={index}
                                    onClick={item.onClick}
                                    variant="ghost"
                                    className="text-sm lg:text-base text-gray-600 hover:text-[#FF8A4D]"
                                >
                                    {item.label}
                                </Button>
                            ))}
                            <Link to="/login">
                                <Button  variant="orange" className=" px-6 md:px-5 py-3  font-['DM_Sans'] ">
                                    Sign In
                                </Button>
                            </Link>
                            <Link to="/register?role=doctor">
                                            <Button  variant="orange" className=" px-6 md:px-5 py-3  font-['DM_Sans']">
                                                Sign Up
                                            </Button>
                                        </Link>
                            
                        </div>

                        {/* Mobile menu button */}
                        <Button
                            variant="ghost"
                            size="icon"
                            className="md:hidden p-2 text-gray-600 hover:text-gray-900 hover:bg-gray-100"
                            onClick={() => setIsMenuOpen(!isMenuOpen)}
                        >
                            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                {isMenuOpen ? (
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                ) : (
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                                )}
                            </svg>
                        </Button>
                    </div>

                    {/* Mobile Navigation */}
                    {isMenuOpen && (
                        <div className="md:hidden py-2 animate-fadeIn">
                            <div className="flex flex-col space-y-2">
                                {navItems.map((item, index) => (
                                    <Button
                                        key={index}
                                        onClick={() => {
                                            item.onClick();
                                            setIsMenuOpen(false);
                                        }}
                                        variant="ghost"
                                        className="text-gray-600 hover:text-[#FF5733] justify-start w-full text-sm"
                                    >
                                        {item.label}
                                    </Button>
                                ))}
                                <div className="flex flex-col space-y-2 px-4 pb-2">
                                    <Link to="/login" className="w-full">
                                        <Button variant="orange" className="w-full text-sm">
                                            Sign In
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </nav>

          
            <div 
                className="relative min-h-[400px] sm:min-h-[500px] md:min-h-[600px] w-full bg-cover bg-center flex items-center justify-center mt-16 md:mt-20 px-4"
                style={{
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${NEW_HERO_IMAGE_URL})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }} 
            >
                <div className="text-center text-white max-w-4xl mx-auto">
                    <ScrollReveal>
                    
                        <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-semibold mb-4 px-4">
                            Vedita.AI: The Future of Ayurveda
                        </h1>
                    </ScrollReveal>
                    <ScrollReveal>
                        <p className="text-lg sm:text-xl md:text-2xl lg:text-3xl mb-8 px-4">
                        Transforming Ancient Vedic Knowledge into Modern Personalised Healthcare.
                        </p>
                    </ScrollReveal>
                </div>
            </div>

           
            <div ref={aiInsightsRef} className="w-full flex justify-center items-center">
                <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <AIInsights />
                </div>
            </div>
            <div ref={veditaFeaturesRef} className="w-full">
                <div className="w-full">
                    <VeditaFeatures />
                </div>
            </div>
            <div ref={ayurvedicEducationRef} className="w-full">
                <div className="w-full">
                    <AyurvedicEducation />
                </div>
            </div>
            <div ref={healthJourneyRef} className="w-full">
                <div className="w-full">
                    <HealthJourney />
                </div>
            </div>
           
            <Footer />
        </div>
    );
};

export default withLayout(GuestLayout)(NewLandingPage);
