import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLibrary } from '@/services/bookService';
import { MyLibraryBook } from '@/types/book';
import { useDebounce } from '@/hooks/useDebounce';

export function useMyLibrary() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearch = useDebounce(searchTerm, 300);

  const { data: books = [], isLoading } = useLibrary({
    search: debouncedSearch
  });

  const handleBookClick = (bookId: string) => {
    navigate(`/reader/${bookId}`);
  };

  return {
    books,
    isLoading,
    searchTerm,
    setSearchTerm,
    handleBookClick,
    navigate
  };
} 