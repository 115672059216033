import { useEffect, useState } from 'react';
import { useAssessmentService } from '@/services/Patient/assessmentService';
import { useNavigate, useParams } from 'react-router-dom';
import { AssessmentResponse } from '@/types/assessment';


export const useQuestionDetails = () => {
  const navigate = useNavigate();
  const { assessmentId } = useParams();
  const [currentData, setCurrentData] = useState<AssessmentResponse | null>(null);
  const [selectedQuestionId, setSelectedQuestionId] = useState<string | null>("");
  const [answers, setAnswers] = useState<Record<string, string>>({});
  const [isNavigating, setIsNavigating] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  
  
  const { useGetAssessment, submitAnswer , finishAssessment} = useAssessmentService();

  const { data: assessmentData, isLoading: isAssessmentLoading, refetch } = useGetAssessment(assessmentId || '', selectedQuestionId ?? '');

  useEffect(() => {
    if (assessmentData) {
      setCurrentData(assessmentData?.data);
      setIsNavigating(false);
    }
  }, [assessmentData]);

  useEffect(() => {
    if(selectedQuestionId){
      refetch();
    }
  },[selectedQuestionId])

  const handleAnswerSubmit = async (questionId: string, selectedAnswer: string) => {
    if (submitAnswer.isPending) {
      console.log('[Debug] Submission already in progress, skipping');
      return;
    }
    if (assessmentId && questionId && selectedAnswer) {
      try {
        submitAnswer.mutate({
          assessmentId,
          questionId,
          selectedAnswer,
        },{
          onSuccess: (response) => {
            console.log(response);
            if(response.progress.remainingQuestions != 0){
              setCurrentData(response);
              setAnswers({});
              setSelectedQuestionId(null)
            }
            
          },
          onError: (error) => {
            console.error('Error completing assessment:', error);
          },
        });
       
  
      } catch (error) {
        console.error('Error submitting the answer:', error);
      }
    } else {
      console.warn('Missing required data: assessmentId, questionId, or selectedAnswer');
    }

  };

  
const handleCompleteAssessment =  () => {
  setShowConfirmDialog(true);
};

const confirmCompleteAssessment = () => {
  setShowConfirmDialog(false);
  if (assessmentId) {
    finishAssessment.mutate(assessmentId, {
      onSuccess: (response) => {
        navigate(`/patient/orderDetails/${response.assessment.orderId._id}`, { replace: true });
      },
      onError: (error) => {
        console.error('Error completing assessment:', error);
      },
    });
  } else {
    console.error('No assessment ID provided.');
  }
};

const handleNextQuestion =  () => {
  setIsNavigating(true);
  setSelectedQuestionId(currentData?.nextQuestionId || '');
};

const handlePreviousQuestion =  () => {
  setIsNavigating(true);
  setSelectedQuestionId(currentData?.previousQuestionId || '');
};

  return {
    assessment:currentData?.assessment,
    progress:currentData?.progress,
    // isLoading: isAssessmentLoading || 
    //            submitAnswer.isPending || 
    //            finishAssessment.isPending ||
    //            isNavigating,
    isSubmittingAssessment: finishAssessment.isPending,
    isSubmitting: submitAnswer.isPending,
    isNavigating,
    isLoading: isAssessmentLoading,
    
    question:currentData?.question,
    handleAnswerSubmit,
    handleNextQuestion,
    handleCompleteAssessment,
    handlePreviousQuestion,
    answers,
    setAnswers,
    showConfirmDialog,
    setShowConfirmDialog,
    confirmCompleteAssessment,
  };
};