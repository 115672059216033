import { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {useDoctorService} from "@/services/Doctor/doctorService"
import { useDocumentService } from '@/services/useDocumentService';
import { toast } from 'sonner';
import { useAuth } from '@/contexts/AuthContext';

// Split the schema into separate tab-specific schemas
const personalInfoSchema = z.object({
  name: z.string().min(2, "Name is required"),
  email: z.string().email("Invalid email address"),
  mobile: z.string()
    .regex(/^\d+$/, "Mobile number must contain only digits")
    .length(10, "Mobile number must be exactly 10 digits"),
  countryCode: z.string().min(2, "Country code is required"),
  avatar: z.string().optional(),
});

const professionalInfoSchema = z.object({
  role: z.enum(["DOCTOR"]),
  isVerified: z.boolean(),
  specialization: z.string().min(2, "Specialization is required"),
  experience: z.number()
    .int()
    .min(1, "Experience must be greater than 0")
    .max(99, "Experience cannot be more than 99 years"),
  about: z.string().optional(),
  education: z.array(z.string().min(2, "Education is required")),
  clinicDetails: z.object({
    name: z.string().min(2, "Clinic name is required"),
    address: z.string().min(5, "Clinic address is required"),
    city: z.string().min(2, "City is required"),
    state: z.string().min(2, "State is required"),
    pincode: z.string().regex(/^\d{6}$/, "Pincode must be 6 digits")
  }),
  documents: z.array(z.object({
    id: z.string().optional(),
    name: z.string().optional(),
    url: z.string().optional(),
    file: z.any().optional()
  })).optional(),
});

const bankDetailsSchema = z.object({
  billingDetails: z.object({
    bankName: z.string().optional(),
    accountNo: z.string().optional(),
    upiId: z.string().optional(),
    branchCode: z.string().optional(),
    branchName: z.string().optional(),
    ifscCode: z.string().optional()
  }).optional()
});

// Combine all schemas for full form validation
const profileFormSchema = z.object({
  ...personalInfoSchema.shape,
  ...professionalInfoSchema.shape,
  ...bankDetailsSchema.shape,
}).transform(data => {
  const { isApproved, _id, ...rest } = data as any;
  return rest;
});

type ProfileFormData = z.infer<typeof profileFormSchema>;

export const useProfile = () => {
  const {setUser} = useAuth();

  const [isEditing, setIsEditing] = useState(false);
  const [avatar, setAvatar] = useState<string>("");
  const [activeTab, setActiveTab] = useState<'personal' | 'professional' | 'bank'>('personal');
  const [documents, setDocuments] = useState<Array<{ id?: string; name: string; url?: string; file?: File }>>();
  
  // New state for display data
  const [displayData, setDisplayData] = useState<ProfileFormData | null>(null);

  const { getDoctorProfile, updateDoctorProfile } = useDoctorService();
  const { uploadDocument } = useDocumentService();

  const form = useForm<ProfileFormData>({
    resolver: zodResolver(profileFormSchema),
  });

  useEffect(() => {
    if (getDoctorProfile.data?.data) {
      form.reset(getDoctorProfile.data.data);
      setUser(getDoctorProfile.data.data);
      setAvatar(getDoctorProfile.data.data.avatar || "");
      // Initialize display data
      setDisplayData(getDoctorProfile.data.data);

    }
  }, [getDoctorProfile.data, form]);

  useEffect(() => {
    if (getDoctorProfile.data?.data?.documents) {
      // Transform the API response to include names derived from URLs
      const formattedDocs = getDoctorProfile.data.data.documents.map((doc: any) => ({
        ...doc,
        name: doc.url.split('/').pop() || 'Document' // Extract filename from URL
      }));
      setDocuments(formattedDocs);
    }
  }, [getDoctorProfile.data]);

  const getSchemaForTab = (tab: string) => {
    switch (tab) {
      case 'personal':
        return personalInfoSchema;
      case 'professional':
        return professionalInfoSchema;
      case 'bank':
        return bankDetailsSchema;
      default:
        return profileFormSchema;
    }
  };

  const validateActiveTab = async (data: any) => {
    try {
      const schema = getSchemaForTab(activeTab);
      const relevantData = {
        personal: {
          name: data.name,
          email: data.email,
          mobile: data.mobile,
          countryCode: data.countryCode,
          avatar: data.avatar,
        },
        professional: {
          role: data.role,
          isVerified: data.isVerified,
          specialization: data.specialization || undefined,
          experience: data.experience || undefined,
          about: data.about,
          education: data.education?.length ? data.education : undefined,
          clinicDetails: {
            name: data.clinicDetails?.name || undefined,
            address: data.clinicDetails?.address || undefined,
            city: data.clinicDetails?.city || undefined,
            state: data.clinicDetails?.state || undefined,
            pincode: data.clinicDetails?.pincode || undefined,
          },
          documents: data.documents,
        },
        bank: {
          billingDetails: data.billingDetails,
        },
      }[activeTab];

      // Clear previous errors for the active tab
      const currentErrors = form.formState.errors;
      Object.keys(currentErrors).forEach(key => {
        if (key in relevantData) {
          form.clearErrors(key);
        }
      });

      await schema.parseAsync(relevantData);
      return { success: true, errors: null };
    } catch (error) {
      if (error instanceof z.ZodError) {
        const formErrors = error.errors.reduce((acc: any, curr) => {
          const path = curr.path.join('.');
          acc[path] = curr.message;
          return acc;
        }, {});
        
        return { success: false, errors: formErrors };
      }
      return { success: false, errors: null };
    }
  };

  const handleDocumentUpload = async (file?: File) => {
    if (!file) return;
    
    // Check if maximum documents limit is reached
    if (documents && documents.length >= 3) {
      toast.error("You can only upload up to 3 documents");
      return;
    }

    // Validate file type
    const validTypes = ['application/pdf', 'image/jpeg', 'image/png', 'image/gif', 'image/webp'];
    if (!validTypes.includes(file.type)) {
      toast.error("Please upload only PDF or image files");
      return;
    }
    
    // Add document to state immediately with temporary local URL
    const newDoc = {
      name: file.name,
      file: file,
      url: URL.createObjectURL(file)
    };
    
    setDocuments(prev => prev ? [...prev, newDoc] : [newDoc]);
    
    // Upload the document
    uploadDocument.mutate(file, {
      onSuccess: (response) => {
        // Update the document in state with the real URL
        setDocuments(prev => prev ? prev.map(doc => 
          doc.file === file 
            ? { ...doc, url: response.data.fileUrl, file: undefined }
            : doc
        ) : []);
        toast.success("Document uploaded successfully");
      },
      onError: (error) => {
     
        toast.error("Failed to upload document. Please try again.");
        // Remove the failed document from state
        setDocuments(prev => prev ? prev.filter(doc => doc.file !== file) : []);
      },
    });
  };

  const removeDocument = (indexToRemove: number) => {
    setDocuments(prev => prev ? prev.filter((_, index) => index !== indexToRemove) : []);
  };

  const onSubmit = async (data: ProfileFormData) => {


    const validation = await validateActiveTab(data);
    if (!validation.success) {
      if (validation.errors) {
        Object.keys(validation.errors).forEach(path => {
          form.setError(path as any, {
            type: 'manual',
            message: validation.errors[path],
          });
        });
      }
      return;
    }

    const { isApproved, ...submitData } = data;
    
    const payloadWithFiles = {
      ...submitData,
      avatar: avatar,
      documents: documents ? documents.map(doc => ({
        id: doc.id,
        name: doc.name,
        url: doc.url
      })) : []
    };
    
    try {
      const result = await updateDoctorProfile.mutateAsync(payloadWithFiles);
      setIsEditing(false);
      setDisplayData(data);
    } catch (error) {
      console.error('Failed to update profile:', error);
    }
  };

  const handleImageUpload = async (file?: File) => {
    if (!file) return
    
    const imageUrl = URL.createObjectURL(file)
    setAvatar(imageUrl)
    
    uploadDocument.mutate(file,{
      onSuccess: (response) => {
        setAvatar(response.data.fileUrl)   
      },
      onError: (error) => {
        setAvatar(form.getValues('avatar') || "");
      },  

     })
  };

  const handleCancel = () => {
    form.reset();
    setIsEditing(false);
  };

  return {
    form,
    isEditing,
    setIsEditing,
    onSubmit,
    handleImageUpload,
    handleCancel,
    avatar,
    handleDocumentUpload,
    isLoading: getDoctorProfile.isLoading,
    isUpdating: updateDoctorProfile.isPending,
    isUploading: uploadDocument.isPending,
    activeTab,
    setActiveTab,
    documents,
    removeDocument,
    displayData, // Return display data
  };
};

export type { ProfileFormData };
