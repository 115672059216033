import React from "react";
import { Card } from "@/components/ui/card";
import DonutChart from "./DonutChart";
import chroma from "chroma-js";
import BarChart from "./AppexBarCharts";

interface ComponentData {
  title: string;
  description: string;
  graphData: Record<
    string,
    { percentage: number; subDosha?: Record<string, { percentage: number }> }
  >;
}

interface NewVikrutiData {
  reportHeading: string;
  component1: ComponentData;
  component2: ComponentData;
  component3?: ComponentData;
  component4?: ComponentData;
  [key: string]: any;
}

const VikrutiTestResult: React.FC<{ data: NewVikrutiData }> = ({ data }) => {
  console.log("datadata", data);
  const renderComponentGraphData = (component: ComponentData) => {
    if (!component || !component.graphData) return [];
    return Object.entries(component.graphData).map(
      ([name, { percentage }]) => ({
        name,
        percentage,
      })
    );
  };

  const generateColours = (numColours: number): string[] => {
    return chroma
      .scale(["#33FF57", "#FF5733"]) // Green to Red scale
      .mode("lab")
      .colors(numColours);
  };

  const generateColoursForSrotas = (numColours: number): string[] => {
    return chroma
      .scale(["#FF5733", "#33FF57"]) // Red to Green scale
      .mode("lab")
      .colors(numColours);
  };

  return (
    <Card className="p-8 bg-background border border-border rounded-lg">
      <h2 className="text-4xl font-extrabold text-primary mb-8">
        {data.reportHeading}
      </h2>

      <div className="space-y-12">
        {Object.keys(data)
          .filter((key) => key.startsWith("component"))
          .map((key) => {
            const component = data[key];
            if (!component) return null;

            const graphData = renderComponentGraphData(component);

            const sortedGraphData = graphData.sort(
              (a, b) => a.percentage - b.percentage
            );

            const categories = [...sortedGraphData.map((data) => [data.name])];

            const series = [
              {
                data: sortedGraphData.map((data) =>
                  parseFloat((data.percentage * 100).toFixed(2))
                ),
              },
            ];

            const colours =
              component.title === "Your Body Systems Health Overview"
                ? generateColoursForSrotas(graphData.length)
                : generateColours(graphData.length);

            return (
              <div key={key} className="space-y-6">
                <h3 className="text-2xl md:text-3xl font-semibold text-secondary border-b-2 border-border pb-2">
                  {component.title}
                </h3>

                <div
                  className="prose prose-secondary max-w-none text-foreground"
                  dangerouslySetInnerHTML={{
                    __html: component.description,
                  }}
                />

                {graphData.length > 0 &&
                  (component.title ===
                  "Explore the Sub-Doshas: A Deeper Dive into Your Ayurvedic Profile" ? (
                    <div className="flex flex-wrap gap-6">
                      {Object.keys(data.component2.graphData).map((dosha) => {
                        const doshaData = component.graphData[dosha];
                        const subDoshaLabels = Object.keys(
                          doshaData.subDosha || {}
                        );
                        const subDoshaCounts = subDoshaLabels.map(
                          (subDosha) =>
                            doshaData.subDosha?.[subDosha]?.percentage * 100 ||
                            0
                        );

                        return (
                          <div key={dosha} className="flex-1 min-w-[250px]">
                            <DonutChart
                              labels={subDoshaLabels}
                              series={subDoshaCounts}
                              title={dosha}
                            />
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div>
                      <BarChart
                        categories={categories}
                        series={series}
                        colors={colours}
                      />
                    </div>
                  ))}
              </div>
            );
          })}
      </div>
    </Card>
  );
};

export default React.memo(VikrutiTestResult);
