import { Card, CardContent } from "@/components/ui/card"
import { LucideIcon } from "lucide-react"

interface CountCardProps {
  title: string;
  value: number;
  icon: LucideIcon;
  color?: string;
}

export function CountCard({ title, value, icon: Icon, color = "bg-blue-500" }: CountCardProps) {
  return (
    <Card>
      <CardContent className="p-6">
        <div className="flex items-center justify-between space-y-0 pb-2">
          <p className="text-sm font-medium">{title}</p>
          <div className={`rounded-full ${color} p-2`}>
            <Icon className="h-4 w-4 text-white" />
          </div>
        </div>
        <div className="flex items-center justify-between pt-4">
          <div className="text-2xl font-bold">{value}</div>
        </div>
      </CardContent>
    </Card>
  )
}
