import React, { useEffect } from 'react'
import { Sidebar } from './Sidebar';
import { AppBar } from './AppBar';
import { SidebarProvider, useSidebar } from '@/contexts/SidebarContext';
import { useAuth } from '@/contexts/AuthContext';

const GeneralUserLayoutContent: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const { isSidebarOpen, toggleSidebar } = useSidebar();
    const { logout, user } = useAuth();

    useEffect(() => {
        if (user && user?.role !== "GENERAL") {
            logout();
        }
    }, [user, logout]);

    return (
        <div className="flex flex-col h-screen">
            <div className="fixed top-0 left-0 right-0 z-10">
                <AppBar toggleSidebar={toggleSidebar} />
            </div>
            <div className="flex flex-1 relative" style={{ marginTop: '3.5rem' }}>
                <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                <main className="flex-1 overflow-y-auto w-full">
                    {children}
                </main>
            </div>
        </div>
    );
};

export const GeneralUserLayout: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    return (
        <SidebarProvider>
            <GeneralUserLayoutContent>{children}</GeneralUserLayoutContent>
        </SidebarProvider>
    );
};

export default GeneralUserLayout;
