import { useState, useRef, useEffect, useCallback } from "react";
import { useSpeechService } from "../../services/useSpeechService";
import { AIInteraction } from "@/types/doctor/globals";

export const useChatUi = (onSendMessage: (message: string) => void, performAction: (action: string) => void, chatHistory: AIInteraction[]) => {
  const [inputMessage, setInputMessage] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [audioData, setAudioData] = useState<number[]>([]);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [lastCtrlPress, setLastCtrlPress] = useState(0);

  const scrollAreaRef = useRef<HTMLDivElement>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const cleanupRef = useRef<(() => void) | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  
  const animationFrameRef = useRef<number | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const accumulatedAudioDataRef = useRef<number[]>([]);
  const speechService = useSpeechService();

  const [audioContext, setAudioContext] = useState<AudioContext | null>(null);

  const MAX_RECORDING_TIME = 60; // Reduced to 58 seconds to account for any potential delay
  const CHUNK_SIZE = 100; // Size of each audio chunk in milliseconds

  const [textareaHeight, setTextareaHeight] = useState("40px");
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleTextareaChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputMessage(e.target.value);
    adjustTextareaHeight();
  }, []);

  const adjustTextareaHeight = useCallback(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "40px";
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + "px";
      setTextareaHeight(scrollHeight + "px");
    }
  }, []);

  useEffect(() => {
    adjustTextareaHeight();
  }, [inputMessage, adjustTextareaHeight]);

  useEffect(() => {
    if (scrollAreaRef.current) {
      scrollAreaRef.current.scrollTop = scrollAreaRef.current.scrollHeight;
    }
  }, [inputMessage]);

  
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [inputMessage, isRecording]); // Add any other dependencies that should trigger scrolling

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const resizeCanvas = () => {
      if (canvasRef.current && containerRef.current) {
        canvasRef.current.width = containerRef.current.offsetWidth;
        canvasRef.current.height = containerRef.current.offsetHeight;
      }
    };

    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);

    return () => {
      window.removeEventListener('resize', resizeCanvas);
    };
  }, []);

  useEffect(() => {
    if (canvasRef.current && isRecording) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      // Accumulate audio data
      accumulatedAudioDataRef.current = [...accumulatedAudioDataRef.current, ...audioData];

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.lineWidth = 2;
      ctx.strokeStyle = '#4a90e2';
      ctx.beginPath();

      const sliceWidth = canvas.width / accumulatedAudioDataRef.current.length;
      let x = 0;

      for (let i = 0; i < accumulatedAudioDataRef.current.length; i++) {
        const v = accumulatedAudioDataRef.current[i] / 128.0;
        const y = (v * canvas.height) / 2;

        if (i === 0) {
          ctx.moveTo(x, y);
        } else {
          ctx.lineTo(x, y);
        }

        x += sliceWidth;
      }

      ctx.lineTo(canvas.width, canvas.height / 2);
      ctx.stroke();
    }
  }, [audioData, isRecording]);

  const sendMessage = () => {
    if (inputMessage.trim().length > 0 &&  !isProcessing) {
      onSendMessage(inputMessage);
      setInputMessage('');
    }
  };

  const processAudioToText = async (audioBlob: Blob, sendMessage: boolean = false) => {
    setIsProcessing(true);
    try {
      const transcription = await speechService.sendAudioForTranscriptionGoogleV1(audioBlob);
      const fullMessage = (inputMessage.trim() + ' ' + transcription.trim()).trim();
      // Set the transcription to the input field instead of sending it as a message
      if(!sendMessage) {
        setInputMessage(fullMessage);
      } else if (fullMessage.trim().length > 0) {
        setInputMessage('');
        onSendMessage(fullMessage);
      }
    } catch (error) {
      console.error('Error processing speech to text:', error);
      // You might want to show an error message to the user here
    } finally {
      setIsProcessing(false);
    }
  };

  const stopRecording = useCallback(async (sendMessage: boolean = false) => {
    if (!isRecording && !mediaRecorderRef.current) {
      return;
    }

    setIsRecording(false);

    if (mediaRecorderRef.current) {
      if (mediaRecorderRef.current.state !== 'inactive') {
        mediaRecorderRef.current.stop();
      }

      if (cleanupRef.current) {
        cleanupRef.current();
      }

      // Stop all tracks in the media stream
      if (mediaRecorderRef.current.stream) {
        mediaRecorderRef.current.stream.getTracks().forEach(track => {
          track.stop();
        });
      }
    }

    if (audioContext) {
      try {
        if (audioContext.state !== 'closed') {
          await audioContext.close();
        }
      } catch (error) {
        console.error('Error closing AudioContext:', error);
      }
      setAudioContext(null);
    }
    
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
    }
    setAudioData([]);

    // Create the audio blob with the recorded data
    const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm;codecs=opus' });

    await processAudioToText(audioBlob, sendMessage);
    mediaRecorderRef.current = null;
  }, [isRecording, audioContext, processAudioToText]);

  const startRecording = useCallback(async () => {
    if (isRecording) {
      return;
    }
    
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const options = {
        mimeType: 'audio/webm;codecs=opus',
        audioBitsPerSecond: 16000
      };
      const mediaRecorder = new MediaRecorder(stream, options);
      
      setIsRecording(true);
      setRecordingTime(0);
      audioChunksRef.current = [];
      setAudioUrl(null);
      accumulatedAudioDataRef.current = [];

      const newAudioContext = new AudioContext();
      const newSourceNode = newAudioContext.createMediaStreamSource(stream);
      setAudioContext(newAudioContext);
      
      const analyser = newAudioContext.createAnalyser();
      analyser.fftSize = 256;
      newSourceNode.connect(analyser);

      const updateWaveform = () => {
        if (!analyser) return;
        const dataArray = new Uint8Array(analyser.frequencyBinCount);
        analyser.getByteTimeDomainData(dataArray);
        setAudioData(Array.from(dataArray));
        animationFrameRef.current = requestAnimationFrame(updateWaveform);
      };
      updateWaveform();

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorder.onstop = () => {
        stream.getTracks().forEach(track => track.stop());
        const maxChunks = Math.floor((MAX_RECORDING_TIME * 1000) / CHUNK_SIZE);
        const audioBlob = new Blob(audioChunksRef.current.slice(0, maxChunks), { type: 'audio/webm;codecs=opus' });
        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioUrl(audioUrl);
      };

      mediaRecorder.start(CHUNK_SIZE);
      mediaRecorderRef.current = mediaRecorder;

      const timerInterval = setInterval(() => {
        setRecordingTime((prevTime) => {
          const newTime = prevTime + 1;
          if (newTime >= MAX_RECORDING_TIME) {
            clearInterval(timerInterval);
            stopRecording();
            return MAX_RECORDING_TIME;
          }
          return newTime;
        });
      }, 1000);

      const cleanup = () => {
        clearInterval(timerInterval);
        if (mediaRecorder.state !== 'inactive') {
          mediaRecorder.stop();
        }
      };

      cleanupRef.current = cleanup;

    } catch (error) {
      console.error('Error starting recording:', error);
      setIsRecording(false);
    }
  }, [MAX_RECORDING_TIME, stopRecording]);

  

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    const isCtrlOrCmd = event.ctrlKey || event.metaKey;
    if (isCtrlOrCmd && (event.code === 'ControlLeft' || event.code === 'MetaLeft')) {
      const now = Date.now();
      if (now - lastCtrlPress < 500) { // 500ms threshold for double click
        event.preventDefault();
        if (!isRecording && !isProcessing) {
          startRecording();
        }
      }
      setLastCtrlPress(now);
    }

    // Single spacebar press to stop recording
    if (isCtrlOrCmd && event.code === 'Space') {
      event.preventDefault();
      if(isRecording) {
        stopRecording(true);
      } else {
        sendMessage();
      }
    }

    // Single spacebar press to stop recording
    if (event.code === 'Space') {
      if(isRecording) {
        event.preventDefault();
        stopRecording();
      }
    }
  }, [isRecording, stopRecording, startRecording, lastCtrlPress]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);


  const handleActionClick = (action: string) => {
    performAction(action);
  }

  return {
    inputMessage,
    setInputMessage,
    scrollAreaRef,
    messagesEndRef,
    sendMessage,
    isRecording,
    startRecording,
    stopRecording,
    recordingTime,
    audioData,
    canvasRef,
    isProcessing,
    MAX_RECORDING_TIME,
    textareaHeight,
    textareaRef,
    handleTextareaChange,
    containerRef,
    handleActionClick,
  };
};
