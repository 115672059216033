import { useState } from "react";
import { usePatientService } from "@/services/Patient/patientService";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";

const useNewPatientModal = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [phone, setPhone] = useState("");
  const [mobile, setMobile] = useState("");
  const [visitDate, setVisitDate] = useState(new Date().toISOString());
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { createNewPatient } = usePatientService();

  const validateForm = () => {
    const newErrors: Record<string, string> = {};

    if (!firstName.trim()) newErrors.firstName = "First name is required";
    if (!lastName.trim()) newErrors.lastName = "Last name is required";
    if (!countryCode) {
      newErrors.countryCode = "Country code is required";
    }
    if (!phone) newErrors.phone = "Phone number is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handlePhoneChange = (value: string, data: any) => {
    setPhone(value);
    setMobile(value.slice(data.dialCode.length));
    setCountryCode(`+${data.dialCode}`);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) return;

    setIsLoading(true);
    try {
      const response = await createNewPatient.mutateAsync({
        firstName,
        lastName,
        countryCode,
        mobileNumber: mobile,
        visitDate: visitDate
      });
      navigate(`/doctor/analysis/${response.data._id}`);
      toast.success("Patient added successfully");
      // Reset form
      setFirstName("");
      setLastName("");
      setCountryCode("");
      setPhone("");
      setErrors({});
    } catch (error) {
      toast.error("Failed to add patient");
    } finally {
      setIsLoading(false);
    }
  };

  return {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    phone,
    handlePhoneChange,
    errors,
    handleSubmit,
    isLoading,
    visitDate,
    setVisitDate
  };
};

export default useNewPatientModal;
