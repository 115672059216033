import { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { usePatientService } from "@/services/Patient/patientService"
import { useDocumentService } from "@/services/useDocumentService"
const formSchema = z.object({
  name: z.string().min(2, { message: "Name is required" }),
  email: z.string().email({ message: "Invalid email address" }),
  mobile: z.string().min(10, { message: "Phone number is required" }),
  countryCode: z.string(),
  role: z.string(),
  isVerified: z.boolean(),
  avatar: z.string().optional(),
  gender: z.enum(["MALE", "FEMALE"]).optional(),
  address: z.string().min(5, { message: "Address is required" }).optional(),
  dateOfBirth: z.string().optional(),
  bloodGroup: z.string().optional(),
})

export type PatientData = z.infer<typeof formSchema>



export const useProfile = () => {
  const [avatar, setAvatar] = useState<string>("")
  const { getPatientProfile, updatePatientProfile } = usePatientService();
  const { uploadDocument } = useDocumentService();
  const { data: profileData, isLoading } = getPatientProfile

  const form = useForm<PatientData>({
    resolver: zodResolver(formSchema)
  })

  useEffect(() => {
    if (profileData?.data) {
      form.reset(profileData.data)
      setAvatar(profileData.data.avatar || "")
    }
  }, [profileData?.data, form])

  const handleImageUpload = async (file?: File) => {
    if (!file) return
    
    const imageUrl = URL.createObjectURL(file)
    setAvatar(imageUrl)
    
    uploadDocument.mutate(file,{
      onSuccess: (response) => {
        setAvatar(response.data.fileUrl)   
      },
      onError: (error) => {
        setAvatar(form.getValues('avatar') || "");
      },  

     })
  }

  const onSubmit = (values: PatientData) => {
    const updatedValues = {
      ...values,
      avatar,
    }
    updatePatientProfile.mutate(updatedValues)
  }

  return {
    form,
    onSubmit,
    avatar,
    handleImageUpload,
    isLoading,
    isUpdating: updatePatientProfile.isPending,
    isUploadingImage: uploadDocument.isPending,
  }
}
