import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import useNewPatientModal from "./useNewPatientModal";
import { Popover, PopoverTrigger } from "@/components/ui/popover";
import { PopoverContent } from "@radix-ui/react-popover";
import { Calendar } from "@/components/ui/calendar";
import { CalendarIcon } from "lucide-react";
import { format } from "date-fns";

export default function NewPatientModal() {
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    phone,
    handlePhoneChange,
    errors,
    handleSubmit,
    isLoading,
    visitDate,
    setVisitDate
  } = useNewPatientModal();

  return (
    <form onSubmit={handleSubmit} className="space-y-4 mt-4">
      <div>
        <label htmlFor="firstName" className="block text-sm font-medium mb-1">
          First Name
        </label>
        <Input
          id="firstName"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          className={errors.firstName ? 'border-red-500' : ''}
        />
        {errors.firstName && (
          <p className="text-red-500 text-sm mt-1">{errors.firstName}</p>
        )}
      </div>

      <div>
        <label htmlFor="lastName" className="block text-sm font-medium mb-1">
          Last Name
        </label>
        <Input
          id="lastName"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          className={errors.lastName ? 'border-red-500' : ''}
        />
        {errors.lastName && (
          <p className="text-red-500 text-sm mt-1">{errors.lastName}</p>
        )}
      </div>

      <div>
        <label htmlFor="phone" className="block text-sm font-medium mb-1">
          Mobile Number
        </label>
        <style>
          {`
            .phone-input-container .react-tel-input {
              width: 100%;
            }
            .phone-input-container .form-control {
              width: 100% !important;
              height: 40px !important;
              padding-right: 84px !important;
            }
            .phone-input-container .flag-dropdown {
              border: none !important;
              background: transparent !important;
            }
          `}
        </style>
        <div className="phone-input-container">
          <PhoneInput
            country={'in'}
            value={phone}
            onChange={handlePhoneChange}
            inputClass={errors.phone ? 'border-red-500' : 'border-gray-300'}
            containerClass="w-full"
            enableSearch={true}
            disableSearchIcon={true}
            specialLabel=""
          />
        </div>
        {errors.phone && (
          <p className="text-red-500 text-sm mt-1">{errors.phone}</p>
        )}

      </div>

      <div className="space-y-1">
        <label className="block text-sm font-medium mb-1">
          Visit Date
        </label>
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant={"outline"}
              className={"w-full pl-3 text-left font-normal h-10 rounded-lg"}
            >
              {visitDate ? (
                format(new Date(visitDate), "PPP")
              ) : (
                <span>Pick a date</span>
              )}
              <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0 rounded-lg border shadow-md" align="start">
            <Calendar
              mode="single"
              selected={new Date(visitDate)}
              onSelect={(date) => setVisitDate(date?.toISOString() ?? '')}
              className="rounded-lg"
            />
          </PopoverContent>
        </Popover>
        {errors.visitDate && (
          <p className="text-red-500 text-sm mt-1">{errors.visitDate}</p>
        )}
      </div>

      <Button type="submit" disabled={isLoading} className="w-full" variant="orange">
        {isLoading ? 'Adding...' : 'Add Patient'}
      </Button>
    </form>
  );
}
