import React from 'react';
import { Button } from "@/components/ui/button";

interface LogoutModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const LogoutModal: React.FC<LogoutModalProps> = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded shadow-lg">
        <h2 className="text-xl font-semibold mb-4 text-gray-800">Confirm Logout</h2>
        <p className="mb-4 text-gray-600">Are you sure you want to logout?</p>
        <div className="flex justify-end space-x-4">
          <Button variant="outline"  onClick={onClose}>Cancel</Button>
          <Button variant="orange" onClick={onConfirm}>Logout</Button>
        </div>
      </div>
    </div>
  );
};