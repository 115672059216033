import { useProfile } from './useProfile';
import DoctorLayout from '@/layouts/Doctor/DoctorLayout';
import withLayout from '@/hoc/withLayout';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/Textarea';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Upload, X, FileText } from 'lucide-react';
import { useState } from 'react';
import { Skeleton } from "@/components/ui/skeleton";

function Profile() {

  const {
    form,
    handleDocumentUpload,
    isEditing,
    setIsEditing,
    onSubmit,
    handleCancel,
    avatar,
    handleImageUpload,
    isLoading,
    isUpdating,
    isUploading,
    activeTab,
    setActiveTab,
    documents,
    removeDocument,
    displayData,
  } = useProfile();

  const formValues = form.watch();

  const [isDragging, setIsDragging] = useState(false);

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
    handleDocumentUpload(e.dataTransfer.files[0]);
  };

  if (isLoading) {
    return (
      <div className="container mx-auto px-2 sm:px-4 pt-6">
        <div className="grid gap-6">
          {/* Profile Header Card */}
          <Card>
            <CardHeader className="flex flex-col sm:flex-row items-start sm:items-center justify-between space-y-2 sm:space-y-0">
              <div className="flex items-center gap-4">
                <Skeleton className="h-20 w-20 rounded-full" />
                <div className="text-center sm:text-left">
                  <Skeleton className="h-8 w-48 mb-2" />
                  <Skeleton className="h-6 w-32 mb-2" />
                  <div className="flex flex-wrap gap-2">
                    <Skeleton className="h-6 w-32" />
                    <Skeleton className="h-6 w-24" />
                  </div>
                </div>
              </div>
              <Skeleton className="h-10 w-24" />
            </CardHeader>
          </Card>

          {/* Tabs and Form Content */}
          <div className="space-y-4">
            {/* Tabs */}
            <Skeleton className="h-10 max-w-[400px]" />

            {/* Tab Content */}
            <Card>
              <CardHeader>
                <Skeleton className="h-7 w-48 mb-2" />
                <Skeleton className="h-5 w-64" />
              </CardHeader>
              <CardContent className="space-y-6">
                {/* Personal Information */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* Name Field */}
                  <div className="space-y-2">
                    <Skeleton className="h-4 w-20" />
                    <Skeleton className="h-10 w-full" />
                  </div>

                  {/* Email Field */}
                  <div className="space-y-2">
                    <Skeleton className="h-4 w-16" />
                    <Skeleton className="h-10 w-full" />
                  </div>

                  {/* Phone Number Field */}
                  <div className="space-y-2 md:col-span-2">
                    <Skeleton className="h-4 w-32" />
                    <div className="flex gap-2">
                      <Skeleton className="h-10 w-20" />
                      <Skeleton className="h-10 flex-1" />
                    </div>
                  </div>
                </div>

                {/* Professional Information */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* Specialization */}
                  <div className="space-y-2">
                    <Skeleton className="h-4 w-28" />
                    <Skeleton className="h-10 w-full" />
                  </div>

                  {/* Experience */}
                  <div className="space-y-2">
                    <Skeleton className="h-4 w-36" />
                    <Skeleton className="h-10 w-full" />
                  </div>

                  {/* Education */}
                  <div className="space-y-2">
                    <Skeleton className="h-4 w-24" />
                    <Skeleton className="h-10 w-full" />
                  </div>

                  {/* About */}
                  <div className="space-y-2 md:col-span-2">
                    <Skeleton className="h-4 w-16" />
                    <Skeleton className="h-32 w-full" />
                  </div>
                </div>

                {/* Clinic Details */}
                <div className="space-y-4">
                  <Skeleton className="h-6 w-32" />
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {/* Clinic Name */}
                    <div className="space-y-2">
                      <Skeleton className="h-4 w-24" />
                      <Skeleton className="h-10 w-full" />
                    </div>

                    {/* Address */}
                    <div className="space-y-2">
                      <Skeleton className="h-4 w-20" />
                      <Skeleton className="h-10 w-full" />
                    </div>

                    {/* City */}
                    <div className="space-y-2">
                      <Skeleton className="h-4 w-16" />
                      <Skeleton className="h-10 w-full" />
                    </div>

                    {/* State */}
                    <div className="space-y-2">
                      <Skeleton className="h-4 w-16" />
                      <Skeleton className="h-10 w-full" />
                    </div>
                  </div>
                </div>

                {/* Bank Details */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* Account Number */}
                  <div className="space-y-2">
                    <Skeleton className="h-4 w-32" />
                    <Skeleton className="h-10 w-full" />
                  </div>

                  {/* IFSC Code */}
                  <div className="space-y-2">
                    <Skeleton className="h-4 w-24" />
                    <Skeleton className="h-10 w-full" />
                  </div>

                  {/* Bank Name */}
                  <div className="space-y-2">
                    <Skeleton className="h-4 w-24" />
                    <Skeleton className="h-10 w-full" />
                  </div>

                  {/* Branch */}
                  <div className="space-y-2">
                    <Skeleton className="h-4 w-20" />
                    <Skeleton className="h-10 w-full" />
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-2 sm:px-4 pt-6">
      <div className="grid gap-6">
        <Card>
          <CardHeader className="flex flex-col sm:flex-row items-start sm:items-center justify-between space-y-2 sm:space-y-0">
            <div className="flex items-center gap-4">
              <div className="relative">
                <Avatar className="h-20 w-20">
                  <AvatarImage src={avatar} alt={displayData?.name} />
                  <AvatarFallback>{displayData?.name?.[0]?.toUpperCase()}</AvatarFallback>
                </Avatar>
                {isEditing && (
                  <div className="absolute bottom-0 right-0">
                    <input
                      type="file"
                      id="avatar-upload"
                      className="hidden"
                      accept="image/*"
                      onChange={(e) => {
                        const file = e.target.files?.[0];
                        if (file) handleImageUpload(file);
                      }}
                    />
                    <Button
                      type="button"
                      size="icon"
                      variant="orange"
                      className="rounded-full h-8 w-8"
                      onClick={() => document.getElementById('avatar-upload')?.click()}
                      disabled={isUploading}
                    >
                      <Upload className="h-4 w-4" />
                    </Button>
                  </div>
                )}
              </div>
              <div className="text-center sm:text-left">
                <CardTitle className="text-2xl flex items-center gap-2">
                  {displayData?.name}

                </CardTitle>
                <CardDescription className="text-lg">
                  {displayData?.specialization}
                </CardDescription>
                <div className="flex flex-wrap gap-2 mt-2">
                  <Badge variant="secondary">{displayData?.experience} years Experience</Badge>
                </div>
              </div>
            </div>
            {!isEditing ? (
              <Button variant="orange" onClick={() => setIsEditing(true)}>Edit Profile</Button>
            ) : null}
          </CardHeader>
        </Card>

        <Form {...form}>
          <form
            id="profile-form"
            onSubmit={(e) => {
              e.preventDefault(); // Prevent default form submission
              const isValid = form.formState.isValid;


              if (!isValid) {
                onSubmit(form.getValues());
                return;
              }

              form.handleSubmit((data) => {
                onSubmit(data);
              })(e);
            }}
            className="space-y-4"
            noValidate
          >
            <Tabs
              defaultValue="personal"
              className="space-y-4"
              onValueChange={(value) => setActiveTab(value as 'personal' | 'professional' | 'bank')}
            >
              <TabsList className="grid max-w-[400px] grid-cols-3 bg-muted/50">
                <TabsTrigger
                  value="personal"
                  className="flex-1 md:flex-none text-sm whitespace-nowrap px-3 py-1.5 md:py-2 text-[var(--primary-text)] hover:bg-[var(--primary-text)] data-[state=active]:bg-[var(--primary)] data-[state=active]:text-[var(--secondary-text)] data-[state=active]:shadow-sm hover:text-[var(--secondary-text)]"

                >
                  Personal Info
                </TabsTrigger>
                <TabsTrigger
                  value="professional"
                  className="flex-1 md:flex-none text-sm whitespace-nowrap px-3 py-1.5 md:py-2 text-[var(--primary-text)] hover:bg-[var(--primary-text)] data-[state=active]:bg-[var(--primary)] data-[state=active]:text-[var(--secondary-text)] data-[state=active]:shadow-sm hover:text-[var(--secondary-text)]"

                >
                  Professional Info
                </TabsTrigger>
                <TabsTrigger
                  value="bank"
                  className="flex-1 md:flex-none text-sm whitespace-nowrap px-3 py-1.5 md:py-2 text-[var(--primary-text)] hover:bg-[var(--primary-text)] data-[state=active]:bg-[var(--primary)] data-[state=active]:text-[var(--secondary-text)] data-[state=active]:shadow-sm hover:text-[var(--secondary-text)]"

                >
                  Bank Details
                </TabsTrigger>
              </TabsList>





              <TabsContent value="personal">
                <Card>
                  <CardHeader>
                    <CardTitle>Personal Information</CardTitle>
                    <CardDescription>
                      Update your personal information here
                    </CardDescription>
                  </CardHeader>
                  <CardContent className="space-y-6">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      <FormField
                        control={form.control}
                        name="name"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>
                              Full Name <span className="text-red-500">*</span>
                            </FormLabel>
                            <FormControl>
                              <Input disabled={true} className="bg-muted" {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="email"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>
                              Email <span className="text-red-500">*</span>
                            </FormLabel>
                            <FormControl>
                              <Input type="email" disabled={true} className="bg-muted" {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="mobile"
                        render={({ field }) => (
                          <FormItem className="flex flex-col justify-end md:col-span-2">
                            <FormLabel>Phone Number <span className="text-red-500">*</span></FormLabel>
                            <FormControl>
                              <div className="flex gap-2">
                                <Input
                                  value={form.getValues("countryCode")}
                                  disabled
                                  className="w-20 bg-muted"
                                />
                                <Input {...field} disabled={true} className="bg-muted flex-1" />
                              </div>
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                  </CardContent>
                </Card>
                {isEditing && (
                  <div className="flex justify-end gap-4 mt-4">
                    <Button
                      type="button"
                      variant="outline"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="orange"
                      type="submit"
                      disabled={isUpdating}
                    >
                      {isUpdating ? "Saving..." : "Save Changes"}
                    </Button>
                  </div>
                )}
              </TabsContent>

              <TabsContent value="professional">
                <Card>
                  <CardHeader>
                    <CardTitle>Professional Information</CardTitle>
                    <CardDescription>
                      Manage your professional details and clinic information
                    </CardDescription>
                  </CardHeader>
                  <CardContent className="space-y-6">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      <FormField
                        control={form.control}
                        name="specialization"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Specialization <span className="text-red-500">*</span></FormLabel>
                            <FormControl>
                              <Input disabled={!isEditing} {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="experience"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Years of Experience <span className="text-red-500">*</span></FormLabel>
                            <FormControl>
                              <Input
                                type="number"
                                disabled={!isEditing}
                                {...field}
                                onInput={(e) => {
                                  const value = e.currentTarget.value;
                                  if (value.length > 2) {
                                    e.currentTarget.value = value.slice(0, 2);
                                  }
                                }}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="education.0"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Education <span className="text-red-500">*</span></FormLabel>
                            <FormControl>
                              <Input disabled={!isEditing} {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="about"
                        render={({ field }) => (
                          <FormItem className="md:col-span-2">
                            <FormLabel>About </FormLabel>
                            <FormControl>
                              <Textarea disabled={!isEditing} {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>

                    <div className="space-y-4">
                      <h3 className="text-lg font-semibold">Clinic Details</h3>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <FormField
                          control={form.control}
                          name="clinicDetails.name"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Clinic Name <span className="text-red-500">*</span></FormLabel>
                              <FormControl>
                                <Input disabled={!isEditing} {...field} />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name="clinicDetails.address"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Address <span className="text-red-500">*</span></FormLabel>
                              <FormControl>
                                <Input disabled={!isEditing} {...field} />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name="clinicDetails.city"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>City <span className="text-red-500">*</span></FormLabel>
                              <FormControl>
                                <Input disabled={!isEditing} {...field} />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name="clinicDetails.state"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>State <span className="text-red-500">*</span></FormLabel>
                              <FormControl>
                                <Input disabled={!isEditing} {...field} />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name="clinicDetails.pincode"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Pincode <span className="text-red-500">*</span></FormLabel>
                              <FormControl>
                                <Input
                                  type="text"
                                  disabled={!isEditing}
                                  {...field}
                                  pattern="\d*"
                                  onInput={(e) => {
                                    const value = e.currentTarget.value;
                                    e.currentTarget.value = value.replace(/\D/g, '').slice(0, 6);
                                  }}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </div>
                    </div>

                    <div className="space-y-4 mt-6">
                      <h3 className="text-lg font-semibold">Documents</h3>

                      {documents && documents.length > 0 && (
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                          {documents.map((doc, index) => (
                            <div key={index} className="flex flex-col items-center justify-between p-3 border rounded">
                              {doc.url?.endsWith('.pdf') ? (
                                <div className="w-full h-48 flex items-center justify-center bg-muted/20">
                                  <a
                                    href={doc.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="flex flex-col items-center gap-2 text-muted-foreground hover:text-primary transition-colors"
                                  >
                                    <FileText className="h-16 w-16" />
                                    <span className="text-sm">Click to view PDF</span>
                                  </a>
                                </div>
                              ) : (
                                <a
                                  href={doc.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="w-full h-48 block hover:opacity-90 transition-opacity"
                                >
                                  <img
                                    src={doc.url}
                                    alt={doc.name}
                                    className="w-full h-full object-cover"
                                  />
                                </a>
                              )}
                              <div className="flex justify-between w-full mt-2">
                                <span className="truncate">{doc.name}</span>
                                {isEditing && (
                                  <button
                                    onClick={() => removeDocument(index)}
                                    className="text-destructive hover:text-destructive/80"
                                    type="button"
                                  >
                                    <X className="h-4 w-4" />
                                  </button>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}

                      {isEditing && (
                        <div
                          className={`border-2 rounded-lg ${isDragging ? 'border-primary' : 'border-dashed'
                            }`}
                          onDragOver={handleDragOver}
                          onDragLeave={handleDragLeave}
                          onDrop={handleDrop}
                        >
                          <div className="flex flex-col items-center justify-center py-8">
                            <Upload className="h-8 w-8 text-muted-foreground mb-2" />
                            <p className="text-sm text-muted-foreground">
                              Drag and drop files here or click to browse
                            </p>
                            <p className="text-sm text-muted-foreground mt-1">
                              (Maximum 3 files, PDF or Images only)
                            </p>
                            <input
                              type="file"
                              id="document-upload"
                              className="hidden"
                              accept=".pdf,image/*"
                              onChange={(e) => handleDocumentUpload(e.target.files?.[0])}
                            />
                            <Button
                              type="button"
                              variant="outline"
                              className="mt-2"
                              onClick={() => document.getElementById('document-upload')?.click()}
                              disabled={documents && documents.length >= 3}
                            >
                              Browse Files
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </CardContent>
                </Card>
                {isEditing && (
                  <div className="flex justify-end gap-4 mt-4">
                    <Button
                      type="button"
                      variant="outline"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="orange"
                      type="submit"
                      disabled={isUpdating}
                    >
                      {isUpdating ? "Saving..." : "Save Changes"}
                    </Button>
                  </div>
                )}
              </TabsContent>

              <TabsContent value="bank">
                <Card>
                  <CardHeader>
                    <CardTitle>Bank Details</CardTitle>
                    <CardDescription>
                      Manage your bank account information for payments
                    </CardDescription>
                  </CardHeader>
                  <CardContent className="space-y-6">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      <FormField
                        control={form.control}
                        name="billingDetails.bankName"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Bank Name</FormLabel>
                            <FormControl>
                              <Input disabled={!isEditing} {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="billingDetails.branchName"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Branch Name</FormLabel>
                            <FormControl>
                              <Input disabled={!isEditing} {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="billingDetails.accountNo"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Account Number</FormLabel>
                            <FormControl>
                              <Input disabled={!isEditing} {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="billingDetails.ifscCode"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>IFSC Code</FormLabel>
                            <FormControl>
                              <Input disabled={!isEditing} {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="billingDetails.branchCode"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Branch Code</FormLabel>
                            <FormControl>
                              <Input disabled={!isEditing} {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="billingDetails.upiId"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>UPI ID</FormLabel>
                            <FormControl>
                              <Input disabled={!isEditing} {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                  </CardContent>
                </Card>
                {isEditing && (
                  <div className="flex justify-end gap-4 mt-4">
                    <Button
                      type="button"
                      variant="outline"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="orange"
                      type="submit"
                      disabled={isUpdating}
                    >
                      {isUpdating ? "Saving..." : "Save Changes"}
                    </Button>
                  </div>
                )}
              </TabsContent>
            </Tabs>
          </form>
        </Form>
      </div>
    </div>
  );
}

export default withLayout(DoctorLayout)(Profile);
