import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@/hooks/use-media-query';
import { PatientTableProps } from '@/types/doctor/testTypes';

export const usePatientTable = ({ patients, formatDate, currentPage, pageSize }: PatientTableProps) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 640px)");

  const handleRowClick = (patientId: string) => {
    navigate(`/doctor/analysis/${patientId}`);
  };

  return {
    isMobile,
    handleRowClick,
    patients,
    formatDate,
    currentPage,
    pageSize,
  };
};
