import { cn } from "@/lib/utils"

interface SkeletonProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: 'default' | 'title' | 'content';
  shimmer?: boolean;
}

function Skeleton({
  className,
  variant = 'default',
  shimmer = true,
  ...props
}: SkeletonProps) {
  return (
    <div className="relative overflow-hidden">
      <div
        className={cn(
          "rounded-md",
          {
            "bg-[hsl(var(--skeleton-title))]": variant === 'title',
            "bg-[hsl(var(--skeleton-content))]": variant === 'content',
            "bg-muted": variant === 'default',
          },
          className
        )}
        {...props}
      />
      {shimmer && (
        <div 
          className="absolute inset-0 -translate-x-full animate-shimmer bg-gradient-to-r from-transparent via-[hsl(var(--skeleton-highlight))] to-transparent"
          style={{ 
            '--skeleton-highlight': 'var(--skeleton-highlight)',
          } as React.CSSProperties}
        />
      )}
    </div>
  )
}

export { Skeleton }
