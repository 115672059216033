import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import DoctorLayout from "@/layouts/Doctor/DoctorLayout"
import withLayout from "@/hoc/withLayout"
import { Button } from "@/components/ui/button"
import { TestsTable } from "@/components/doctor/TestsTable"
import { Skeleton } from "@/components/ui/skeleton"
import useTests from "./useTests"
import { NewTestForm } from "@/components/doctor/OrderTest/NewTestForm"
import { FileX, Plus } from "lucide-react"
import { CreateTestRequest } from "@/types/doctor/testTypes"
import ReactPaginate from 'react-paginate';

export function Tests() {
  const {
    orders,
    formatDate,
    isTestModalOpen,
    setIsTestModalOpen,
    isLoading,
    createNewTest,
    setCurrentPage,
    currentPage,
    totalPagesOrders,
    testTypes,
    isLoadingTestTypes
  } = useTests();

  const handlePageChange = ({ selected }: { selected: number }) => {
    if (selected + 1 === currentPage) return;
    setCurrentPage(selected + 1);
  };

  const handleCreateTest = async (data: CreateTestRequest) => {
    try {
      await createNewTest.mutateAsync(data);
      setIsTestModalOpen(false);
    } catch (error) {
      console.error('Failed to create test:', error);
    }
  };

  return (
    <div className="container mx-auto px-2 sm:px-4 pt-6">
      <Card>
        <CardHeader className="flex flex-row items-center justify-between">
          <CardTitle>Tests</CardTitle>
          <Dialog open={isTestModalOpen} onOpenChange={setIsTestModalOpen}>
            <DialogTrigger asChild>
              <Button variant="orange">
                <Plus className="h-4 w-4 mr-2" />
                Create Tests
              </Button>
            </DialogTrigger>
            <DialogContent 
              onPointerDownOutside={(e) => {
                e.preventDefault();
              }}
            >
              <DialogHeader>
                <DialogTitle>Create New Test</DialogTitle>
              </DialogHeader>
              <NewTestForm 
                onSubmit={handleCreateTest} 
                isLoading={createNewTest.isPending}
                testTypes={testTypes}
                isLoadingTestTypes={isLoadingTestTypes}
              />
            </DialogContent>
          </Dialog>
        </CardHeader>
        <CardContent>
          {isLoading ? (
            <div className="space-y-4">
              {/* Desktop View Skeleton */}
              <div className="hidden lg:block">
                <div className="rounded-md">
                  {/* Table Header */}
                  <div className="grid grid-cols-7 gap-4 border-b pb-4">
                    <Skeleton className="h-4 w-12" variant="title" /> {/* S No. */}
                    <Skeleton className="h-4 w-32" variant="title" /> {/* Patient Name */}
                    <Skeleton className="h-4 w-28" variant="title" /> {/* Phone Number */}
                    <Skeleton className="h-4 w-24" variant="title" /> {/* Tests */}
                    <Skeleton className="h-4 w-28" variant="title" /> {/* Order Date */}
                    <Skeleton className="h-4 w-24" variant="title" /> {/* Test Status */}
                    <Skeleton className="h-4 w-20" variant="title" /> {/* Actions */}
                  </div>

                  {/* Table Rows */}
                  {[...Array(5)].map((_, index) => (
                    <div key={index} className="grid grid-cols-7 gap-4 py-4 border-b">
                      <div className="flex items-center">
                        <Skeleton className="h-4 w-8" variant="content" />
                      </div>
                      <div className="space-y-2">
                        <Skeleton className="h-4 w-32" variant="content" />
                        <Skeleton className="h-3 w-40" variant="content" />
                      </div>
                      <div>
                        <Skeleton className="h-4 w-28" variant="content" />
                      </div>
                      <div>
                        <Skeleton className="h-4 w-24" variant="content" />
                      </div>
                      <div>
                        <Skeleton className="h-4 w-28" variant="content" />
                      </div>
                      <div>
                        <Skeleton className="h-6 w-20 rounded-full" variant="content" />
                      </div>
                      <div className="flex justify-center">
                        <Skeleton className="h-8 w-24 rounded-md" variant="content" />
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Mobile and Tablet View Skeleton */}
              <div className="lg:hidden space-y-4">
                {[...Array(5)].map((_, index) => (
                  <div key={index} className="p-4 border rounded-lg space-y-4">
                    {/* Header */}
                    <div className="flex items-center justify-between border-b pb-3">
                      <div className="flex items-center gap-2">
                        <Skeleton className="h-4 w-8" variant="content" />
                        <Skeleton className="h-5 w-32" variant="content" />
                      </div>
                      <Skeleton className="h-4 w-24" variant="content" />
                    </div>

                    {/* Content */}
                    <div className="space-y-3">
                      <div className="flex justify-between items-start">
                        <div className="space-y-2">
                          <Skeleton className="h-4 w-40" variant="content" />
                          <Skeleton className="h-4 w-32" variant="content" />
                        </div>
                        <Skeleton className="h-6 w-24 rounded-full" variant="content" />
                      </div>

                      <div className="flex justify-between items-center">
                        <Skeleton className="h-4 w-20" variant="content" />
                        <Skeleton className="h-4 w-32" variant="content" />
                      </div>

                      <Skeleton className="h-9 w-full rounded-md" variant="content" />
                    </div>
                  </div>
                ))}
              </div>

              {/* Pagination Skeleton */}
              <div className="flex justify-center mt-6">
                <div className="flex gap-2 items-center">
                  <Skeleton className="h-8 w-20" variant="content" /> {/* Previous */}
                  {[...Array(3)].map((_, i) => (
                    <Skeleton key={i} className="h-8 w-8" variant="content" />
                  ))}
                  <Skeleton className="h-8 w-20" variant="content" /> {/* Next */}
                </div>
              </div>
            </div>
          ) : orders && orders.length > 0 ? (
            <>
              <div className="overflow-x-auto">
                <TestsTable 
                  tests={orders} 
                  formatDate={formatDate} 
                  currentPage={currentPage}
                  pageSize={10}
                />
              </div>
              {totalPagesOrders > 1 && (
                <div className="flex justify-center mt-4">
                  <ReactPaginate
                    previousLabel="Previous"
                    nextLabel="Next"
                    pageCount={totalPagesOrders}
                    onPageChange={handlePageChange}
                    forcePage={currentPage - 1}
                    containerClassName="flex gap-2 items-center"
                    previousClassName="px-2 py-1 text-sm rounded bg-gray-100 hover:bg-gray-200"
                    nextClassName="px-2 py-1 text-sm rounded bg-gray-100 hover:bg-gray-200"
                    pageClassName="px-2 py-1 text-sm rounded bg-gray-100 hover:bg-gray-200"
                    disabledClassName="opacity-50 cursor-not-allowed"
                    breakLabel="..."
                    activeClassName="!bg-[var(--primary)] text-white shadow-sm hover:bg-[var(--primary-hover)]"
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                  />
                </div>
              )}
            </>
          ) : (
            <div className="flex flex-col items-center justify-center py-8 text-center">
              <div className="rounded-full bg-muted p-3">
                <FileX className="h-6 w-6 text-muted-foreground" />
              </div>
              <h3 className="mt-4 text-lg font-semibold">No Tests Found</h3>
              <p className="mt-2 text-sm text-muted-foreground">
                No tests have been ordered yet. Click the "Order Test" button to create a new test.
              </p>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
}

export default withLayout(DoctorLayout)(Tests);
