import React from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

interface DonutChartProps {
  labels: string[]; // Labels for each segment of the donut chart
  series: number[]; // Data series for the donut chart
  title: string; // Title for the donut chart
}

const DonutChart: React.FC<DonutChartProps> = ({ labels, series, title }) => {
  // Define the chart options with proper typing for ApexOptions
  const chartOptions: ApexOptions = {
    chart: {
      type: "donut", // Set chart type to donut
    },
    labels: labels, // Set labels dynamically based on the data passed
    title: {
      text: title, // Set title dynamically based on the title prop
      align: "center", // Center-align the title
      style: {
        fontSize: "18px", // Customize the title font size
        fontWeight: "bold", // Make the title bold
        color: "#333", // Title color
      },
    },
    responsive: [
      {
        breakpoint: 480, // Apply styles for screens smaller than 480px
        options: {
          chart: {
            width: 200, // Adjust chart width on small screens
          },
          legend: {
            position: "bottom", // Position the legend at the bottom on small screens
          },
        },
      },
    ],
    legend: {
      position: "bottom", // Position the legend at the bottom
    },
  };

  return (
    <div className="donut-chart">
      <Chart options={chartOptions} series={series} type="donut" width="380" />
    </div>
  );
};

export default DonutChart;
