import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useApi } from '@/hooks/useApi';
import { useNavigate } from 'react-router-dom';
import { toast } from "sonner";


interface ContactFormData {
  name: string;
  email: string;
  phone: string;
  subject: string;
  message: string;
  nationality: string;
}

interface FormErrors {
  name?: string;
  email?: string;
  phone?: string;
  subject?: string;
  message?: string;
}



export const useContactUs = () => {
  const api = useApi();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<ContactFormData>({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
    nationality: ''
  });

  const [errors, setErrors] = useState<FormErrors>({});

  const validateForm = (): boolean => {
    const newErrors: FormErrors = {};
    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
    } else if (formData.name.trim().length < 2) {
      newErrors.name = 'Name must be at least 2 characters long';
    }

    if (!formData.phone) {
      newErrors.phone = 'Phone number is required';
    } else if (formData.phone.length < 10) {
      newErrors.phone = 'Please enter a valid phone number';
    }

    if (!formData.message.trim()) {
      newErrors.message = 'Message is required';
    } else if (formData.message.trim().length < 10) {
      newErrors.message = 'Message must be at least 10 characters long';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const submitContactForm = async (data: ContactFormData) => {
    const response = await api.post('/contact/contact_us', data);
    return response.data;
};

  const mutation = useMutation({
    mutationFn: submitContactForm,
    onSuccess: () => {
      toast.success('Submitted Successfully');
      navigate('/', { replace: true });
    },
  });

  const handlePhoneChange = (value: string, data: any) => {
    setFormData(prev => ({
      ...prev,
      phone: value,
      nationality: `+${data.dialCode}`
    }));

    if (errors.phone) {
      setErrors(prev => ({ ...prev, phone: undefined }));
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string) => {
    if (typeof e === 'string') {
      return;
    } else {
      const { name, value } = e.target;
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
      if (errors[name as keyof FormErrors]) {
        setErrors(prev => ({ ...prev, [name]: undefined }));
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      mutation.mutate(formData);
    }
  };

  return {
    formData,
    errors,
    isSubmitting: mutation.isPending,
    submitSuccess: mutation.isSuccess,
    handleChange,
    handlePhoneChange,
    handleSubmit
  };
};
