import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from '../../ui/button';
import { Card } from '../../ui/card';
import { PatientTableProps } from '@/types/doctor/testTypes';
import { usePatientTable } from './usePatientTable';
import { useMediaQuery } from '@/hooks/useMediaQuery';

export const PatientTable: React.FC<PatientTableProps> = (props) => {
  const { handleRowClick, patients, formatDate, currentPage, pageSize } = usePatientTable(props);
  const isMobile = useMediaQuery("(max-width: 640px)");
  const isTablet = useMediaQuery("(max-width: 1024px)");

  const getSerialNumber = (index: number) => {
    return (currentPage - 1) * pageSize + index + 1;
  };

  if (isMobile) {
    return (
      <div className="space-y-4">
        {patients?.map((patient) => (
          <Card key={patient._id} className="p-4">
            <div className="flex flex-col space-y-2">
              <div className="flex justify-between items-center">
                <div className="font-medium">{patient.firstName} {patient.lastName}</div>
              </div>
              <div className="text-sm text-gray-500">
                <div>Mobile: {patient.mobileNumber}</div>
                <div>Visit: {formatDate(patient.visitDate)}</div>
              </div>
              <Button
                size="sm"
                variant="orange"
                onClick={() => handleRowClick(patient._id || '')}
                className="rounded-full mt-2"
              >
                View Details
              </Button>
            </div>
          </Card>
        ))}
        {patients.length === 0 && (
          <p className="text-center py-4 text-gray-500">No records found.</p>
        )}
      </div>
    );
  }

  if (isTablet) {
    return (
      <div className="space-y-4">
        {patients?.map((patient, index) => (
          <Card key={patient._id} className="p-4">
            <div className="flex justify-between items-start">
              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <span className="text-sm text-muted-foreground">#{getSerialNumber(index)}</span>
                  <span className="font-medium">{patient.firstName} {patient.lastName}</span>
                </div>
                <div className="flex items-center gap-4 text-sm text-gray-500">
                  <div className="flex items-center gap-1">
                    <span>Mobile:</span>
                    <span>{patient.mobileNumber}</span>
                  </div>
                  <div className="flex items-center gap-1">
                    <span>Visit:</span>
                    <span>{formatDate(patient.visitDate)}</span>
                  </div>
                </div>
              </div>
              <Button
                size="sm"
                variant="orange"
                onClick={() => handleRowClick(patient._id || '')}
                className="rounded-full"
              >
                View Details
              </Button>
            </div>
          </Card>
        ))}
        {patients.length === 0 && (
          <p className="text-center py-4 text-gray-500">No records found.</p>
        )}
      </div>
    );
  }

  return (
    <div className="overflow-x-auto">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>#</TableHead>
            <TableHead>Patient Name</TableHead>
            <TableHead>Mobile Number</TableHead>
            <TableHead>Visit Date</TableHead>
            <TableHead className="text-center">Action</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {patients?.map((patient, index) => (
            <TableRow key={patient._id}>
              <TableCell>{getSerialNumber(index)}</TableCell>
              <TableCell>{patient.firstName} {patient.lastName}</TableCell>
              <TableCell>{patient.mobileNumber}</TableCell>
              <TableCell>{formatDate(patient.visitDate)}</TableCell>
              <TableCell className='text-center'>
                <Button
                  size="sm"
                  variant="orange"
                  onClick={() => handleRowClick(patient._id || '')}
                  className="rounded-full mt-2"
                >
                  View Details
                </Button>
              </TableCell>
            </TableRow>
          ))}
          {patients.length === 0 && (
            <TableRow>
              <TableCell colSpan={5} className="text-center py-4">
                <p className="text-gray-500">No records found.</p>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};
