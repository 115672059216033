import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { BookOpen, ArrowLeft, User2, FileText } from 'lucide-react';
import { useBookDetails } from './useBookDetails';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { useAuth } from '@/contexts/AuthContext';
import withLayout from '@/hoc/withLayout';
import DoctorLayout from '@/layouts/Doctor/DoctorLayout';
import PatientLayout from '@/layouts/Patient/PatientLayout';
import GeneralUserLayout from '@/layouts/GeneralUser/GeneralUserLayout';
import { Skeleton } from "@/components/ui/skeleton";

const BookDetailsSkeleton = () => {
  
  return (
    <div className="min-h-screen bg-[var(--background)]">
      <div className="relative overflow-hidden bg-[var(--secondary)]">
        <div className="absolute inset-0 bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px] opacity-50" />
        <div className="relative">
          <div className="container mx-auto px-4 py-8 sm:py-12">
            <Skeleton className="h-10 w-28 mb-8" />
            
            <div className="flex flex-col gap-8 lg:flex-row lg:items-start lg:gap-16">
              {/* Book Cover Skeleton */}
              <div className="w-48 flex-shrink-0 sm:w-64">
                <Skeleton className="aspect-[2/3] rounded-2xl" />
              </div>

              {/* Book Info Skeleton */}
              <div className="flex-1 space-y-8">
                <div>
                  <Skeleton className="h-8 w-24 rounded-full" />
                  <Skeleton className="mt-6 h-12 w-3/4" />
                  <Skeleton className="mt-3 h-8 w-1/2" />
                </div>

                {/* Price Card Skeleton */}
                <div className="rounded-2xl bg-[var(--secondary)] p-8 shadow-lg ring-1 ring-black/5">
                  <div className="flex flex-col gap-6 sm:flex-row sm:items-center sm:justify-between">
                    <div className="space-y-2">
                      <Skeleton className="h-10 w-32" />
                      <Skeleton className="h-6 w-24" />
                    </div>
                    <Skeleton className="h-12 w-full sm:w-40" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Content Section Skeleton */}
      <div className="container mx-auto px-4 py-12">
        <div className="grid gap-8 lg:grid-cols-3">
          <div className="lg:col-span-2">
            <Skeleton className="h-8 w-48 mb-4" />
            <div className="rounded-2xl bg-[var(--secondary)] p-8 shadow-lg ring-1 ring-black/5">
              <div className="space-y-4">
                <Skeleton className="h-4 w-full" />
                <Skeleton className="h-4 w-5/6" />
                <Skeleton className="h-4 w-4/6" />
              </div>
            </div>
          </div>

          <div>
            <Skeleton className="h-8 w-48 mb-4" />
            <div className="rounded-2xl bg-[var(--secondary)] p-6 shadow-lg ring-1 ring-black/5">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {[1, 2, 3].map((i) => (
                  <div key={i} className="space-y-2">
                    <Skeleton className="h-4 w-20" />
                    <Skeleton className="h-6 w-full" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export function BookDetailsPage() {
  const navigate = useNavigate();
  const {
    book,
    isLoading,
    isPaymentModalOpen,
    setIsPaymentModalOpen,
    handlePurchase,
    isPurchased,
    isProcessingPayment,
  } = useBookDetails();

  const searchParams = new URLSearchParams(window.location.search);
  const canPurchase = searchParams.get('enable_purchase') === 'true';

  if (isLoading) {
    return <BookDetailsSkeleton />;
  }

  if (!book) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-foreground">Book Not Found</h2>
          <p className="mt-2 text-muted-foreground">
            The book you're looking for doesn't exist or has been removed.
          </p>
          <Button className="mt-4" onClick={() => navigate('/library')}>
            Return to Library
          </Button>
        </div>
      </div>
    );
  }

  const handleReadClick = () => {
    navigate(`/reader/${book.bookId}`);
  };

  return (
    <div className="min-h-screen bg-[var(--background)]">
      {/* Hero Section */}
      <div className="relative overflow-hidden bg-[var(--secondary)]">
        {/* Background Pattern */}
        <div className="absolute inset-0 bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px] opacity-50" />

        <div className="relative">
          <div className="container mx-auto px-4 py-8 sm:py-12">

            
            <Button
              variant="outline"
              onClick={() => navigate('/library')}
              className="mb-8 inline-flex items-center text-sm"
            >
              <ArrowLeft className="mr-2 h-4 w-4" />
              Back to Library
            </Button>

            <div className="flex flex-col gap-8 lg:flex-row lg:items-start lg:gap-16">
              {/* Book Cover */}
              <div className="w-48 flex-shrink-0 sm:w-64">
                <div className="aspect-[2/3] overflow-hidden rounded-2xl bg-gradient-to-br from-[var(--primary)] to-[var(--primary-hover)]/20 shadow-xl ring-1 ring-[var(--primary)]/10 transition-transform hover:scale-105 duration-300">
                  {book.image ? (
                    <img
                      src={book.image}
                      alt={book.title}
                      className="h-full w-full object-cover"
                    />
                  ) : (
                    <div className="flex h-full w-full items-center justify-center">
                      <BookOpen className="h-16 w-16 text-[var(--secondary)]" />
                    </div>
                  )}
                </div>
              </div>

              {/* Book Info */}
              <div className="flex-1 space-y-8">
                <div>
                  <div className="flex flex-wrap items-center gap-3">
                    <span className="rounded-full bg-[var(--primary)]/10 px-4 py-1.5 text-sm font-medium text-[var(--primary)]">
                      {book.type}
                    </span>
                    <span className="text-sm text-muted-foreground">Published by {book.publisher}</span>
                  </div>
                  <h1 className="mt-6 text-3xl font-bold text-foreground sm:text-4xl tracking-tight">{book.title}</h1>
                  <p className="mt-3 text-xl text-muted-foreground font-medium">{book.author}</p>
                </div>

                {/* Price Card */}
                <div className="rounded-2xl bg-[var(--secondary)] p-8 shadow-lg ring-1 ring-black/5 hover:shadow-xl transition-shadow duration-300">
                  <div className="flex flex-col gap-6 sm:flex-row sm:items-center sm:justify-between">
                    <div className="space-y-1">
                      {book.price === 0 ? (
                        <div className="text-2xl font-bold text-[var(--primary)]">Free</div>
                      ) : book.discountedPrice < book.price ? (
                        <div>
                          <div className="text-3xl font-bold text-[var(--primary)]">₹{book.discountedPrice}</div>
                          <div className="flex items-center gap-3">
                            <span className="text-lg line-through text-muted-foreground">₹{book.price}</span>
                            <span className="rounded-full bg-[var(--primary)]/10 px-3 py-1 text-sm font-medium text-[var(--primary)]">
                              {Math.round(((book.price - book.discountedPrice) / book.price) * 100)}% OFF
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div className="text-3xl font-bold text-foreground">₹{book.price}</div>
                      )}
                    </div>

                    {isPurchased ? (
                      <Button
                        size="lg"
                        variant="orange"
                        className="w-full sm:w-auto hover:scale-105 transition-transform duration-300"
                        onClick={handleReadClick}
                      >
                        Read Now
                      </Button>
                    ) : (
                      <Button
                        size="lg"
                        variant="orange"
                        className="w-full sm:w-auto hover:scale-105 transition-transform duration-300"
                        onClick={() => setIsPaymentModalOpen(true)}
                        disabled={!canPurchase}
                      >
                        {canPurchase ? (book.price === 0 ? 'Add to Library' : 'Proceed to Payment') : 'Coming Soon'}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Content Section */}
      <div className="container mx-auto px-4 py-12">
        <div className="grid gap-8 lg:grid-cols-3">
          {/* About Section */}
          {book.description && (
            <div className="lg:col-span-2">
              <h2 className="text-xl font-semibold text-foreground mb-4">About this book</h2>
              <div className="rounded-2xl bg-[var(--secondary)] p-8 shadow-lg ring-1 ring-black/5 hover:shadow-xl transition-shadow duration-300">
                <p
                  className="text-muted-foreground leading-relaxed"
                  dangerouslySetInnerHTML={{ __html: book.description }}
                />
              </div>
            </div>
          )}

          {/* Details Section */}
          <div className={book.description ? undefined : 'lg:col-span-3'}>
            <h2 className="text-xl font-semibold text-foreground mb-4">Book Details</h2>
            <div className="rounded-2xl bg-[var(--secondary)] p-6 shadow-lg ring-1 ring-black/5">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="space-y-1">
                  <div className="text-sm text-muted-foreground">Author</div>
                  <div className="flex items-center gap-2">
                    <User2 className="h-4 w-4 text-[var(--primary)]" />
                    <span className="font-medium">{book.author}</span>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="text-sm text-muted-foreground">Publisher</div>
                  <div className="flex items-center gap-2">
                    <BookOpen className="h-4 w-4 text-[var(--primary)]" />
                    <span className="font-medium">{book.publisher}</span>
                  </div>
                </div>
                <div className="space-y-1 md:col-span-2">
                  <div className="text-sm text-muted-foreground">Category</div>
                  <div className="flex items-center gap-2">
                    <FileText className="h-4 w-4 text-[var(--primary)]" />
                    <span className="font-medium">{book.type}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog open={isPaymentModalOpen} onOpenChange={setIsPaymentModalOpen}>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle className="text-2xl font-bold">
              {canPurchase ? 'Confirm Purchase' : 'Coming Soon!'}
            </DialogTitle>
            <DialogDescription className="text-muted-foreground mt-2">
              {canPurchase 
                ? `Are you sure you want to ${book.price === 0 ? 'add' : 'purchase'} "${book.title}" ${book.price === 0 ? 'to your library' : 'for ₹' + book.discountedPrice}?`
                : "We're currently working on setting up our payment system. This feature will be available soon."}
            </DialogDescription>
          </DialogHeader>
          <div className="space-y-6 py-4">
            {canPurchase ? (
              <div className="flex flex-col gap-3">
                <Button
                  variant="orange"
                  className="w-full"
                  onClick={handlePurchase}
                >
                  {book.price === 0 ? 'Add to Library' : 'Confirm Payment'}
                </Button>
                <Button
                  variant="outline"
                  className="w-full"
                  onClick={() => setIsPaymentModalOpen(false)}
                >
                  Cancel
                </Button>
              </div>
            ) : (
              <Button
                variant="orange"
                className="w-full"
                onClick={() => setIsPaymentModalOpen(false)}
              >
                Close
              </Button>
            )}
          </div>
        </DialogContent>
      </Dialog>

      {isProcessingPayment && (
        <div className="fixed inset-0 bg-background/80 backdrop-blur-sm z-40">
          <div className="fixed left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] sm:max-w-md w-full">
            <div className="bg-[var(--secondary)] rounded-2xl border shadow-2xl p-8">
              <div className="flex flex-col items-center justify-center space-y-6">
                <div className="animate-spin rounded-full h-10 w-10 border-4 border-[var(--primary)] border-t-transparent"></div>
                <h2 className="text-2xl font-semibold">Processing Payment</h2>
                <p className="text-center text-muted-foreground">
                  Please wait while we prepare your payment. Do not close or reload the browser.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

// Export the component with conditional layout



export function BookDetails() {
  const { user } = useAuth();

  if (user?.role === 'DOCTOR') {
    return withLayout(DoctorLayout)(BookDetailsPage)({});
  }


  if (user?.role === 'PATIENT') {
    return withLayout(PatientLayout)(BookDetailsPage)({});
  }

  return withLayout(GeneralUserLayout)(BookDetailsPage)({});
}