import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { BookResponse, Book, BookPurchaseResponse, BookPage, TableOfContents } from '@/types/book';
import { useApi } from '@/hooks/useApi';
import { useBookReader } from '@/contexts/BookReaderContext';

// Book Queries
export const useBooks = (params: {
    page?: number;
    perPage?: number;
    sortBy?: 'title' | 'author' | 'price' | 'type';
    order?: 'asc' | 'desc';
}) => {
    const api = useApi();
    return useQuery({
        queryKey: ['books', params],
        queryFn: async () => {
            const { data } = await api.get<BookResponse>('/books', { params });
            return data;
        }
    });
};

export const useBook = (bookId: string) => {
    const api = useApi();
    return useQuery({
        queryKey: ['book', bookId],
        queryFn: async () => {
            const { data } = await api.get<Book>(`/books/${bookId}`);
            return data;
        },
        enabled: !!bookId
    });
};

export const useSearchBooks = (term: string) => {
    const api = useApi();
    return useQuery({
        queryKey: ['books', 'search', term],
        queryFn: async () => {
            const { data } = await api.get<Book[]>('/books/search', {
                params: { term }
            });
            return {
                items: data,
                pagination: {
                    currentPage: 1,
                    perPage: data.length,
                    total: data.length,
                    totalPages: 1,
                    nextPage: null,
                    previousPage: null
                }
            } as BookResponse;
        },
        enabled: !!term
    });
};

export const useBookContent = (bookId: string, pageNumber: number) => {
    const api = useApi();
    const { pages, tableOfContents } = useBookReader();
    const payload = pages.find(page => page.page === pageNumber);
    
    return useQuery({
        queryKey: ['bookContent', bookId, pageNumber],
        queryFn: async () => {
            const { data } = await api.post(`/books/${bookId}/page`, payload);
            return data;
        },
        enabled: !!bookId && !!pageNumber && tableOfContents.length > 0 && !!payload
    });
};

// Progress Mutations
export const useUpdateProgress = () => {
    const api = useApi();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ bookId, progress }: {
            bookId: string;
            progress: {
                pageNumber: number;
                pageType: string;
                pageData: any;
            }
        }) => {
            const { data } = await api.post(`/books/${bookId}/progress`, progress);
            return data;
        },
        onSuccess: (_, { bookId }) => {
            queryClient.invalidateQueries({ queryKey: ['book', bookId] });
        }
    });
};

// Library Queries
export const useLibrary = (params?: { search?: string }) => {
    const api = useApi();
    return useQuery({
        queryKey: ['library', params],
        queryFn: async () => {
            const { data } = await api.get('/books/my-library', { params });
            return data;
        }
    });
};

// Purchase Mutations
export const useCreatePurchase = () => {
    const api = useApi();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (bookId: string) => {
            const { data } = await api.post<BookPurchaseResponse>(`/books/${bookId}/purchase`);
            return data;
        },
        onSuccess: () => {
            // Invalidate library query to refresh the books list
            queryClient.invalidateQueries({ queryKey: ['library'] });
        }
    });
};

export const useVerifyPayment = () => {
    const api = useApi();
    return useMutation({
        mutationFn: async ({ bookId, paymentData }: {
            bookId: string;
            paymentData: {
                razorpay_payment_id: string;
                razorpay_order_id: string;
                razorpay_signature: string;
            }
        }) => {
            const { data } = await api.post(`/books/payment/verify-purchase/${bookId}`, paymentData);
            return data;
        }
    });
};

// Bookmark Mutations
export interface Bookmark {
    id: string;
    bookId: string;
    pageNumber: number;
    content: string;
    note?: string;
    createdAt: Date;
}

export const useAddBookmark = () => {
    const api = useApi();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({
            bookId,
            pageNumber,
            content,
            note
        }: Omit<Bookmark, 'id' | 'createdAt'>) => {
            const { data } = await api.post(`/books/${bookId}/bookmarks`, {
                pageNumber,
                content,
                note
            });
            return data;
        },
        onSuccess: (_, { bookId }) => {
            queryClient.invalidateQueries({ queryKey: ['bookmarks', bookId] });
        }
    });
};

export const useGetBookmarks = (bookId: string) => {
    const api = useApi();
    return useQuery({
        queryKey: ['bookmarks', bookId],
        queryFn: async () => {
            const { data } = await api.get<Bookmark[]>(`/books/${bookId}/bookmarks`);
            return data;
        }
    });
};

export const useDeleteBookmark = () => {
    const api = useApi();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ bookId, bookmarkId }: { bookId: string; bookmarkId: string }) => {
            const { data } = await api.delete(`/books/${bookId}/bookmarks/${bookmarkId}`);
            return data;
        },
        onSuccess: (_, { bookId }) => {
            queryClient.invalidateQueries({ queryKey: ['bookmarks', bookId] });
        }
    });
};

// Highlight Mutations
export interface Highlight {
    id: string;
    bookId: string;
    pageNumber: number;
    content: string;
    color: string;
    createdAt: Date;
}

export const useAddHighlight = () => {
    const api = useApi();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({
            bookId,
            pageNumber,
            content,
            color
        }: Omit<Highlight, 'id' | 'createdAt'>) => {
            const { data } = await api.post(`/books/${bookId}/highlights`, {
                pageNumber,
                content,
                color
            });
            return data;
        },
        onSuccess: (_, { bookId }) => {
            queryClient.invalidateQueries({ queryKey: ['highlights', bookId] });
        }
    });
};

export const useGetHighlights = (bookId: string) => {
    const api = useApi();
    return useQuery({
        queryKey: ['highlights', bookId],
        queryFn: async () => {
            const { data } = await api.get<Highlight[]>(`/books/${bookId}/highlights`);
            return data;
        }
    });
};

export const useDeleteHighlight = () => {
    const api = useApi();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ bookId, highlightId }: { bookId: string; highlightId: string }) => {
            const { data } = await api.delete(`/books/${bookId}/highlights/${highlightId}`);
            return data;
        },
        onSuccess: (_, { bookId }) => {
            queryClient.invalidateQueries({ queryKey: ['highlights', bookId] });
        }
    });
};


// Add the TOC query
export const useBookGeneratePagesAndToc = (bookId: string) => {
    const api = useApi();
    return useQuery({
        queryKey: ['book-generate-pages-and-toc', bookId],
        queryFn: async () => {
            const { data } = await api.get<{
                pages: BookPage[];
                toc: TableOfContents[];
            }>(`/books/${bookId}/generate-pages`);
            return data;
        },
        enabled: !!bookId
    });
};
