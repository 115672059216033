import React from 'react';
import ScrollReveal from '../ScrollReveal/ScrollReveal';
import {  BookText, MonitorSmartphone, Brain, FileChartColumn } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';

interface InsightCardProps {
    icon: React.ReactNode;
    title: string;
    subtitle: string;
    description: string;
}

const InsightCard: React.FC<InsightCardProps> = ({ icon, title, subtitle, description }) => (
    
    <Card className="w-full max-w-sm bg-white shadow-lg rounded-xl overflow-hidden">
        <CardHeader className='pb-0'>
            <div className="flex items-center justify-center w-16 h-16 mx-auto  rounded-full text-[var(--primary-hover)]">
                {icon}
            </div>
            <CardTitle  className="text-xl md:text-2xl font-semibold text-[#2D3748] mb-4 text-center">{title}</CardTitle>
        </CardHeader>
        
        <CardContent >
        <h5 className="text-lg font-semibold text-[var(--primary)] mb-2 text-center">
    {subtitle}
    <hr className="my-4 border-gray-300 dark:border-gray-500" />
</h5>
            <div className=" text-center text-base md:text-lg text-gray-600 leading-relaxed"  dangerouslySetInnerHTML={{ __html: description }} />
        </CardContent>
    </Card>
);

const AIInsights: React.FC = () => {
    const insights = [
        {
            icon: <BookText className="w-12 h-12" />,
            title: "AI Chatbooks",
            Subtitle:"Chat with Charaka Samhita!",
            description: "<p>Along with other classical Ayurveda textbooks with <strong>Vedita AI</strong>, your mentor for learning and conversation.</p>"
        },
        {
            icon: <MonitorSmartphone className="w-12 h-12" />,
            title: "Patient Simulator",
            Subtitle:"Gain experience!",
            description: "<p>On virtual patients, systematically practice your skills, receive ratings, and get feedback to refine your diagnosis and treatment decisions.</p>"
        },
        {
            icon: <Brain className="w-12 h-12" />,
            title: "AI Diagnosis & Treatment",
            Subtitle:"Discuss your case with Vedita AI!",
            description: "<p>In real-time or later to get tailored suggestions on patient complaints, examinations, investigations, differential diagnosis, and treatment plans empowering you to create a prescription based on your decisions.</p>"
        },
        {
            icon: <FileChartColumn className="w-12 h-12" />,
            title: "Ayurveda Analytics",
            Subtitle:"Order Vikriti test online!",
            description: "<p>Transform your practice with <strong>Vedita AI</strong>—patients complete assessments through a web link, and you receive detailed reports including Prakriti, Vikriti, and Health risk analysis!</p>"
        }
    ];

    return (
        <section className="bg-[#FDF6F4] py-20 w-full max-w-7xl">
            <div className="container mx-auto px-4 md:px-6">
                <ScrollReveal>
                    <div className="flex items-center justify-center mb-10">
                        <h2 className="text-4xl md:text-5xl font-semibold text-[#2D3748] text-center">
                            Empowering Doctors with AI-Driven Insights
                        </h2>
                    </div>
              
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 justify-items-center">
                    {insights.map((insight, index) => (
                        <InsightCard
                            key={index}
                            icon={insight.icon}
                            title={insight.title}
                            subtitle={insight.Subtitle}
                            description={insight.description}
                        />
                    ))}
                </div>
                </ScrollReveal>
            </div>
        </section>
    );
};

export default AIInsights;
