import { useApi } from "@/hooks/useApi";
import { TestDetails } from "@/types/doctor/testTypes";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

export const useTestService = (currentPage: number, pageSize: number) => {
  const api = useApi();
  const queryClient = useQueryClient();

  const getOrders = useQuery({
    queryKey: ["orders", currentPage, pageSize],
    queryFn: async ({ queryKey }) => {
      const [_, page, limit] = queryKey as [string, number, number];
      const response = await api.get(`/tests/ordered-tests?page=${page}&limit=${limit}`);
      const { items, total, pagination } = response.data;

      return {
        orders: items || [],
        total: total || 0,
        pagination: pagination || {
          currentPage: page,
          totalPages: Math.ceil((total || 0) / Number(limit))
        }
      };
    },
  });

  const getTests = useQuery({
    queryKey: ["tests"],
    queryFn: async () => {
      const response = await api.get("/tests");
      return response.data;
    },
  });

  const getTestTypes = useQuery({
    queryKey: ["testTypes"],
    queryFn: async () => {
      const response = await api.get("/tests");
      return response.data;
    },
  });

  const createOrder = async (testId: string) => {
    const response = await api.post(`/assessments/payment/create-order/${testId}`);
    return response.data;
  };

  const verifyPayment = async ({ 
    orderId, 
    paymentData 
  }: { 
    orderId: string, 
    paymentData: {
      razorpay_payment_id: string;
      razorpay_order_id: string;
      razorpay_signature: string;
    }
  }) => {
    const response = await api.post(`/assessments/payment/verify-order/${orderId}`, paymentData);
    return response.data;
  };

  const createNewTest = useMutation({
    mutationFn: async (testData: Partial<TestDetails>[] | Partial<TestDetails>) => {
      const response = await api.post("/tests/order", testData);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["orders"] });
    },
  });

  const verifyPaymentMutation = useMutation({
    mutationFn: verifyPayment,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["orders"] });
    },
  });

  const createOrderMutation = useMutation({
    mutationFn: createOrder,
  });

  return {
    getOrders,
    getTests,
    getTestTypes,
    createNewTest,
    createOrder: createOrderMutation,
    verifyPayment: verifyPaymentMutation,
  };
};