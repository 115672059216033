import { useState, useCallback } from 'react'
import { Test, TestStatus } from '@/types/patient/test'
import { useNavigate } from 'react-router-dom'
import { useTestService } from '@/services/Patient/testService'
import { CreateTestRequest } from "@/types/patient/test"

export const useTests = () => {
  const navigate = useNavigate()
  const [isTestModalOpen, setIsTestModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1)
  const pageSize = 10
  const { getOrders ,getTestTypes,createNewTest} = useTestService()
  const { data: ordersData, isLoading, refetch } = getOrders(currentPage, pageSize)


  const { data: testTypesData, isLoading: isLoadingTestTypes } = getTestTypes;


  const testTypes = testTypesData ? testTypesData.map((type: any) => ({
    value: type.id.toString(),
    label: type.name,
    pricing: type.pricing
})) : [];

  const handlePageChange = useCallback((newPage: number) => {
    if (newPage === currentPage) return;
    setCurrentPage(newPage);
  }, [currentPage]);

  const tests: Test[] = ordersData?.orders?.map((order: any, index: number) => ({
    id: order._id,
    serialNumber: ((ordersData.pagination.currentPage - 1) * pageSize) + index + 1,
    tests: order.testIds.map((test: any) => test.name),
    testDetails: order.testIds.map((test: any) => ({
      _id: test._id,
      name: test.name,
      description: test.description,
      pricing: {
        price: test.pricing.price,
        currency: test.pricing.currency,
        discountPrice: test.pricing.discountPrice
      }
    })),
    addedByDr:order.patientMobile=== order.doctorId?.mobile ? 'Self' : `Dr. ${order.doctorId?.name || 'Unknown'}`,
    isTaken: order.status === 'TEST_COMPLETED',
    isPaid: order.status !== 'PAYMENT_PENDING',
    result: order.result || undefined,
    status: order.status as TestStatus,
    orderedAt: new Date(order.orderedAt).toLocaleDateString(),
    amount: order.totalAmount,
    currency: order.currency
  })) || []

  const handleViewTest = (test: Test) => {
    navigate(`/patient/orderDetails/${test.id}`)
  }


  const handleCreateTest =  (data: CreateTestRequest) => {
    
       createNewTest.mutate(data,{
        onSuccess: () => {
          setIsTestModalOpen(false);
          refetch();
       }});
      
  };

  return {
    tests,
    currentPage,
    setCurrentPage: handlePageChange,
    pageSize,
    isLoading,
    ordersData,
    handleViewTest,
    isTestModalOpen,
    setIsTestModalOpen,
    totalPages: ordersData?.pagination?.totalPages || 1,
    testTypes,
    isLoadingTestTypes,
    handleCreateTest
  }
}
