import { TongueAnalysisResponse } from '@/types/tongueAnalysis';
import { useMutation } from '@tanstack/react-query';
import { useApi } from '@/hooks/useApi';

interface AnalyzeImageRequest {
  imageUrl: string;
  assessmentId: string;
}

export const useTongueAnalysis = () => {
  const api = useApi();

  return useMutation({
    
    mutationFn: async (request: AnalyzeImageRequest) => {
      const { data } = await api.post<TongueAnalysisResponse>(
        `assessments/report/Tongue_Analysis/${request.assessmentId}`,
        request
      );
      return data;
    }
  });
};
