import { useAdminService } from "@/services/adminService";
import { DoctorDetails } from "@/types/doctor";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";

export function useDoctorDetails() {
  const navigate = useNavigate();

  const { id } = useParams();
  const adminService = useAdminService();

  const { data: doctor, isLoading } = useQuery<DoctorDetails>({
    queryKey: ['doctor', id],
    queryFn: () => {
      if (!id) throw new Error('Doctor ID is required');
      return adminService.getDoctor(id);
    },
    enabled: !!id
  });

  
  const mutation = useMutation({
    mutationFn: ({ status }: { status: boolean }) => 
      adminService.updateDoctorStatus(id!, status),
    onSuccess: () => {
      navigate('/admin/dashboard');
    },
    onError: (error) => {
      console.error(error);
      toast.error("Failed to update doctor status");
    }
  });

  const handleStatusUpdate = (status: boolean) => {
    if (!id) {
      toast.error('Doctor ID is required');
      return;
    }

    mutation.mutate({ status });
  };


  return {
    doctor,
    isLoading,
    handleStatusUpdate,
  };
}
