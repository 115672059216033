import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import ReactMarkdown from 'react-markdown';
import remarkGfm from "remark-gfm";

interface Props {
    title: string;
    markdown: string;
}

export const MarkdownCard: React.FC<Props> = ({ title, markdown }) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
      </CardHeader>
      <CardContent>
        <ReactMarkdown
          className="prose prose-sm max-w-none dark:prose-invert"
          remarkPlugins={[remarkGfm]}
        >
          {markdown}
        </ReactMarkdown>
      </CardContent>
    </Card>
  );
};
