import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';

const SIDEBAR_STATE_KEY = 'doctorLayoutSidebarOpen';

interface SidebarContextType {
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
}

const SidebarContext = createContext<SidebarContextType | undefined>(undefined);

export const SidebarProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(() => {
    const savedState = localStorage.getItem(SIDEBAR_STATE_KEY);
    return savedState ? JSON.parse(savedState) : window.innerWidth >= 1024;
  });

  const handleResize = useCallback(() => {
    if (window.innerWidth >= 1024) {
      const savedState = localStorage.getItem(SIDEBAR_STATE_KEY);
      setIsSidebarOpen(savedState ? JSON.parse(savedState) : true);
    } else {
      setIsSidebarOpen(false);
    }
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  useEffect(() => {
    if (window.innerWidth >= 1024) {
      localStorage.setItem(SIDEBAR_STATE_KEY, JSON.stringify(isSidebarOpen));
    }
    if (isSidebarOpen) {
      document.body.classList.add('sidebar-open');
    } else {
      document.body.classList.remove('sidebar-open');
    }
  }, [isSidebarOpen]);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev: boolean) => {
      const newState = !prev;
      if (window.innerWidth >= 1024) {
        localStorage.setItem(SIDEBAR_STATE_KEY, JSON.stringify(newState));
      }
      return newState;
    });
  };

  return (
    <SidebarContext.Provider value={{ isSidebarOpen, toggleSidebar }}>
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = () => {
  const context = useContext(SidebarContext);
  if (context === undefined) {
    throw new Error('useSidebar must be used within a SidebarProvider');
  }
  return context;
};
