import React, { useRef } from 'react';
import { Camera, Upload, X, Loader2 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { useTongueAnalysis } from './useTongueAnalysis';
import Webcam from 'react-webcam';
import { TongueAttribute } from '@/types/tongueAnalysis';
import PatientLayout from '@/layouts/Patient/PatientLayout';
import withLayout from '@/hoc/withLayout';

 function TongueAnalysis() {
  const {
    previewUrl,
    isCameraOpen,
    setIsCameraOpen,
    isAnalyzing,
    isUploading,
    analysisResult,
    handleImageUpload,
    handleAnalyze,
    clearImage,
  } = useTongueAnalysis();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const webcamRef = useRef<Webcam>(null);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      handleImageUpload(file);
    }
  };

  const captureImage = React.useCallback(() => {
    const imageSrc = webcamRef.current?.getScreenshot();
    if (imageSrc) {
      fetch(imageSrc)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], 'tongue-image.jpg', { type: 'image/jpeg' });
          handleImageUpload(file);
          setIsCameraOpen(false);
        });
    }
  }, [webcamRef]);

  return (
    <div className="container mx-auto px-4 py-8">
      <Card className="max-w-2xl mx-auto">
        <CardHeader>
          <CardTitle className="text-2xl font-bold text-center">
            Tongue Analysis
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-6">
          {/* Image Upload Area */}
          {!previewUrl && !isCameraOpen && (
            <div className="border-2 border-dashed border-muted-foreground/25 rounded-lg p-8">
              <div className="flex flex-col items-center gap-4">
                <div className="text-muted-foreground text-center">
                  <p className="text-lg font-medium">Upload a tongue image</p>
                  <p className="text-sm">or take a picture using your camera</p>
                </div>
                <div className="flex gap-4">
                  <Button
                    variant="outline"
                    onClick={() => fileInputRef.current?.click()}
                  >
                    <Upload className="mr-2 h-4 w-4" />
                    Upload Image
                  </Button>
                  <Button
                    variant="outline"
                    onClick={() => setIsCameraOpen(true)}
                  >
                    <Camera className="mr-2 h-4 w-4" />
                    Take Picture
                  </Button>
                </div>
                <input
                  type="file"
                  ref={fileInputRef}
                  className="hidden"
                  accept="image/*"
                  onChange={handleFileUpload}
                />
              </div>
            </div>
          )}

          {/* Camera Interface */}
          {isCameraOpen && (
            <div className="relative">
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                className="w-full rounded-lg"
              />
              <div className="absolute bottom-4 left-0 right-0 flex justify-center gap-4">
                <Button onClick={captureImage}>Capture</Button>
                <Button variant="outline" onClick={() => setIsCameraOpen(false)}>
                  Cancel
                </Button>
              </div>
            </div>
          )}

          {/* Image Preview */}
          {previewUrl && (
            <div className="relative">
              <img
                src={previewUrl}
                alt="Tongue preview"
                className="w-full rounded-lg"
              />
              <Button
                variant="outline"
                size="icon"
                className="absolute top-2 right-2"
                onClick={clearImage}
              >
                <X className="h-4 w-4" />
              </Button>
            </div>
          )}

          {/* Analysis Button */}
          {previewUrl && !analysisResult && (
            <Button
              className="w-full bg-orange-500 hover:bg-orange-600 text-white"
              onClick={handleAnalyze}
              disabled={isAnalyzing || isUploading}
            >
              {(isUploading || isAnalyzing) && (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              )}
              {isUploading 
                ? 'Uploading...' 
                : isAnalyzing 
                  ? 'Analyzing...' 
                  : 'Analyze Image'}
            </Button>
          )}

          {/* Loading State
          {isAnalyzing && (
            <div className="flex items-center justify-center gap-2 text-muted-foreground">
              <Loader2 className="h-4 w-4 animate-spin" />
              <span>Analyzing your tongue image...</span>
            </div>
          )} */}

          {/* Results */}
          {analysisResult && (
            <Card className="mt-6">
              <CardHeader>
                <CardTitle className="text-xl">Analysis Results</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="space-y-6">
                  {/* Image Type */}
                  <div>
                    <h4 className="font-medium">Image Type:</h4>
                    <p className="text-muted-foreground">
                      {analysisResult?.data?.data?.response?.tongue_image_analysis?.image_type}
                    </p>
                  </div>

                  {/* Final Analysis */}
                  <div>
                    <h4 className="font-medium">Final Analysis:</h4>
                    <p className="text-muted-foreground">
                      {analysisResult?.data?.data?.response?.tongue_image_analysis?.final_analysis}
                    </p>
                  </div>

                  {/* Attributes */}
                  <div>
                    <h4 className="font-medium mb-4">Detailed Analysis:</h4>
                    <div className="space-y-4">
                      {Object.entries(analysisResult?.data?.data?.response?.tongue_image_analysis?.attributes || {}).map(([key, value]) => (
                        <div key={key} className="border rounded-lg p-4">
                          <h5 className="font-medium capitalize mb-2">{key.replace(/_/g, ' ')}</h5>
                          <div className="grid gap-2">
                            <div>
                              <span className="text-sm font-medium">Description:</span>
                              <p className="text-sm text-muted-foreground">
                                {(value as TongueAttribute).description}
                              </p>
                            </div>
                            <div>
                              <span className="text-sm font-medium">Ayurvedic Analysis:</span>
                              <p className="text-sm text-muted-foreground">
                                {(value as TongueAttribute).ayurvedic_analysis}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          )}
        </CardContent>
      </Card>
    </div>
  );
}


export default withLayout(PatientLayout)(TongueAnalysis);