import React from 'react';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Search, BookOpen, X } from 'lucide-react';
import { MyLibraryBook } from '@/types/book';
import { useMyLibrary } from './useMyLibrary';
import { useAuth } from '@/contexts/AuthContext';
import withLayout from '@/hoc/withLayout';
import DoctorLayout from '@/layouts/Doctor/DoctorLayout';
import PatientLayout from '@/layouts/Patient/PatientLayout';
import GeneralUserLayout from '@/layouts/GeneralUser/GeneralUserLayout';
import { BookCard } from '@/components/book/BookCard';
import { Skeleton } from '@/components/ui/skeleton';

function MyLibraryPage() {
    const {
        books,
        isLoading,
        searchTerm,
        setSearchTerm,
        handleBookClick,
        navigate
    } = useMyLibrary();

    const getBookPlaceholderBg = (index: number) => {
        const colors = [
            'bg-gradient-to-br from-blue-100 to-blue-200',
            'bg-gradient-to-br from-emerald-100 to-emerald-200',
            'bg-gradient-to-br from-orange-100 to-orange-200',
            'bg-gradient-to-br from-rose-100 to-rose-200'
        ];
        return colors[index % colors.length];
    };

    return (
        <div className="min-h-screen bg-background">
            <div className="container mx-auto px-4 py-6 sm:py-8">
                {/* Header */}
                <div className="mb-8 space-y-6">
                    <div className="flex flex-col gap-4 sm:flex-row sm:items-center sm:justify-between">
                        <div>
                            <h1 className="text-2xl font-bold text-foreground sm:text-3xl">My Library</h1>
                            <p className="mt-1 text-muted-foreground">Continue reading where you left off</p>
                        </div>
                        <div className="relative w-full sm:w-72">
                            <Search className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-muted-foreground" />
                            <Input
                                placeholder="Search your books..."
                                className="w-full pl-10 pr-10"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            {searchTerm && (
                                <button
                                    onClick={() => setSearchTerm('')}
                                    className="absolute right-3 top-1/2 -translate-y-1/2 text-muted-foreground hover:text-foreground"
                                >
                                    <X className="h-4 w-4" />
                                </button>
                            )}
                        </div>
                    </div>
                </div>

                {/* Books Grid */}
                {isLoading ? (
                    <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-3 sm:gap-4">
                        {[...Array(12)].map((_, index) => (
                            <div key={index} className="flex flex-col space-y-3 rounded-lg border p-4">
                                <Skeleton variant="title" className="h-[180px] w-full rounded-md" />
                                <div className="space-y-2">
                                    <Skeleton variant="content" className="h-4 w-3/4" />
                                    <Skeleton variant="content" className="h-3 w-1/2" />
                                    <Skeleton variant="content" className="h-2 w-1/4 mt-2" />
                                </div>
                            </div>
                        ))}
                    </div>
                ) : books?.length === 0 ? (
                    <div className="flex h-[400px] flex-col items-center justify-center gap-4">
                        <div className="text-center">
                            <p className="text-lg font-medium text-foreground">No books found</p>
                            <p className="mt-1 text-muted-foreground">
                                {searchTerm
                                    ? `No results found for "${searchTerm}"`
                                    : 'Your library is empty. Purchase books to start reading.'}
                            </p>
                        </div>
                        {searchTerm ? (
                            <Button variant="outline" onClick={() => setSearchTerm('')}>
                                Clear Search
                            </Button>
                        ) : (
                            <Button onClick={() => navigate('/library')}>Browse Books</Button>
                        )}
                    </div>
                ) : (
                    <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-3 sm:gap-4">
                        {books?.map((book: MyLibraryBook, index: number) => (
                            <BookCard
                                key={book.bookId}
                                book={book}
                                onClick={handleBookClick}
                                index={index}
                            />  
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}

// Export the component with conditional layout
export function MyLibrary() {
    const { user } = useAuth();

    if (user?.role === 'DOCTOR') {
        return withLayout(DoctorLayout)(MyLibraryPage)({});
    }

    if (user?.role === 'PATIENT') {
        return withLayout(PatientLayout)(MyLibraryPage)({});
    }

    return withLayout(GeneralUserLayout)(MyLibraryPage)({});
}
