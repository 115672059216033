//@ts-nocheck
import React, { useState, useEffect, useCallback } from 'react'
import { cn } from '@/lib/utils'
import { GripVertical } from 'lucide-react'

export function ResizablePanel({
  children,
  defaultSize = 50,
  minSize = 20,
  maxSize = 80,
  side = 'left',
}) {
  const [isResizing, setIsResizing] = useState(false)
  const [size, setSize] = useState(defaultSize)

  const startResizing = useCallback(() => {
    setIsResizing(true)
  }, [])

  const stopResizing = useCallback(() => {
    setIsResizing(false)
  }, [])

  const resize = useCallback(
    (e) => {
      if (isResizing) {
        const containerWidth = window.innerWidth
        const clientX = e.touches ? e.touches[0].clientX : e.clientX
        const newSize = (clientX / containerWidth) * 100

        if (newSize >= minSize && newSize <= maxSize) {
          setSize(newSize)
        }
      }
    },
    [isResizing, minSize, maxSize]
  )

  useEffect(() => {
    if (isResizing) {
      window.addEventListener('mousemove', resize)
      window.addEventListener('mouseup', stopResizing)
      window.addEventListener('touchmove', resize)
      window.addEventListener('touchend', stopResizing)
    }

    return () => {
      window.removeEventListener('mousemove', resize)
      window.removeEventListener('mouseup', stopResizing)
      window.removeEventListener('touchmove', resize)
      window.removeEventListener('touchend', stopResizing)
    }
  }, [isResizing, resize, stopResizing])

  return (
    <div
      className={cn(
        'h-screen relative',
        side === 'left' ? 'pr-1' : 'pl-1'
      )}
      style={{ width: `${size}%` }}
    >
      {children}
      <div
        onMouseDown={startResizing}
        onTouchStart={startResizing}
        className={cn(
          'absolute top-0 w-1 h-full cursor-col-resize hover:bg-primary/20 transition-colors',
          isResizing && 'bg-primary',
          side === 'left' ? 'right-0' : 'left-0'
        )}
      >
        <div className="absolute top-1/2 -translate-y-1/2 left-[-4px]">
          <GripVertical className="h-4 w-4 text-muted-foreground" />
        </div>
      </div>
    </div>
  )
}