import { toast } from "sonner";
import { InputField } from "../types/global";

// Add the sleep function
export const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const validateEmail = (email: string) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return {
        error: !re.test(String(email).toLowerCase()),
        errorText: "Invalid email address",
    };
};

export const validatePassword = (password: string) => {
    if (password.length < 6) {
        return { error: true, errorText: "Password must be at least 6 characters long" };
    } else {
        return { error: false, errorText: "" };
    }
};

export const handleValidation = (field: InputField) => {
  let error = false;
  let errorText = "";
  const value = field.value;
  const name = field.name;
  if (
    `${value}`.trim().length === 0
  ) {
    error = true;
    errorText = `${name} is required`;
  } else {
    switch (name) {
      case "email":
        const validation = validateEmail(`${value}`);
        error = validation.error;
        errorText = validation.errorText;
        break;
      case "password":
        const passwordValidation = validatePassword(`${value}`);
        error = passwordValidation.error;
        errorText = passwordValidation.errorText;
        break;
    }
  }
  return { error, errorText };
};

export const hasError = (inputFields: Record<string, InputField>) => {
  let error = false;
  for (const field in inputFields) {
    const validation = handleValidation(inputFields[field]);
    if (!error) {
      error = validation.error;
    }
  }
  return error;
}

export const handleApiErrors = (error:any) => {
  if(error.response){
    toast.error(error.response.data.message)
  }else{
    toast.error("Something went wrong!!!")
  }
}

export const hexToText = (hex: string): string | null => {
  if (!hex) return null;
  try {
    const bytes = new Uint8Array(hex.match(/.{1,2}/g)?.map(byte => parseInt(byte, 16)) || []);
    return new TextDecoder().decode(bytes);
  } catch (error) {
    console.error('Error decoding hex:', error);
    return null;
  }
};

export async function convertToWav(audioBlob: Blob): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async (event) => {
      const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
      const audioBuffer = await audioContext.decodeAudioData(event.target?.result as ArrayBuffer);
      
      const numberOfChannels = 1; // Mono
      const length = audioBuffer.length;
      const sampleRate = 16000; // 16kHz
      const wavBuffer = audioContext.createBuffer(numberOfChannels, length, sampleRate);

      // Mix down to mono if necessary
      const channelData = audioBuffer.getChannelData(0);
      if (audioBuffer.numberOfChannels > 1) {
        for (let i = 1; i < audioBuffer.numberOfChannels; i++) {
          const channel = audioBuffer.getChannelData(i);
          for (let j = 0; j < length; j++) {
            channelData[j] += channel[j];
          }
        }
        for (let i = 0; i < length; i++) {
          channelData[i] /= audioBuffer.numberOfChannels;
        }
      }
      wavBuffer.copyToChannel(channelData, 0);

      const wavBlob = await new Promise<Blob>((innerResolve) => {
        const worker = new Worker(URL.createObjectURL(new Blob([`
          onmessage = function(e) {
            const buffer = e.data;
            const length = buffer.length;
            const sampleRate = ${sampleRate};
            const wavPcm = new Int16Array(length);
            
            // Convert Float32Array to Int16Array
            for (let i = 0; i < length; i++) {
              const s = Math.max(-1, Math.min(1, buffer[i]));
              wavPcm[i] = s < 0 ? s * 0x8000 : s * 0x7FFF;
            }

            const wavFile = new ArrayBuffer(44 + wavPcm.length * 2);
            const view = new DataView(wavFile);

            // Write WAV header
            writeString(view, 0, 'RIFF');
            view.setUint32(4, 36 + wavPcm.length * 2, true);
            writeString(view, 8, 'WAVE');
            writeString(view, 12, 'fmt ');
            view.setUint32(16, 16, true);
            view.setUint16(20, 1, true);
            view.setUint16(22, 1, true);
            view.setUint32(24, sampleRate, true);
            view.setUint32(28, sampleRate * 2, true);
            view.setUint16(32, 2, true);
            view.setUint16(34, 16, true);
            writeString(view, 36, 'data');
            view.setUint32(40, wavPcm.length * 2, true);

            // Write PCM audio
            for (let i = 0; i < wavPcm.length; i++) {
              view.setInt16(44 + i * 2, wavPcm[i], true);
            }

            function writeString(view, offset, string) {
              for (let i = 0; i < string.length; i++) {
                view.setUint8(offset + i, string.charCodeAt(i));
              }
            }

            postMessage(new Blob([wavFile], {type: 'audio/wav'}));
          }
        `])));
        worker.onmessage = (e) => {
          innerResolve(e.data);
          worker.terminate();
        };
        worker.postMessage(wavBuffer.getChannelData(0));
      });

      resolve(wavBlob);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(audioBlob);
  });
}
