import { Button } from "@/components/ui/button"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { Badge } from "@/components/ui/badge"
import { format } from "date-fns"
import { Link } from "react-router-dom";
import { Card } from "@/components/ui/card";

interface DoctorsTableProps {
  doctors: {
    _id: string;
    name: string;
    email: string;
    mobile: string;
    isApproved: boolean;
    createdAt: string;
  }[];
}

const getStatusColor = (isApproved: boolean) => {
  return isApproved ? "bg-green-500" : "bg-yellow-500";
}

export function DoctorsTable({ doctors }: DoctorsTableProps) {
  return (
    <>
      {/* Mobile View */}
      <div className="space-y-4 md:hidden">
        {doctors.map((doctor) => (
          <Card key={doctor._id} className="p-4">
            <div className="flex flex-col space-y-2">
              <div className="flex justify-between items-start">
                <div>
                  <h3 className="font-medium">{doctor.name}</h3>
                  <p className="text-sm text-muted-foreground">{doctor.email}</p>
                </div>
                <Badge className={getStatusColor(doctor.isApproved)}>
                  {doctor.isApproved ? "Approved" : "Pending"}
                </Badge>
              </div>
              <div className="text-sm">
                <div className="grid grid-cols-2 gap-1">
                  <span className="text-muted-foreground">Mobile:</span>
                  <span>{doctor.mobile}</span>
                  <span className="text-muted-foreground">Joined:</span>
                  <span>{format(new Date(doctor.createdAt), 'MMM d, yyyy')}</span>
                </div>
              </div>
              <div className="pt-2">
                <Link to={`/admin/doctorsDetails/${doctor._id}`} className="w-full">
                  <Button 
                    variant="outline" 
                    size="sm"
                    className="w-full"
                  >
                    View Details
                  </Button>
                </Link>
              </div>
            </div>
          </Card>
        ))}
      </div>

      {/* Desktop View */}
      <div className="hidden md:block rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Name</TableHead>
              <TableHead>Email</TableHead>
              <TableHead>Mobile</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Joined</TableHead>
              <TableHead className="text-right">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {doctors.map((doctor) => (
              <TableRow key={doctor._id}>
                <TableCell className="font-medium">{doctor.name}</TableCell>
                <TableCell>{doctor.email}</TableCell>
                <TableCell>{doctor.mobile}</TableCell>
                <TableCell>
                  <Badge className={getStatusColor(doctor.isApproved)}>
                    {doctor.isApproved ? "Approved" : "Pending"}
                  </Badge>
                </TableCell>
                <TableCell>{format(new Date(doctor.createdAt), 'MMM d, yyyy')}</TableCell>
                <TableCell className="text-right">
                  <Link to={`/admin/doctorsDetails/${doctor._id}`}>
                    <Button 
                      variant="outline" 
                      size="sm"
                    >
                      View Details
                    </Button>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  )
}
