import { X, Library } from "lucide-react";
import { Link } from "react-router-dom";
import { useEffect, useRef } from "react";
import { Button } from "@/components/ui/button";

interface SidebarProps {
  isOpen: boolean;
  toggleSidebar: () => void;
}

export function Sidebar({ isOpen, toggleSidebar }: SidebarProps) {
  const sidebarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node) && isOpen) {
        toggleSidebar();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, toggleSidebar]);

  return (
    <>
      {isOpen && <div className="fixed inset-0 bg-black/50 z-40" />}
      <div
        ref={sidebarRef}
        className={`fixed inset-y-0 left-0 z-50 w-64 bg-[var(--background)] text-[var(--primary-foreground)] p-4 transform transition-transform duration-300 ease-in-out ${isOpen ? "translate-x-0" : "-translate-x-full"
          }`}
      >
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold text-[var(--primary-hover)]">Vedita AI</h1>
          <Button onClick={toggleSidebar} variant="ghost" size="icon" className="hover:bg-[var(--primary-hover)] hover:text-[var(--secondary-text)]">
            <X className="h-6 w-6" />
          </Button>
        </div>
        <nav>
          <ul className="space-y-2">
            <div className="mt-6 pt-6">
              <h2 className="px-2 mb-2 text-xs font-semibold text-[var(--primary-foreground)]/80 uppercase">Library</h2>
              <SidebarItem href="/library" icon={<Library />} text="Browse Books" onClick={toggleSidebar} />
              <SidebarItem href="/my-library" icon={<Library />} text="My Books" onClick={toggleSidebar} />
            </div>
          </ul>
        </nav>
      </div>
    </>
  );
}

function SidebarItem({ href, icon, text, onClick }: { href: string; icon: React.ReactNode; text: string; onClick: () => void }) {
  return (
    <li>
      <Link to={href} className="flex items-center space-x-2 p-2 rounded hover:bg-[var(--primary)] hover:text-[var(--secondary-text)]" onClick={onClick}>
        {icon}
        <span>{text}</span>
      </Link>
    </li>
  );
}
