import { UseNewTestFormProps } from "@/types/patient/test";
import { useState } from "react";

export const useNewTestForm = ({ onSubmit, testTypes }: UseNewTestFormProps) => {

 
  const [selectedTests, setSelectedTests] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [errors, setErrors] = useState<{selectedTests: string;}>({selectedTests: ""});

  const handleSelect = (value: string) => {
    setSelectedTests((current) => {
      const newSelection = current.includes(value)
        ? current.filter((item) => item !== value)
        : [...current, value];
      return newSelection;
    });
  };

  const handleRemoveTest = (valueToRemove: string) => {
    setSelectedTests((current) => 
      current.filter((value) => value !== valueToRemove)
    );
  };

  const filteredTests = testTypes.filter((test) =>
    test.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const validateForm = () => {
    const newErrors = {
    
      selectedTests: "",
     
    };



    if (selectedTests.length === 0) {
      newErrors.selectedTests = "At least one test must be selected";
    }


    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      onSubmit({
        selectedTests
      });
    }
  };

  return {
   
    selectedTests,
    open,
    setOpen,
    searchQuery,
    setSearchQuery,
    errors,
    filteredTests,
    handleSelect,
    handleRemoveTest,
    handleSubmit,

  };
};
