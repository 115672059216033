import { useState } from 'react';
import { toast } from 'sonner';
import { useDocumentService } from '@/services/useDocumentService';
import { useTongueAnalysis as useAnalyzeImage } from '@/services/tongueAnalysisService';
import { TongueAnalysisResponse } from '@/types/tongueAnalysis';
import { useParams } from 'react-router-dom';

export function useTongueAnalysis() {
    const { assessmentId } = useParams();
  const [image, setImage] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  
  const { uploadDocument } = useDocumentService();
  const analyzeImage = useAnalyzeImage();

  const handleImageUpload = async (file: File) => {
    // Create preview URL
    const previewUrl = URL.createObjectURL(file);
    setPreviewUrl(previewUrl);
    setImage(file);

    // Upload to S3 using document service
    uploadDocument.mutate(file, {
      onSuccess: (response) => {
        const imageUrl = response.data.fileUrl;
        setPreviewUrl(imageUrl); // Update preview with S3 URL
      },
      onError: (error) => {
        toast.error('Failed to upload image: ' + error.message);
        clearImage();
      },
    });
  };

  const handleAnalyze = async () => {
    if (!previewUrl) {
      toast.error('Please select an image first');
      return;
    }

    analyzeImage.mutate(
      { imageUrl: previewUrl ,assessmentId:assessmentId || ''},
      {
        onSuccess: (response) => {
          
          
          // Navigate through the nested structure
          const analysisResult = response.data.data.response.tongue_image_analysis;
          
          if (!analysisResult?.image_type || 
              analysisResult.image_type.toLowerCase() === "not a tongue image" ||
              analysisResult.final_analysis.includes("No tongue image provided")) {
            toast.error('Please upload a clear image of a tongue');
            clearImage();
            return;
          }

          // If we reach here, we have a valid analysis
          toast.success('Analysis completed successfully');
        },
        onError: (error) => {
          console.error('Analysis error:', error);
          toast.error('Failed to analyze image: ' + error.message);
        },
      }
    );
  };

  const clearImage = () => {
    if (previewUrl) {
      URL.revokeObjectURL(previewUrl);
    }
    setImage(null);
    setPreviewUrl(null);
    analyzeImage.reset(); // Reset the analysis results
  };

  return {
    image,
    previewUrl,
    isCameraOpen,
    setIsCameraOpen,
    isAnalyzing: analyzeImage.isPending,
    isUploading: uploadDocument.isPending,
    analysisResult: analyzeImage.data,
    handleImageUpload,
    handleAnalyze,
    clearImage,
  };
}
