import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useOrderDetails } from "./useOrderDetails";
import { Badge } from "@/components/ui/badge";
import {
  IndianRupee,
  FileText,
  Stethoscope,
  ScrollText,
  Lock,
  ArrowLeft,
} from "lucide-react";
import cn from "classnames";
import PatientLayout from "@/layouts/Patient/PatientLayout";
import withLayout from "@/hoc/withLayout";
import { Test } from "@/services/Patient/orderService";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Skeleton } from "@/components/ui/skeleton";
import { Link, useNavigate } from "react-router-dom";

export const OrderDetailsPage = () => {
  const { orderDetails, isLoading, handleProceedToPayment, handleStartTest } =
    useOrderDetails();
  const navigate = useNavigate();

  console.log("orderDetailsorderDetails", orderDetails);

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-50/40 py-8">
        <div className="container mx-auto px-4">
          <div className="space-y-8">
            {/* Header Skeleton */}
            <div className="space-y-2">
              <Skeleton className="h-10 w-48" />
              <Skeleton className="h-6 w-96" />
            </div>

            <div className="flex flex-col lg:flex-row gap-8">
              {/* Doctor Information Section Skeleton */}
              <div className="w-full lg:w-[30%]">
                <Card className="overflow-hidden border-none shadow-md h-full">
                  <CardHeader className="bg-primary/5 pb-4">
                    <div className="flex items-center gap-3">
                      <Skeleton className="h-12 w-12 rounded-full" />
                      <div className="space-y-2">
                        <Skeleton className="h-6 w-32" />
                        <Skeleton className="h-4 w-24" />
                      </div>
                    </div>
                  </CardHeader>
                  <CardContent className="p-6">
                    <div className="space-y-6">
                      {/* Doctor Details Skeleton */}
                      <Card className="border shadow-sm">
                        <CardHeader className="pb-3 bg-gray-50/50">
                          <div className="flex items-center gap-2">
                            <Skeleton className="h-6 w-32" />
                          </div>
                        </CardHeader>
                        <CardContent className="pt-4">
                          <div className="space-y-4">
                            <div className="space-y-2">
                              <Skeleton className="h-6 w-48" />
                              <Skeleton className="h-4 w-32" />
                            </div>
                          </div>
                        </CardContent>
                      </Card>

                      {/* Payment Details Skeleton */}
                      <Card className="border shadow-sm">
                        <CardHeader className="pb-3 bg-gray-50/50">
                          <div className="flex items-center gap-2">
                            <Skeleton className="h-6 w-32" />
                          </div>
                        </CardHeader>
                        <CardContent className="pt-4">
                          <div className="space-y-4">
                            <div className="grid grid-cols-2 gap-4">
                              <div className="space-y-2">
                                <Skeleton className="h-4 w-16" />
                                <Skeleton className="h-6 w-24" />
                              </div>
                              <div className="space-y-2">
                                <Skeleton className="h-4 w-16" />
                                <Skeleton className="h-6 w-24" />
                              </div>
                            </div>
                            <Skeleton className="h-8 w-24" />
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                  </CardContent>
                </Card>
              </div>

              {/* Test Details Section Skeleton */}
              <div className="w-full lg:w-[70%]">
                <Card className="overflow-hidden border-none shadow-md h-full">
                  <CardHeader className="bg-primary/5 pb-4">
                    <div className="flex items-center gap-3">
                      <Skeleton className="h-12 w-12 rounded-full" />
                      <div className="space-y-2">
                        <Skeleton className="h-6 w-32" />
                        <Skeleton className="h-4 w-48" />
                      </div>
                    </div>
                  </CardHeader>
                  <CardContent className="p-6">
                    <ScrollArea className="h-[400px]">
                      <div className="space-y-4 pr-4">
                        {[...Array(3)].map((_, index) => (
                          <Card key={index} className="border shadow-sm">
                            <CardContent className="p-4">
                              <div className="space-y-4">
                                <div className="flex flex-col sm:flex-row sm:items-start sm:justify-between gap-2 sm:gap-4">
                                  <div className="space-y-2">
                                    <Skeleton className="h-6 w-48" />
                                    <Skeleton className="h-4 w-32" />
                                  </div>
                                  <Skeleton className="h-10 w-28" />
                                </div>
                                <Skeleton className="h-20 w-full" />
                              </div>
                            </CardContent>
                          </Card>
                        ))}
                      </div>
                    </ScrollArea>
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!orderDetails) {
    return (
      <div className="min-h-screen bg-gray-50/40 py-8">
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-center h-64">
            <p className="text-lg text-gray-500">No order details found</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50/40 py-8">
      <div className="container mx-auto px-4">
        <div className="mb-8">
        <Button
              variant="outline"
              onClick={() => navigate('/patient/test')}
              className="mb-1 inline-flex items-center text-sm"
            >
              <ArrowLeft className="mr-2 h-4 w-4" />
              Back to Tests
            </Button>
          <div className="flex items-center gap-2 mb-2">
           
            <h1 className="text-3xl font-bold text-gray-900">Order Details</h1>
          </div>
          <p className="text-gray-500">
            View your order information and test details
          </p>
        </div>

        <div className="flex flex-col lg:flex-row gap-8">
          {/* Doctor Information Section */}
          <div className="w-full lg:w-[30%]">
            <Card className="overflow-hidden border-none shadow-md h-full">
              <CardHeader className="bg-primary/5 pb-4">
                <div className="flex items-center gap-3">
                  <div className="p-3 bg-primary/10 rounded-full">
                    <Stethoscope className="h-6 w-6 text-primary" />
                  </div>
                  <div>
                    <CardTitle>Doctor Information</CardTitle>
                    <CardDescription>
                      View doctor and payment information
                    </CardDescription>
                  </div>
                </div>
              </CardHeader>
              <CardContent className="p-6">
                <div className="space-y-6">
                  {/* Doctor Details Card */}
                  <Card className="border shadow-sm">
                    <CardHeader className="pb-3 bg-gray-50/50">
                      <div className="flex items-center gap-2">
                        <ScrollText className="h-4 w-4 text-gray-500" />
                        <CardTitle className="text-lg">
                          Doctor Details
                        </CardTitle>
                      </div>
                    </CardHeader>
                    <CardContent className="pt-4">
                      <div className="space-y-4">
                        <div>
                          <h3 className="text-xl font-semibold text-gray-900">
                            {orderDetails?.doctorId?.name}
                          </h3>
                          <p className="text-gray-500 mt-1">
                            {orderDetails?.doctorId?.specialization}
                          </p>
                        </div>
                      </div>
                    </CardContent>
                  </Card>

                  {/* Payment Details Card */}
                  <Card className="border shadow-sm">
                    <CardHeader className="pb-3 bg-gray-50/50">
                      <div className="flex items-center gap-2">
                        <IndianRupee className="h-4 w-4 text-gray-500" />
                        <CardTitle className="text-lg">
                          Payment Details
                        </CardTitle>
                      </div>
                    </CardHeader>
                    <CardContent className="pt-4">
                      <div className="space-y-4">
                        <div className="grid grid-cols-2 gap-4">
                          <div>
                            <p className="text-sm text-gray-500">Amount</p>
                            <div className="flex items-center gap-2 mt-1">
                              {Number(orderDetails?.totalAmount) === 0 ? (
                                <span className="text-lg font-semibold">Free</span>
                              ) : (
                                <>
                                  <IndianRupee className="h-4 w-4" />
                                  <span className="text-lg font-semibold">{Number(orderDetails?.totalAmount).toFixed(2)}</span>
                                </>
                              )}
                            </div>
                          </div>
                          <div>
                            <p className="text-sm text-gray-500">Currency</p>
                            <p className="text-lg font-semibold mt-1">
                              {orderDetails?.currency}
                            </p>
                          </div>
                        </div>
                        <div>
                          <div className="mt-2">
                            {orderDetails?.status === "PAYMENT_COMPLETED" ? (
                              <p className="text-green-600 font-medium">
                                Payment Done
                              </p>
                            ) : (
                              <Button
                                variant="orange"
                                size="sm"
                                onClick={handleProceedToPayment}
                              >
                                Pay Now
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </CardContent>
            </Card>
          </div>

          {/* Test Details Section */}
          <div className="w-full lg:w-[70%]">
            <Card className="overflow-hidden border-none shadow-md h-full">
              <CardHeader className="bg-primary/5 pb-4">
                <div className="flex items-center gap-3">
                  <div className="p-3 bg-primary/10 rounded-full">
                    <FileText className="h-6 w-6 text-primary" />
                  </div>
                  <div>
                    <CardTitle>Ordered Tests</CardTitle>
                    <CardDescription>
                      View your ordered tests and their status
                    </CardDescription>
                  </div>
                </div>
              </CardHeader>
              <CardContent className="p-6">
                <ScrollArea className="h-[400px]">
                  <div className="space-y-4 pr-4">
                    {orderDetails?.tests?.map((test: Test) => (
                      <Card key={test._id} className="border shadow-sm">
                        <CardContent className="p-4">
                          <div className="space-y-2">
                            <div className="flex flex-col sm:flex-row sm:items-start sm:justify-between gap-2 sm:gap-4">
                              <div className="space-y-2 sm:space-y-0">
                                <h3 className="text-lg font-semibold text-gray-900">
                                  {test.name}
                                </h3>
                                <div className="flex items-center gap-2 text-gray-600">
                                  <IndianRupee className="h-4 w-4" />
                                  <span className="font-medium">
                                    {test.pricing.discountPrice > 0 ? (
                                      <>
                                        <span className="text-primary">
                                          {test.pricing.discountPrice}
                                        </span>
                                        <span className="ml-2 text-sm text-gray-400 line-through">
                                          {test.pricing.price}
                                        </span>
                                      </>
                                    ) : (
                                      <span>{test.pricing.price}</span>
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div className="sm:flex-shrink-0">
                                {orderDetails?.status ===
                                "PAYMENT_COMPLETED" ? (
                                  <Button
                                    variant="orange"
                                    className="w-full sm:w-auto"
                                    onClick={() => {
                                      test.assessment?.status != "COMPLETED" &&
                                        handleStartTest(
                                          test._id,
                                          test.assessment
                                        );
                                    }}
                                  >
                                    {test.assessment?.status === "COMPLETED" ? (
                                      <Link
                                        to={`/test-results/${test.assessment?._id}/${test.reportType}/${orderDetails.patientGender}`}
                                        className="text-primary-foreground"
                                      >
                                        View Report
                                      </Link>
                                    ) : test.assessment ? (
                                      "Resume Test"
                                    ) : (
                                      "Start Test"
                                    )}
                                  </Button>
                                ) : (
                                  <Button
                                    className="w-full sm:w-auto"
                                    variant="outline"
                                    disabled
                                  >
                                    <Lock className="h-4 w-4 mr-2" />
                                    Start Test
                                  </Button>
                                )}
                              </div>
                            </div>
                            {test.description && (
                              <Accordion
                                type="single"
                                collapsible
                                className="w-full"
                              >
                                <AccordionItem value="description">
                                  <AccordionTrigger className="text-sm font-medium">
                                    Description
                                  </AccordionTrigger>
                                  <AccordionContent>
                                    <div
                                      className="prose prose-sm max-w-none text-gray-600 pt-2"
                                      dangerouslySetInnerHTML={{
                                        __html: test.description,
                                      }}
                                    />
                                  </AccordionContent>
                                </AccordionItem>
                              </Accordion>
                            )}
                            <Badge
                              variant={
                                test.assessment?.status === "COMPLETED"
                                  ? "default"
                                  : test.assessment?.status === "IN_PROGRESS"
                                  ? "outline"
                                  : "secondary"
                              }
                              className={cn(
                                "whitespace-nowrap",
                                test.assessment?.status === "COMPLETED" &&
                                  "bg-green-100 text-green-800 hover:bg-green-100",
                                test.assessment?.status === "IN_PROGRESS" &&
                                  "bg-yellow-100 text-yellow-800 hover:bg-yellow-100",
                                test.assessment?.status === "PENDING" &&
                                  "bg-gray-100 text-gray-800 hover:bg-gray-100"
                              )}
                            >
                              {test.assessment?.status}
                            </Badge>
                          </div>
                        </CardContent>
                      </Card>
                    ))}
                  </div>
                </ScrollArea>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withLayout(PatientLayout)(OrderDetailsPage);
