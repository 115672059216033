import React, { useEffect } from 'react'
import { AdminAppBar } from './AdminAppBar';
import { useAuth } from '@/contexts/AuthContext';

const AdminLayoutContent: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const { logout, user } = useAuth();
    
    useEffect(() => {
        if (user && user?.role !== "ADMIN") {
            logout();
        }
    }, [user, logout]);

    return (
        <div className="flex flex-col h-screen">
            <div className="fixed top-0 left-0 right-0 z-10">
                <AdminAppBar />
            </div>
            <main className="flex-1 overflow-y-auto w-full" style={{ marginTop: '4rem' }}>
                {children}
            </main>
        </div>
    );
};

export const AdminLayout: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    return (
        <AdminLayoutContent>{children}</AdminLayoutContent>
    );
};

export default AdminLayout;
