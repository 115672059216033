import { useApi } from "@/hooks/useApi";
import { ChatPayload, BookChatRequest, BookAIResponse } from "@/types/global";

export const useChatService = () => {
  const api = useApi();

  const processInput = async (payload:{
    doctorInput: ChatPayload[],
    sessionId: string,
    mode: string,
    variables?: { [key: string]: string }
  }): Promise<any> => {
    try {
      const response = await api.post("/chat/process-input", {
        userMessage: payload.doctorInput,
        sessionId: payload.sessionId,
        variables: payload.variables,
        mode: payload.mode
      });
      return response.data;
    } catch (error) {
      console.error("Error processing AI interaction:", error);
      throw error;
    }
  };

  const processBookChat = async (payload: BookChatRequest): Promise<BookAIResponse> => {
    try {
      const response = await api.post("/chat/chat-books", payload);
      return response.data;
    } catch (error) {
      console.error("Error processing book chat:", error);
      throw error;
    }
  };

  const generatePrescription = async (caseData: any): Promise<any> => {
    try {
      const response = await api.post("/chat/generate-prescription", {
        caseData,
      });
      return response.data;
    } catch (error) {
      console.error("Error generating prescription:", error);
      throw error;
    }
  };

  const testOpenAI = async (message: string): Promise<string> => {
    try {
      const response = await api.post("/chat/test-openai", { message });
      return response.data.response;
    } catch (error) {
      console.error("Error testing OpenAI:", error);
      throw error;
    }
  };

  return {
    processInput,
    processBookChat,
    generatePrescription,
    testOpenAI,
  };
};
