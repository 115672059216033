"use client"

import { useState } from "react"
import { format } from "date-fns"
import { CalendarIcon, Pencil, Upload } from "lucide-react"
import { Skeleton } from "@/components/ui/skeleton"

import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { Textarea } from "@/components/ui/Textarea"
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { useProfile } from "./useProfile"
import withLayout from "@/hoc/withLayout"
import PatientLayout from "@/layouts/Patient/PatientLayout"
import { useAuth } from "@/contexts/AuthContext"
import GeneralUserLayout from "@/layouts/GeneralUser/GeneralUserLayout"

function Profile() {
  const [isEditing, setIsEditing] = useState(false)
  const { form, onSubmit, avatar, handleImageUpload, isLoading } = useProfile()

  const handleSubmit = (values: any) => {
    onSubmit(values)
    setIsEditing(false)
  }

  if (isLoading) {
    return (
      <div className="container mx-auto px-2 sm:px-4 pt-6">
        <div className="grid gap-6">
          <Card>
            <CardHeader className="pb-0">
              <div className="flex flex-col sm:flex-row items-center sm:items-start justify-between space-y-4 sm:space-y-0">
                {/* Avatar and Edit Button Skeleton */}
                <div className="w-full sm:w-64 flex flex-col items-center space-y-4">
                  <Skeleton className="w-24 sm:w-32 h-24 sm:h-32 rounded-full" />
                  <Skeleton className="h-9 w-32" />
                </div>

                {/* Form Fields Skeleton */}
                <div className="flex-1 w-full sm:pl-6">
                  <Skeleton className="h-8 w-48 mb-6 mx-auto sm:mx-0" />
                  <div className="space-y-6">
                    <div className="grid gap-6 sm:grid-cols-1 md:grid-cols-2">
                      {/* Name Field */}
                      <div className="space-y-2">
                        <Skeleton className="h-4 w-16" />
                        <Skeleton className="h-10 w-full" />
                      </div>

                      {/* Email Field */}
                      <div className="space-y-2">
                        <Skeleton className="h-4 w-16" />
                        <Skeleton className="h-10 w-full" />
                      </div>

                      {/* Phone Number Field */}
                      <div className="space-y-2">
                        <Skeleton className="h-4 w-32" />
                        <div className="flex gap-2">
                          <Skeleton className="h-10 w-20" />
                          <Skeleton className="h-10 flex-1" />
                        </div>
                      </div>

                      {/* Date of Birth Field */}
                      <div className="space-y-2">
                        <Skeleton className="h-4 w-24" />
                        <Skeleton className="h-10 w-full" />
                      </div>

                      {/* Gender Field */}
                      <div className="space-y-2">
                        <Skeleton className="h-4 w-20" />
                        <div className="flex gap-4">
                          <Skeleton className="h-6 w-20" />
                          <Skeleton className="h-6 w-20" />
                        </div>
                      </div>

                      {/* Address Field */}
                      <div className="space-y-2 md:col-span-2">
                        <Skeleton className="h-4 w-20" />
                        <Skeleton className="h-20 w-full" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardHeader>
          </Card>
        </div>
      </div>
    )
  }

  return (
    <div className="container mx-auto px-2 sm:px-4 pt-6">
      <div className="grid gap-6">
        <Card>
          <CardHeader className="pb-0">
            <div className="flex flex-col sm:flex-row items-center sm:items-start justify-between space-y-4 sm:space-y-0">
              <div className="w-full sm:w-64 flex flex-col items-center space-y-4">
                <div className="relative group">
                  <Avatar className="w-24 sm:w-32 h-24 sm:h-32 cursor-pointer hover:opacity-80 transition-opacity">
                    <AvatarImage 
                      src={avatar} 
                      alt="Patient profile picture"
                      onClick={() => isEditing && document.getElementById('profile-upload')?.click()} 
                    />
                    <AvatarFallback>
                      {form.getValues("name")?.[0]?.toUpperCase()}
                    </AvatarFallback>
                  </Avatar>
                  {/* {isEditing && (
                    <div 
                      className="absolute inset-0 flex items-center justify-center bg-black/40 text-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity cursor-pointer"
                      onClick={() => document.getElementById('profile-upload')?.click()}
                    >
                      <Upload className="h-6 w-6" />
                    </div>
                  )} */}

{isEditing && (
                  <div className="absolute bottom-0 right-0">
                    <input
                      type="file"
                      id="avatar-upload"
                      className="hidden"
                      accept="image/*"
                      onChange={(e) => {
                        const file = e.target.files?.[0];
                        if (file) handleImageUpload(file);
                      }}
                    />
                    <Button
                      type="button"
                      size="icon"
                      variant="orange"
                      className="rounded-full h-8 w-8"
                      onClick={() => document.getElementById('profile-upload')?.click()}
                      // disabled={isUploading}
                    >
                      <Upload className="h-4 w-4" />
                    </Button>
                  </div>
                )}

                  <input
                    type="file"
                    id="profile-upload"
                    className="hidden"
                    accept=".jpg,.jpeg,.png"
                    onChange={(e) => handleImageUpload(e.target.files?.[0])}
                    disabled={!isEditing}
                  />
                </div>
                {!isEditing && (
                  <Button
                    variant="orange"
                    size="sm"
                    onClick={() => setIsEditing(true)}
                    className="w-full sm:w-auto"
                  >
                    <Pencil className="w-4 h-4 mr-2" />
                    Edit Profile
                  </Button>
                )}
              </div>

              <div className="flex-1 w-full sm:pl-6">
                <CardTitle className="text-2xl font-bold mb-6 text-center sm:text-left">
                  {form.getValues("name") || "Patient Profile"}
                </CardTitle>
                <Form {...form}>
                  <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-6">
                    <div className="grid gap-6 sm:grid-cols-1 md:grid-cols-2">
                      <FormField
                        control={form.control}
                        name="name"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Name</FormLabel>
                            <FormControl>
                              <Input {...field} disabled={!isEditing} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="email"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Email</FormLabel>
                            <FormControl>
                              <Input {...field} type="email" disabled={true} className="bg-muted" />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="mobile"
                        render={({ field }) => (
                          <FormItem className="flex flex-col justify-end">
                            <FormLabel>Phone Number</FormLabel>
                            <FormControl>
                              <div className="flex gap-2">
                                <Input 
                                  value={form.getValues("countryCode")} 
                                  disabled 
                                  className="w-20 bg-muted"
                                />
                                <Input {...field} disabled={true} className="bg-muted" />
                              </div>
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="dateOfBirth"
                        render={({ field }) => (
                          <FormItem className="flex flex-col justify-end">
                            <FormLabel>Date of birth</FormLabel>
                            <Popover>
                              <PopoverTrigger asChild>
                                <FormControl>
                                  <Button
                                    variant={"outline"}
                                    className={cn(
                                      "w-full pl-3 text-left font-normal h-10",
                                      !field.value && "text-muted-foreground"
                                    )}
                                    disabled={!isEditing}
                                  >
                                    {field.value ? (
                                      format(new Date(field.value), "PPP")
                                    ) : (
                                      <span>Pick a date</span>
                                    )}
                                    <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                                  </Button>
                                </FormControl>
                              </PopoverTrigger>
                              <PopoverContent className="w-auto p-0" align="start">
                                <Calendar
                                  mode="single"
                                  selected={field.value ? new Date(field.value) : undefined}
                                  onSelect={(date) => field.onChange(date?.toISOString())}
                                  disabled={(date) => !isEditing || date > new Date()}
                                  
                                />
                              </PopoverContent>
                            </Popover>
                            <FormMessage />
                          </FormItem>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="gender"
                        render={({ field }) => (
                          <FormItem className="space-y-3">
                            <FormLabel>Gender</FormLabel>
                            <FormControl>
                              <RadioGroup
                                onValueChange={field.onChange}
                                value={field.value}
                                className="flex flex-col space-y-1"
                                disabled={!isEditing}
                              >
                                <FormItem className="flex items-center space-x-3 space-y-0">
                                  <FormControl>
                                    <RadioGroupItem value="MALE" />
                                  </FormControl>
                                  <FormLabel className="font-normal">
                                    Male
                                  </FormLabel>
                                </FormItem>
                                <FormItem className="flex items-center space-x-3 space-y-0">
                                  <FormControl>
                                    <RadioGroupItem value="FEMALE" />
                                  </FormControl>
                                  <FormLabel className="font-normal">
                                    Female
                                  </FormLabel>
                                </FormItem>
                              </RadioGroup>
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="bloodGroup"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Blood Group</FormLabel>
                            <Select 
                              onValueChange={field.onChange}
                              value={field.value}
                              disabled={!isEditing}
                            >
                              <FormControl>
                                <SelectTrigger>
                                  <SelectValue placeholder="Select blood group" />
                                </SelectTrigger>
                              </FormControl>
                              <SelectContent>
                                <SelectItem value="A+">A+</SelectItem>
                                <SelectItem value="A-">A-</SelectItem>
                                <SelectItem value="B+">B+</SelectItem>
                                <SelectItem value="B-">B-</SelectItem>
                                <SelectItem value="AB+">AB+</SelectItem>
                                <SelectItem value="AB-">AB-</SelectItem>
                                <SelectItem value="O+">O+</SelectItem>
                                <SelectItem value="O-">O-</SelectItem>
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormItem>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="address"
                        render={({ field }) => (
                          <FormItem className="md:col-span-2">
                            <FormLabel>Address</FormLabel>
                            <FormControl>
                              <Textarea 
                                {...field} 
                                disabled={!isEditing} 
                                className="min-h-[100px]"
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>

                    {isEditing && (
                      <div className="flex justify-end gap-4">
                        <Button
                          type="button"
                          variant="outline"
                          onClick={() => {
                            form.reset();
                            setIsEditing(false);
                          }}
                        >
                          Cancel
                        </Button>
                        <Button type="submit" variant="orange">
                          Save Changes
                        </Button>
                      </div>
                    )}
                  </form>
                </Form>
              </div>
            </div>
          </CardHeader>
          <CardContent className="pt-6">
          </CardContent>
        </Card>
      </div>
    </div>
  )
}


export default function GeneralProfile() {
  const { user } = useAuth();

  if (user?.role === 'PATIENT') {
      return withLayout(PatientLayout)(Profile)({});
  }

  return withLayout(GeneralUserLayout)(Profile)({});


} 

