import axios, {
  AxiosInstance,
  InternalAxiosRequestConfig,
  AxiosResponse,
  AxiosError,
} from "axios";
import {
  clearStoredUsedData,
  getStoredUsedData,
  setStoredUsedData,
} from "@/utils/authToolkit";
import { toast } from "sonner";

const baseURL = "https://api.vedita.ai";
// const baseURL = "http://localhost:8000";

// Extend the AxiosRequestConfig interface
interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  _retry?: boolean;
}

export const useApi = () => {
  const api: AxiosInstance = axios.create({
    baseURL,
    timeout: 100000, // Add a timeout similar to axios.ts
  });

  api.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      const { token } = getStoredUsedData();
      if (token) {
        config.headers = config.headers || {};
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  const refreshTokenAndRetry = async (error: AxiosError) => {
    const originalRequest = error.config as CustomAxiosRequestConfig;
    if (!originalRequest) {
      return Promise.reject(error);
    }

    try {
      const { refreshToken } = getStoredUsedData();
      if (!refreshToken) {
        throw new Error("No refresh token available");
      }

      const { data } = await axios.post(`${baseURL}/auth/refresh`, {
        refreshToken,
      });
      setStoredUsedData(data.data, data.token, data.refreshToken);

      if (originalRequest.headers) {
        originalRequest.headers.Authorization = `Bearer ${data.token}`;
      }

      return api(originalRequest);
    } catch (refreshError) {
      clearStoredUsedData();
      toast.error("Session expired");
      setTimeout(() => {
        window.location.href = "/login";
      }, 500);
      return Promise.reject(refreshError);
    }
  };

  api.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError) => {
      const originalRequest = error.config as CustomAxiosRequestConfig;
      if (
        error.response?.status === 401 &&
        originalRequest &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        return refreshTokenAndRetry(error);
      }
      return Promise.reject(error);
    }
  );

  return api;
};
