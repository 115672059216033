import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import DoctorLayout from "@/layouts/Doctor/DoctorLayout"
import withLayout from "@/hoc/withLayout"
import NewPatientModal from "@/components/doctor/NewPatientModal/NewPatientModal"
import { Button } from "@/components/ui/button"
import { PatientTable } from "@/components/doctor/PatientTable/PatientTable"
import { Skeleton } from "@/components/ui/skeleton"
import { Plus } from "lucide-react"
import usePatients from "./usePatients"
import ReactPaginate from 'react-paginate';

export function Patients() {
  const {
    patients,
    formatDate,
    isPatientModalOpen,
    setIsPatientModalOpen,
    isLoading,
    currentPage,
    totalPages,
    setCurrentPage
  } = usePatients();

  const handlePageChange = ({ selected }: { selected: number }) => {
    if (selected + 1 === currentPage) return;
    setCurrentPage(selected + 1);
  };

  return (
    <div className="container mx-auto px-2 sm:px-4 pt-6">
      <Card className="overflow-hidden">
        <CardHeader className="flex flex-col sm:flex-row items-start sm:items-center justify-between space-y-2 sm:space-y-0 pb-2">
          <CardTitle>All Patients</CardTitle>
          <Dialog open={isPatientModalOpen} onOpenChange={setIsPatientModalOpen}>
            <DialogTrigger asChild>
              <Button variant="orange">
                <Plus className="h-4 w-4 mr-2" />
                Add Patient
              </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]" onPointerDownOutside={(e) => {
              e.preventDefault();
            }}>
              <DialogHeader>
                <DialogTitle>New Patient</DialogTitle>
                <DialogDescription>
                  Enter the details for the new patient below.
                </DialogDescription>
              </DialogHeader>
              <NewPatientModal />
            </DialogContent>
          </Dialog>
        </CardHeader>
        <CardContent>
          {isLoading ? (
            <div className="space-y-4">
              {[...Array(10)].map((_, index) => (
                <div key={index}>
                  {/* Mobile View Skeleton (< 640px) */}
                  <div className="block sm:hidden">
                    <Card className="p-4">
                      <div className="flex flex-col space-y-2">
                        <div className="flex justify-between items-center">
                          <Skeleton className="h-5 w-32" variant="title" />
                        </div>
                        <div className="space-y-2">
                          <Skeleton className="h-4 w-28" variant="content" />
                          <Skeleton className="h-4 w-32" variant="content" />
                        </div>
                        <Skeleton className="h-8 w-24 rounded-full" variant="content" />
                      </div>
                    </Card>
                  </div>

                  {/* Tablet View Skeleton (640px - 1024px) */}
                  <div className="hidden sm:block lg:hidden">
                    <Card className="p-4">
                      <div className="flex justify-between items-start">
                        <div className="space-y-2">
                          <div className="flex items-center gap-2">
                            <Skeleton className="h-4 w-8" variant="content" />
                            <Skeleton className="h-5 w-32" variant="title" />
                          </div>
                          <div className="flex items-center gap-4">
                            <div className="flex items-center gap-1">
                              <Skeleton className="h-4 w-28" variant="content" />
                            </div>
                            <div className="flex items-center gap-1">
                              <Skeleton className="h-4 w-32" variant="content" />
                            </div>
                          </div>
                        </div>
                        <Skeleton className="h-8 w-24 rounded-full" variant="content" />
                      </div>
                    </Card>
                  </div>

                  {/* Desktop View Skeleton (> 1024px) */}
                  <div className="hidden lg:block">
                    <div className="grid grid-cols-5 gap-4 py-4 border-b last:border-0">
                      <Skeleton className="h-4 w-8" variant="content" />
                      <Skeleton className="h-4 w-32" variant="content" />
                      <Skeleton className="h-4 w-28" variant="content" />
                      <Skeleton className="h-4 w-32" variant="content" />
                      <div className="flex justify-center">
                        <Skeleton className="h-8 w-24 rounded-full" variant="content" />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : patients && patients.length > 0 ? (
            <>
              <div className="overflow-x-auto">
                <PatientTable 
                  patients={patients} 
                  formatDate={formatDate} 
                  currentPage={currentPage}
                  pageSize={10}
                />
              </div>
              {totalPages > 1 && (
                <div className="flex justify-center mt-4">
                  <ReactPaginate
                    previousLabel="Previous"
                    nextLabel="Next"
                    pageCount={totalPages}
                    onPageChange={handlePageChange}
                    forcePage={currentPage - 1}
                    containerClassName="flex gap-2 items-center"
                    previousClassName="px-2 py-1 text-sm rounded bg-gray-100 hover:bg-gray-200"
                    nextClassName="px-2 py-1 text-sm rounded bg-gray-100 hover:bg-gray-200"
                    pageClassName="px-2 py-1 text-sm rounded bg-gray-100 hover:bg-gray-200"
                    disabledClassName="opacity-50 cursor-not-allowed"
                    activeClassName="!bg-[var(--primary)] text-white shadow-sm hover:bg-[var(--primary-hover)]"
                    breakLabel="..."
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                  />
                </div>
              )}
            </>
          ) : (
            <div className="flex h-[400px] flex-col items-center justify-center gap-4">
              <div className="text-center">
                <p className="text-lg font-medium text-foreground">No patients found</p>
                <p className="mt-1 text-muted-foreground">
                  No patients have been added yet. Click the "Add Patient" button to add a new patient.
                </p>
              </div>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
}

export default withLayout(DoctorLayout)(Patients);
