import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

export const GuestLayout = ({ children }: React.PropsWithChildren<{}>) => {
    const auth = useAuth();
    const navigate = useNavigate();
    
    useEffect(() => {
        if (auth?.user?.role=="DOCTOR") {
            navigate("/doctor/dashboard", { replace: true });
        }else if (auth?.user?.role=="PATIENT") {
            navigate("/patient/dashboard", { replace: true });
        }else if(auth?.user?.role=="ADMIN"){
            navigate("/admin/dashboard", { replace: true });
        }else if(auth?.user?.role=="GENERAL"){
            navigate("/library", { replace: true });
        }
    }, [auth?.user, navigate]);     
    
    return (
        <div>
            {/* Header content */}
            <main>
                {/* Render the main content of the page */}
                {children}
            </main>
            <footer>
                {/* Footer content */}
            </footer>
        </div>
    )
}

export default GuestLayout;