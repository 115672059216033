import { useState } from 'react';
import { HelpCircle, ChevronLeft, ListOrdered, MessageSquare, ClipboardCheck, MessagesSquare } from 'lucide-react';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs';
import { Button } from '@/components/ui/button';
import { Outline } from '@/components/book/outline';
import { useBookReader } from '@/contexts/BookReaderContext';
import { useCopilotWindow } from './useCopilotWindow';
import { BookChat } from '../chatbox/BookChat';
import { ChatMode, ModeButton } from '@/types/global';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";

const AVAILABLE_MODES: ModeButton[] = [
  { mode: 'ask', label: 'Ask Questions', icon: HelpCircle },
  { mode: 'test', label: 'Test Knowledge', icon: ClipboardCheck },
  { mode: 'discuss', label: 'Discuss & Learn', icon: MessagesSquare },
];

export function CopilotWindow() {
  const { 
    book, 
    isLoadingBook, 
    handleBackToLibrary, 
    currentPage 
  } = useBookReader();
  
  const { 
    chatHistory, 
    isLoading,
    isAITyping,
    handleAIInteraction,
    isChatOpen,
    toggleChat,
    clearChat,
    currentMode,
    setMode,
  } = useCopilotWindow();
  
  const [showClearChatDialog, setShowClearChatDialog] = useState(false);
  const [pendingModeChange, setPendingModeChange] = useState<ChatMode | null>(null);

  const handleMessage = async (message: string) => {
    await handleAIInteraction(message);
  };

  const handleModeChange = (newMode: ChatMode) => {
    if (chatHistory.length > 0) {
      setPendingModeChange(newMode);
      setShowClearChatDialog(true);
    } else {
      setMode(newMode);
    }
  };

  const handleClearChatConfirm = () => {
    clearChat();
    if (pendingModeChange) {
      setMode(pendingModeChange);
      setPendingModeChange(null);
    }
    setShowClearChatDialog(false);
  };

  const handleClearChatCancel = () => {
    setPendingModeChange(null);
    setShowClearChatDialog(false);
  };

  if (!book && !isLoadingBook) {
    return (
      <div className="h-full flex items-center justify-center">
        <div className="text-center text-[hsl(var(--book-explanation-text))]">
          <p>No book selected</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="h-full flex flex-col">
        <Tabs defaultValue="outline" className="flex-1 flex flex-col h-full">
          <div className="flex-none border-b border-[hsl(var(--book-topic-border))] bg-[hsl(var(--book-topic-bg))]">
            <div className="px-4 py-3 flex items-center gap-2">
              <Button 
                variant="ghost" 
                size="sm" 
                onClick={handleBackToLibrary}
                className="gap-2 text-[hsl(var(--book-topic-text))] hover:text-[hsl(var(--book-topic-text))] hover:bg-[hsl(var(--book-topic-border))]"
              >
                <ChevronLeft className="h-4 w-4" />
                Back
              </Button>
              <div className="h-4 w-px bg-[hsl(var(--book-topic-border))] mx-2" />
              <h2 className="text-sm font-medium truncate text-[hsl(var(--book-topic-text))]">
                {book?.title || 'Reading Assistant'}
                {currentPage && ` - Page ${currentPage}`}
              </h2>
            </div>
            <TabsList className="grid w-full grid-cols-2 bg-[hsl(var(--book-topic-bg))]">
              <TabsTrigger 
                value="outline" 
                className="flex items-center gap-2 data-[state=active]:bg-[hsl(var(--book-topic-border))] data-[state=active]:text-[hsl(var(--book-topic-text))]"
              >
                <ListOrdered className="h-4 w-4" />
                TOC
              </TabsTrigger>
              <TabsTrigger 
                value="chat" 
                className="flex items-center gap-2 data-[state=active]:bg-[hsl(var(--book-topic-border))] data-[state=active]:text-[hsl(var(--book-topic-text))]"
              >
                <MessageSquare className="h-4 w-4" />
                Chat
              </TabsTrigger>
            </TabsList>
          </div>

          <div className="flex-1 overflow-hidden bg-[hsl(var(--book-explanation-bg))]">
            <TabsContent value="outline" className="h-full data-[state=inactive]:hidden bg-[hsl(var(--book-explanation-bg))]">
              <Outline />
            </TabsContent>
            <TabsContent value="chat" className="h-full p-2 flex flex-col data-[state=inactive]:hidden bg-[hsl(var(--book-explanation-bg))]">
              <BookChat
                chatHistory={chatHistory}
                isLoading={isLoading}
                isAITyping={isAITyping}
                onSendMessage={handleMessage}
                onClose={() => {}}
                title={book?.title ? `AI Assistant` : 'Reading Assistant'}
                description={getModePlaceholder(currentMode)}
                currentMode={currentMode}
                onModeChange={handleModeChange}
                availableModes={AVAILABLE_MODES}
                availableActions={[]}
                performAction={action => {}}
              />
            </TabsContent>
          </div>
        </Tabs>
      </div>

      <Dialog open={showClearChatDialog} onOpenChange={setShowClearChatDialog}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Switch Chat Mode</DialogTitle>
            <DialogDescription>
              Changing to {pendingModeChange} mode will clear your current chat history. 
              This action cannot be undone. Would you like to proceed?
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="sm:justify-between">
            <Button
              variant="orange"
              type="button"
              onClick={handleClearChatCancel}
            >
              Cancel
            </Button>
            <Button
              variant="orange"
              type="button"
              onClick={handleClearChatConfirm}
            >
              Continue
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}

function getModePlaceholder(mode: ChatMode): string {
  switch (mode) {
    case 'ask':
      return 'Ask questions about the text you\'re reading';
    case 'test':
      return 'Test your understanding with practice questions';
    case 'discuss':
      return 'Discuss concepts and explore deeper insights';
    default:
      return 'Chat with the AI about the book';
  }
}