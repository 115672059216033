import { useAssessment } from './useAssessment';
import withLayout from '@/hoc/withLayout';
import PatientLayout from '@/layouts/Patient/PatientLayout';
import { AssessmentStart } from './components/AssessmentStart';
import { Card } from '@/components/ui/card';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Skeleton } from '@/components/ui/skeleton';

function AssessmentPage() {
  const {
    assessment,
    startTest,
    isLoading,
  } = useAssessment();

  if (isLoading) {
    return (
      <div className="container mx-auto py-8 px-4 max-w-3xl">
        <div className="relative">
          <Card className="shadow-lg h-[600px]">
            <ScrollArea className="h-full">
              <div className="p-5 space-y-6 pr-4 pb-24">
                {/* Header Section Skeleton */}
                <div className="space-y-4">
                  <div className="flex justify-center">
                    <Skeleton className="h-8 w-2/3" />
                  </div>
                  <div className="space-y-3">
                    {[...Array(4)].map((_, index) => (
                      <Skeleton key={index} className="h-4 w-full" />
                    ))}
                  </div>
                </div>

                {/* Preparation Section Skeleton */}
                <div className="space-y-4">
                  <Skeleton className="h-6 w-48" />
                  <div className="space-y-4">
                    {/* Instructions Skeleton */}
                    <div className="space-y-3">
                      {[...Array(3)].map((_, index) => (
                        <Skeleton key={index} className="h-4 w-full" />
                      ))}
                    </div>
                    
                    {/* Requirements Skeleton */}
                    <div className="space-y-3">
                      <Skeleton className="h-5 w-32" />
                      <div className="space-y-2 pl-4">
                        {[...Array(4)].map((_, index) => (
                          <Skeleton key={index} className="h-4 w-[90%]" />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ScrollArea>
          </Card>
          
          {/* Start Button Skeleton */}
          <div className="absolute bottom-0 left-0 right-0 bg-white/80 backdrop-blur-sm border-t py-3 px-4">
            <div className="flex justify-center">
              <Skeleton className="h-10 w-40" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!assessment) {
    return (
      <div className="container mx-auto py-8">
        <div className="flex items-center justify-center">
          <p className="text-lg text-gray-500">Assessment not found</p>
        </div>
      </div>
    );
  }

  return (
    <AssessmentStart
      assessment={assessment}
      onStart={startTest}
    />
  );
}

export default withLayout(PatientLayout)(AssessmentPage);
