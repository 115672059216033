import { useApi } from "@/hooks/useApi";
import { AssessmentResponse } from "@/types/assessment";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";



export const useAssessmentService = () => {
  
  const api = useApi();

  const useGetAssessment = (assessmentId: string, questionId: string = "") =>
    useQuery({
      queryKey: ["assessment", assessmentId],
      queryFn: async (): Promise<AxiosResponse<AssessmentResponse>> => {
        return api.get(`/assessments/${assessmentId}?question=${questionId}`);
        
      },
      retry:false
    }); 

  

  const submitAnswer = useMutation({
    mutationFn: async ({
      assessmentId,
      questionId,
      selectedAnswer,
    }: {
      assessmentId: string;
      questionId: string;
      selectedAnswer: string;
    }) => {
      const response = await api.post(`/assessments/${assessmentId}/submit`, {
        questionId,
        selectedAnswer,
      });
      return response.data;
    },
  });

  const finishAssessment = useMutation({
    mutationFn: async (assessmentId: string) => {
      const response = await api.post(`/assessments/${assessmentId}/complete`);
      return response.data;
    },
  });

  return {
    useGetAssessment,
    submitAnswer,
    finishAssessment,
    
  };
};
