import { User } from '@/types/global';
import { getStoredUsedData, clearStoredUsedData, setStoredUsedData } from '@/utils/authToolkit';
import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

interface AuthContextType {
  user: User | null;
  token: string | null;
  refreshToken: string | null;
  setAuthInfo: (token: string, refreshToken: string, user: User) => void;
  logout: () => void;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const { user, token, refreshToken } = getStoredUsedData();
 
    if (user && token && refreshToken) {
      setUser(user);
      setToken(token);
      setRefreshToken(refreshToken);
    }
  }, []);

  const setAuthInfo = useCallback((token: string, refreshToken: string, user: User) => {
    setUser(user);
    setToken(token);
    setRefreshToken(refreshToken);
    setStoredUsedData(user, token, refreshToken);
  }, []);

  const logout = useCallback(() => {
    setUser(null);
    setToken(null);
    setRefreshToken(null);
    clearStoredUsedData();
    navigate('/login');
  }, [navigate]);

  const contextValue = {
    user,
    token,
    refreshToken,
    setAuthInfo,
    logout,
    setUser
  };


  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
