import { TestDetails } from "@/types/doctor/globals";
import { useCallback, useState } from "react";
import { useTestService } from "@/services/Doctor/testService";

const useTests = () => {
    const [isTestModalOpen, setIsTestModalOpen] = useState(false);
    const [selectedTest, setSelectedTest] = useState<any>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;

    const { createNewTest, getTests, getTestTypes, getOrders } = useTestService(currentPage, pageSize);

    const formatDate = useCallback((date: Date | string) => {
        const d = new Date(date);
        return `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth() + 1).toString().padStart(2, '0')}/${d.getFullYear()}`;
    }, []);

    const { data: testsData, isLoading: isLoadingTests, isError: isErrorTests } = getTests;
    const { data: ordersData, isLoading: isLoadingOrders, isError: isErrorOrders } = getOrders;
    const { data: testTypesData, isLoading: isLoadingTestTypes } = getTestTypes;

    const allTests = testsData?.data ? [...testsData.data].sort((a: TestDetails, b: TestDetails) => 
        new Date(b.testDate).getTime() - new Date(a.testDate).getTime()
    ) : [];

    const orders = ordersData?.orders || [];
    const totalOrders = ordersData?.total || 0;
    const totalPagesOrders = ordersData?.pagination?.totalPages || Math.ceil(totalOrders / pageSize);

    const testTypes = testTypesData ? testTypesData.map((type: any) => ({
        value: type.id.toString(),
        label: type.name,
        pricing: type.pricing
    })) : [];

    const handlePageChange = useCallback((newPage: number) => {
        if (newPage === currentPage) return;
        setCurrentPage(newPage);
    }, [currentPage]);

    return {
        tests: allTests,
        totalTests: allTests.length,
        orders,
        formatDate,
        isTestModalOpen,
        setIsTestModalOpen,
        selectedTest,
        setSelectedTest,
        isLoading: isLoadingTests || isLoadingOrders,
        isError: isErrorTests || isErrorOrders,
        createNewTest,
        setCurrentPage: handlePageChange,
        currentPage,
        totalPagesOrders,
        testTypes,
        isLoadingTestTypes,
    };
};

export default useTests;
