import { useAdminService } from '@/services/adminService';
import { useQuery } from '@tanstack/react-query';
import { DashboardStats } from '@/types/doctor';
import { useState } from 'react';

export function useDashboard() {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const adminService = useAdminService();

  const { 
    data: stats, 
    isLoading: isStatsLoading,
    error: statsError 
  } = useQuery<DashboardStats>({
    queryKey: ['adminDashboardStats', currentPage],
    queryFn: () => adminService.getDashboardStats(currentPage, pageSize)
  });

  const handlePageChange = (newPage: number) => {
    if (newPage === currentPage) return;
    setCurrentPage(newPage);
  };

  return {
    stats,
    isLoading: isStatsLoading,
    error: statsError,
    currentPage,
    totalPages: stats?.data?.pagination?.totalPages || 1,
    setCurrentPage: handlePageChange
  };
}