import { CountCard } from "@/components/Admin/CountCard";
import { DoctorsTable } from "@/components/Admin/DoctorsTable";
import withLayout from "@/hoc/withLayout";
import AdminLayout from "@/layouts/Admin/AdminLayout";
import { useDashboard } from "./useDashboard";
import { Skeleton } from "@/components/ui/skeleton";
import { Users, UserCheck, UserX } from "lucide-react";
import { format } from "date-fns";
import { Calendar } from "lucide-react";
import ReactPaginate from 'react-paginate';

function AdminDashboard() {
  const { stats, isLoading, currentPage, totalPages, setCurrentPage } = useDashboard();

  const handlePageChange = ({ selected }: { selected: number }) => {
    if (selected + 1 === currentPage) return;
    setCurrentPage(selected + 1);
  };

  if (isLoading || !stats) {
    return (
      <div className="container mx-auto p-4 space-y-6">
        <div className="flex items-center justify-between mb-8">
          <Skeleton className="h-8 w-64" />
          <div className="flex items-center space-x-2">
            <Calendar className="h-5 w-5 text-muted-foreground" />
            <Skeleton className="h-5 w-32" />
          </div>
        </div>
        <div className="grid gap-4 md:grid-cols-3">
          <Skeleton className="h-32" />
          <Skeleton className="h-32" />
          <Skeleton className="h-32" />
        </div>
        <Skeleton className="h-[400px]" />
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4 space-y-6">
      <div className="flex items-center justify-between mb-8">
        <h1 className="text-3xl font-bold">Admin Dashboard</h1>
        <div className="flex items-center space-x-2">
          <Calendar className="h-5 w-5 text-muted-foreground" />
          <span className="text-muted-foreground">
            {format(new Date(), 'MMMM d, yyyy')}
          </span>
        </div>
      </div>
      
      <div className="grid gap-4 md:grid-cols-3">
        <CountCard
          title="Total Doctors"
          value={stats.stats.totalDoctors}
          icon={Users}
          color="bg-blue-500"
        />
        <CountCard
          title="Approved Doctors"
          value={stats.stats.totalApprovedDoctors}
          icon={UserCheck}
          color="bg-green-500"
        />
        <CountCard
          title="Pending Approval"
          value={stats.stats.totalUnapprovedDoctors}
          icon={UserX}
          color="bg-yellow-500"
        />
      </div>

      <DoctorsTable 
        doctors={stats.data.items} 
      />

      {totalPages > 1 && (
        <div className="flex justify-center mt-4">
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            pageCount={totalPages}
            onPageChange={handlePageChange}
            forcePage={currentPage - 1}
            containerClassName="flex gap-2"
            previousClassName="px-3 py-1 rounded bg-gray-100 hover:bg-gray-200"
            nextClassName="px-3 py-1 rounded bg-gray-100 hover:bg-gray-200"
            pageClassName="px-3 py-1 rounded hover:bg-gray-100"
            activeClassName="!bg-[var(--primary)] text-white shadow-sm hover:bg-[var(--primary-hover)]"
            disabledClassName="opacity-50 cursor-not-allowed"
          />
        </div>
      )}
    </div>
  )
}

export default withLayout(AdminLayout)(AdminDashboard);