import { useApi } from "@/hooks/useApi";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

export interface Doctor {
  id: string;
  name: string;
  specialization: string;
}

export interface Test {
  _id: string;
  name: string;
  prompt: string;
  description: string;
  image: string;
  status: string;
  preparation: {
    estimatedTime: {
      hours: number;
      minutes: number;
    };
    instructions: string;
    requirements: string[];
  };
  pricing: {
    price: number;
    currency: string;
    discountPrice: number;
  };
  assessment: {
    _id: string;
    userId: string;
    orderId: string;
    startDate: string;
    status: string;
    answers: any[];
    paymentStatus: string;
    results: any[];
    progress: {
      totalQuestions: number;
      answeredQuestions: number;
      progressPercentage: number;
      remainingQuestions: number;
      status: string;
      currentQuestionNumber: number;
    };
  };
  reportType: string;
}

export interface Order {
  id: string;
  doctorId: Doctor;
  status: string;
  tests: Test[];
  totalAmount: number;
  currency: string;
  patientGender: string;
}


export interface OrderResponse {
  order: Order;
}

export const useOrderService = () => {
  const api = useApi();

  const useGetOrderDetails = (orderId: string) => 
    useQuery({
      queryKey: ["order", orderId],
      queryFn: async (): Promise<AxiosResponse<OrderResponse>> => {
        return api.get(`/assessments/order/${orderId}`);
      },
    });

    const makePayment = async (testId: string) => {

      const response = await api.get(`/assessments/payment/order-status/${testId}`);
      return response.data;
    };
  
    const verifyPayment = async ({ 
      orderId, 
      paymentData 
    }: { 
      orderId: string, 
      paymentData: {
        razorpay_payment_id: string;
        razorpay_order_id: string;
        razorpay_signature: string;
      }
    }) => {
      const response = await api.post(`/assessments/payment/verify-order/${orderId}`, paymentData);
      return response.data;
    };
  
    const verifyPaymentMutation = useMutation({
      mutationFn: verifyPayment,
    });
  
    const makePaymentMutation = useMutation({
      mutationFn: makePayment,
    });

  const startTest = useMutation({
    mutationFn: async ({ orderId, testId }: { orderId: string; testId: string }) => {
      const response = await api.post(`/assessments/start/${testId}`, { orderId });
      return response;
    },
  });

  const resumeTest = useMutation({
    mutationFn: async ({ assessmentId }: { assessmentId: string }) => {
      const response = await api.post(`/assessments/resume/${assessmentId}`);
      return response;
    },
  });

  return {
    useGetOrderDetails,
    makePayment: makePaymentMutation,
    verifyPayment: verifyPaymentMutation,
    startTest,
    resumeTest
  };
};
