import { useApi } from '@/hooks/useApi';
import { useMutation,useQueryClient } from '@tanstack/react-query';

const useDocumentService = () => {
  const api = useApi();
  const queryClient = useQueryClient();

  const uploadDocument = useMutation({
    mutationFn: async (file: File) => {
      const formData = new FormData();
      formData.append('file', file);
      return api.post('/cloud/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['patientProfile'] });
    },
  });

  return {
    uploadDocument,
  };
};

export { useDocumentService };