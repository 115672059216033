import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Input } from "@/components/ui/input";
import { Symptom } from "@/types/doctor/globals";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";


interface Props {
  symptoms: Symptom[];
  onUpdate: (symptoms: Symptom[]) => void;
}

export const SymptomPane: React.FC<Props> = ({ symptoms, onUpdate }) => {

  return (
    <Card>
      <CardHeader>
        <CardTitle>Symptoms</CardTitle>
      </CardHeader>
      <CardContent>
        {symptoms.length > 0 && (
          <div className="overflow-x-auto">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="min-w-[150px]">Symptom</TableHead>
                  <TableHead className="min-w-[120px]">Since When</TableHead>
                  <TableHead className="min-w-[100px]">Is Episodic</TableHead>
                  <TableHead className="min-w-[100px]">Intensity</TableHead>
                  <TableHead className="min-w-[100px]">Prognosis</TableHead>
                  <TableHead className="min-w-[120px]">Episodic Type</TableHead>
                  <TableHead className="min-w-[120px]">Episodic Description</TableHead>
                  <TableHead className="min-w-[120px]">Onset</TableHead>
                  <TableHead className="min-w-[150px]">Trigger Events</TableHead>
                  <TableHead className="min-w-[150px]">Trigger Event Detail</TableHead>
                  <TableHead className="min-w-[150px]">Identifying Features</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {symptoms.map((symptom, index) => (
                  <TableRow key={index}>
                    <TableCell className="min-w-[150px]">{symptom.name || '-'}</TableCell>
                    <TableCell className="min-w-[120px]">{symptom.sinceWhen || '-'}</TableCell>
                    <TableCell className="min-w-[100px]">{symptom.isEpisodic || 'No'}</TableCell>
                    <TableCell className="min-w-[100px]">{symptom.intensity || 'Low'}</TableCell>
                    <TableCell className="min-w-[100px]">{symptom.prognosis || 'Acute'}</TableCell>
                    <TableCell className="min-w-[120px]">{symptom.episodicType || '-'}</TableCell>
                    <TableCell className="min-w-[120px]">{symptom.episodicDescription || '-'}</TableCell>
                    <TableCell className="min-w-[120px]">{symptom.onset || '-'}</TableCell>
                    <TableCell className="min-w-[150px]">{symptom.triggerEvents || '-'}</TableCell>
                    <TableCell className="min-w-[150px]">{symptom.triggerEventDetail || '-'}</TableCell>
                    <TableCell className="min-w-[150px]">{symptom.identifyingFeatures || '-'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
        {/* <Button
          onClick={handleAdd}
          variant="outline"
          className="mt-4 w-full"
        >
          <Plus className="h-4 w-4 mr-2" />
          Add Symptom
        </Button> */}
      </CardContent>
    </Card>
  );
};
