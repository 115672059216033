import { AxiosResponse } from 'axios';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useApi } from '@/hooks/useApi';
import { ProfileFormData } from '@/pages/Doctor/Profile/useProfile';

export const useDoctorService = () => {
  const api = useApi();
  const queryClient = useQueryClient();

  // Get doctor profile
  const getDoctorProfile = useQuery({
    queryKey: ['doctor-profile'],
    queryFn: async (): Promise<AxiosResponse<ProfileFormData>> => {
      return api.get('/profile');
    },
  });

  // Update doctor profile
  const updateDoctorProfile = useMutation({
    mutationFn: async (profileData: Partial<ProfileFormData>): Promise<AxiosResponse<ProfileFormData>> => {
      return api.put('/profile', profileData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['doctor-profile'] });
    },
  });



  return {
    getDoctorProfile,
    updateDoctorProfile,
   
  };
};
