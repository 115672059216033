import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Separator } from "@/components/ui/separator";

interface PatientDetails {
  firstName: string;
  lastName: string;
  age: string;
  gender: string;
  weight: string;
  height: string;
}

interface Props {
  data: PatientDetails;
}

export const PatientInfoForm: React.FC<Props> = ({ data }) => {
  

  return (
    <Card className="w-full">
      <CardHeader>
        <div className="flex items-center space-x-4">
          <div>
            <CardTitle className="text-2xl font-bold">Patient Information</CardTitle>
            <p className="text-sm text-muted-foreground">Enter the patient's details below or ask Vedita to fill it for you</p>
          </div>
        </div>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="space-y-2">
            <Label htmlFor="firstName">First Name</Label>
            <Input
              id="firstName"
              value={data.firstName || ''}
              readOnly
              placeholder="Enter first name"
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="lastName">Last Name</Label>
            <Input
              id="lastName"
              value={data.lastName || ''}
              readOnly
              placeholder="Enter last name"
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="age">Age</Label>
            <Input
              id="age"
              type="text"
              value={data.age ?? ''}
              readOnly
              placeholder="Enter age"
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="gender">Gender</Label>
            <Select
              value={`${data.gender}`.trim().toLocaleLowerCase()}
              disabled
            >
              <SelectTrigger id="gender">
                <SelectValue placeholder="Select gender" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="male">Male</SelectItem>
                <SelectItem value="female">Female</SelectItem>
                <SelectItem value="other">Other</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
        <Separator className="my-6" />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="space-y-2">
            <Label htmlFor="weight">Weight (kg)</Label>
            <Input
              id="weight"
              type="text"
              value={data.weight ?? ''}
              readOnly
              placeholder="Enter weight"
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="height">Height (cm)</Label>
            <Input
              id="height"
              type="text"
              value={data.height ?? ''}
              readOnly
              placeholder="Enter height"
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
