import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { FamilyHistoryItem } from "@/types/doctor/globals";

interface Props {
  familyHistory: FamilyHistoryItem[];
  onUpdate: (familyHistory: FamilyHistoryItem[]) => void;
}

export const FamilyHistoryPane: React.FC<Props> = ({ familyHistory, onUpdate }) => {
  
  return (
    <Card>
      <CardHeader>
        <CardTitle>Family History</CardTitle>
      </CardHeader>
      <CardContent>
        {familyHistory.length > 0 && (
          <div className="overflow-x-auto">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="min-w-[120px]">Relation</TableHead>
                  <TableHead className="min-w-[200px]">Condition</TableHead>
                  <TableHead className="min-w-[100px]">Prognosis</TableHead>
                  <TableHead className="min-w-[100px]">Intensity</TableHead>
                  <TableHead className="min-w-[100px]">Since When</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {familyHistory.map((entry, index) => (
                  <TableRow key={index}>
                    <TableCell className="min-w-[120px]">{entry.relation}</TableCell>
                    <TableCell className="min-w-[200px]">{entry.condition}</TableCell>
                    <TableCell className="min-w-[100px]">{entry.prognosis}</TableCell>
                    <TableCell className="min-w-[100px]">{entry.intensity}</TableCell>
                    <TableCell className="min-w-[100px]">{entry.sinceWhen}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
      </CardContent>
    </Card>
  );
};
