import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { MedicalHistoryItem } from "@/types/doctor/globals";

interface Props {
  history: MedicalHistoryItem[];
  onUpdate: (history: MedicalHistoryItem[]) => void;
}

export const HistoryPane: React.FC<Props> = ({ history, onUpdate }) => {
 

  return (
    <Card>
      <CardHeader>
        <CardTitle>Medical History</CardTitle>
      </CardHeader>
      <CardContent>
        {history.length > 0 && (
          <div className="overflow-x-auto">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="min-w-[150px]">Relation</TableHead>
                  <TableHead className="min-w-[150px]">Condition</TableHead>
                  <TableHead className="min-w-[100px]">Prognosis</TableHead>
                  <TableHead className="min-w-[100px]">Intensity</TableHead>
                  <TableHead className="min-w-[100px]">Since When</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {history.map((condition, index) => (
                  <TableRow key={index}>
                    <TableCell className="min-w-[150px]">
                      {condition.relation}
                    </TableCell>
                    <TableCell className="min-w-[150px]">
                      {condition.condition}
                    </TableCell>
                    <TableCell className="min-w-[100px]">
                      {condition.prognosis}
                    </TableCell>
                    <TableCell className="min-w-[100px]">
                      {condition.intensity}
                    </TableCell>
                    <TableCell className="min-w-[100px]">
                      {condition.sinceWhen}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
      </CardContent>
    </Card>
  );
};
