import { Card, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Assessment } from "@/types/assessment";

interface AssessmentStartProps {
  assessment: Assessment;
  onStart: () => void;
}

export function AssessmentStart({ assessment, onStart }: AssessmentStartProps) {
  return (
    <div className="container mx-auto py-8 px-4 max-w-3xl">
      <div className="relative">
        <Card className="shadow-lg h-full font-['DM_Sans']">
         
            <div className="p-5 space-y-6">
              {/* Header Section */}
              <div>
                <CardTitle className="text-2xl font-bold text-[hsl(var(--foreground))] text-center mb-4">
                  {assessment.testId.name}
                </CardTitle>
                <div 
                  className="prose prose-sm max-w-none text-[hsl(var(--muted-foreground))]"
                  dangerouslySetInnerHTML={{
                    __html: assessment.testId.description || "",
                  }}
                />
              </div>

              {/* Preparation Section */}
              <div className="space-y-4">
                <h3 
                  className="text-lg font-semibold text-[hsl(var(--foreground))]"
                  dangerouslySetInnerHTML={{ __html: "Preparation Instructions" }}
                />
                <div className="space-y-4">
                  <p 
                    className="text-[hsl(var(--muted-foreground))]" 
                    dangerouslySetInnerHTML={{ 
                      __html: assessment.testId.preparation.instructions 
                    }} 
                  />
                  <div>
                    <h4 className="font-medium mb-2 text-[hsl(var(--foreground))]">Requirements:</h4>
                    <ul className="list-disc list-inside space-y-1.5 text-[hsl(var(--muted-foreground))]">
                      {assessment.testId.preparation.requirements.map((req: string, index: number) => (
                        <li 
                          key={index} 
                          className="pl-2" 
                          dangerouslySetInnerHTML={{ __html: req }}
                        />
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="flex justify-center mt-8">
                <Button
                variant="orange"
                onClick={onStart}
              >
                Start Assessment
              </Button>
                </div>
               
              </div>
            </div>
        
        </Card>
        
       
      </div>
    </div>
  );
}
