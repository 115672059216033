import { useChatService } from "@/services/useChatService";
import { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ChatMode, BookAIResponse, TextPayload, BookChatRequest } from "@/types/global";
import { AIInteraction } from "@/types/doctor/globals";
import { useBookReader } from "@/contexts/BookReaderContext";
import { hexToText } from "@/utils/toolkit";

export const useCopilotWindow = () => {
  const { bookId } = useParams<{ bookId: string }>();
  const queryClient = useQueryClient();
  const chatService = useChatService();
  const { book, currentPage, pageContent, language } = useBookReader();
  const [chatHistory, setChatHistory] = useState<AIInteraction[]>([]);
  const [isAITyping, setIsAITyping] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [sessionId, setSessionId] = useState<string>('');
  const [currentMode, setCurrentMode] = useState<ChatMode>('ask');

  // Reset chat and mode when page changes
  useEffect(() => {
    setChatHistory([]);
    setSessionId('');
    setCurrentMode('ask');
  }, [currentPage]);

  const formatShlokaContent = () => {
    if (!pageContent?.shlokas?.length) return '';

    const formattedContent = pageContent.shlokas.map(shloka => {
      return `Shaloka ${shloka.numberFrom}${shloka.numberTo ? `-${shloka.numberTo}` : ''}
Translation: ${hexToText(shloka.excryptedText2)}
Explanation: ${hexToText(shloka.excryptedText3)}
`;
    }).join('\n\n');

    return formattedContent;
  };

  const aiInteractionMutation = useMutation<BookAIResponse, Error, { message: string; mode: ChatMode; showUserMessage?: boolean }>({
    mutationFn: async ({ message, mode, showUserMessage = true }) => {
      let userMessages: TextPayload[] = [
        { type: 'text', text: message }
      ];

      // For test mode, if it's the first message (no sessionId), add shloka content as first message
      if (!sessionId) {
        switch (mode) {
          case 'ask':
            {
              const shlokaContent = formatShlokaContent();
              userMessages = [
                { type: 'text', text: `***** Shloka ${shlokaContent} ****** end shaloka` },
                { type: 'text', text: message }
              ];
              break;
            }
          case 'test':
            {
              const shlokaContent = formatShlokaContent();
              userMessages = [
                { type: 'text', text: `***** book text **** ${shlokaContent} ****** text` },
                { type: 'text', text: message }
              ];
              break;
            }
          case 'discuss':
            {
              const shlokaContent = formatShlokaContent();
              userMessages = [
                { type: 'text', text: `***** shaloka *** ${shlokaContent} ****** end shaloka` },
                { type: 'text', text: message }
              ];
              break;
            }
            break;
          default:
            break;
        }
        
      }

      const request: BookChatRequest = {
        userMessage: userMessages,
        mode: mode,
        sessionId: sessionId,
        variables: {
          'book-title': book?.title || 'Ayurveda Book',
          'language-code': language || 'en-US',
        }
      };

      return chatService.processBookChat(request);
    },
    onMutate: ({ message, showUserMessage = true }) => {
      setIsAITyping(true);
      if (showUserMessage) {
        setChatHistory((prev) => [
          ...prev,
          { role: 'user', content: message },
        ]);
      }
    },
    onSuccess: (result) => {
      if (result.sessionId) {
        setSessionId(result.sessionId);
      }

      setChatHistory((prev) => [
        ...prev,
        { role: 'ai', content: result.aiResponse },
      ]);

      if (bookId) {
        queryClient.invalidateQueries({ queryKey: ['book', bookId] });
      }
    },
    onError: (error) => {
      console.error("Error processing AI interaction:", error);
      toast.error("Failed to process your message. Please try again.");
      setChatHistory((prev) => [
        ...prev,
        { role: 'ai', content: "Sorry, I encountered an error. Please try again." },
      ]);
    },
    onSettled: () => {
      setIsAITyping(false);
    },
  });

  const handleAIInteraction = useCallback(
    (message: string) => {
      aiInteractionMutation.mutate({ message, mode: currentMode });
    },
    [aiInteractionMutation, currentMode]
  );

  const setMode = useCallback((mode: ChatMode) => {
    setCurrentMode(mode);
    switch (mode) {
      case 'ask':
        //aiInteractionMutation.mutate({ message: '', mode: 'ask', showUserMessage: false });
        break;
      case 'test':
        aiInteractionMutation.mutate({ message: '', mode: 'test', showUserMessage: false });
        break;
      case 'discuss':
        aiInteractionMutation.mutate({ message: 'Lets Discuss', mode: 'discuss', showUserMessage: false });
        break;
      default:
        //aiInteractionMutation.mutate({ message: '', mode: 'test', showUserMessage: false });
    }
  }, [aiInteractionMutation]);

  const toggleChat = () => {
    setIsChatOpen(prevState => !prevState);
  };

  const clearChat = useCallback(() => {
    setChatHistory([]);
    setIsAITyping(false);
    setSessionId('');

    if (aiInteractionMutation.isPending) {
      aiInteractionMutation.reset();
    }

    toast.info("Chat cleared. Starting new conversation.");
  }, [aiInteractionMutation]);

  return {
    chatHistory,
    isLoading: aiInteractionMutation.isPending,
    isAITyping,
    handleAIInteraction,
    isChatOpen,
    toggleChat,
    sessionId,
    clearChat,
    currentMode,
    setMode,
  };
};
