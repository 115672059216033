import React, { useEffect, useState } from 'react'
import { Sidebar } from './Sidebar';
import { AppBar } from './AppBar';
import { SidebarProvider, useSidebar } from '@/contexts/SidebarContext';
import { useAuth } from '@/contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { LogOut } from 'lucide-react';
import { LogoutModal } from '@/components/LogoutModal';

const ProfileOnlyLayout: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const { logout } = useAuth();
    const [isLogoutModalOpen, setLogoutModalOpen] = useState(false);

    const handleLogout = () => {
        setLogoutModalOpen(true);
    };

    const handleConfirmLogout = () => {
        logout();
        setLogoutModalOpen(false);
    };

    const handleCloseModal = () => {
        setLogoutModalOpen(false);
    };

    return (
        <div className="container mx-auto px-4 md:px-6 py-8">
            <div className="max-w-6xl mx-auto bg-white rounded-lg shadow-md">
                <div className="p-6 border-b border-gray-200">
                    <div className="flex justify-between items-center">
                        <div>
                            <h1 className="text-2xl font-bold text-red-600">Account Pending Approval</h1>
                            <p className="text-gray-600 mt-2">Your account is currently pending approval.</p>
                        </div>
                        <Button
                            onClick={handleLogout}
                            variant="ghost"
                            size="sm"
                            className="text-red-600 hover:text-red-700 hover:bg-red-50"
                        >
                            <LogOut className="h-5 w-5 mr-2" />
                            Logout
                        </Button>
                    </div>
                </div>
                <div className="p-6">
                    {children}
                </div>
            </div>
            <LogoutModal
                isOpen={isLogoutModalOpen}
                onConfirm={handleConfirmLogout}
                onClose={handleCloseModal}
            />
        </div>
    );
};

const DoctorLayoutContent: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const { isSidebarOpen, toggleSidebar } = useSidebar();
    const { logout, user } = useAuth();
    // const navigate = useNavigate();

    useEffect(() => {
        if (user && user?.role!="DOCTOR") {
            logout();
        }
    }, [user, logout]);

    // If we're on a non-profile page and the doctor is not approved, redirect to profile
    // useEffect(() => {
    //     if (user && !user.isApproved && !window.location.pathname.includes('/doctor/profile')) {
    //         navigate('/doctor/profile');
    //     }
    // }, [user, navigate]);

    // // Show profile-only layout for unapproved doctors
    // if (user && !user.isApproved) {
    //     return <ProfileOnlyLayout>{children}</ProfileOnlyLayout>;
    // }

    return (
        <div className="flex flex-col h-screen ">
            <div className="fixed top-0 left-0 right-0 z-10">
                <AppBar toggleSidebar={toggleSidebar} />
            </div>
            <div className="flex flex-1 relative" style={{ marginTop: '3.5rem' }}>
                <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                <main className="flex-1 overflow-y-auto w-full">
                    {children}
                </main>
            </div>
        </div>
    );
};

export const DoctorLayout: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    return (
        <SidebarProvider>
            <DoctorLayoutContent>{children}</DoctorLayoutContent>
        </SidebarProvider>
    );
};

export default DoctorLayout;
