import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { ScrollArea } from '@radix-ui/react-scroll-area';
import React from 'react';

const CookiePolicy: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <Card className="w-full h-full max-w-4xl mx-auto">
        <CardHeader>
          <CardTitle className="text-3xl font-bold mb-6">Cookie Policy</CardTitle>
        </CardHeader>
        <CardContent>
          <ScrollArea className="h-full w-full pr-4">
            <div className="space-y-6">
              <section>
                <p className="text-sm text-muted-foreground">
                  Welcome to the cookie policy of Desi LLM PVT. LTD., a Limited Liability Company (doing business as: Vedita.ai Platform or Company) having registration credentials as U86901KA2024PTC186441 in Belagavi, Karnataka, India (referred to as "we", "us", or "our").
                </p>
              </section>

              <section>
                <p className="text-sm text-muted-foreground">
                  This cookie policy explains how we use cookies and other similar technologies on our website. By using our website, you consent to the use of cookies and other similar technologies as described in this policy. We may update this policy from time to time to reflect changes in our practices and services, so we recommend that you check this page periodically for the latest information.
                </p>
              </section>

              <section>
                <p className="text-sm text-muted-foreground">
                  We respect your privacy and we are committed to ensuring that you are adequately informed on how you can manage your cookies.
                </p>
              </section>

              <section>
                <h3 className="text-lg font-semibold mb-2">What are Cookies?</h3>
                <p className="text-sm text-muted-foreground">
                  Cookies are small data files. When you visit a website, the website sends the cookie to your computer. Your computer stores it in a file located inside your web browser.
                </p>
              </section>

              <section>
                <p className="text-sm text-muted-foreground">
                  Cookies do not transfer viruses or malware to your computer, because the data in a cookie does not change when it travels back and forth, it has no way to affect how your computer runs. Instead, they act more like logs (i.e. they record user activity and remember tasteful information), and get updated every time you visit a website.
                </p>
              </section>

              <section>
                <p className="text-sm text-muted-foreground">
                  We may obtain information about you by accessing cookies sent by our website. Different types of cookies keep track of different activities. For example, session cookies are used only when a person is actively navigating a website. Once you leave the website, the session cookie disappears. For a more detailed list of cookies we use, please see the relevant section below.
                </p>
              </section>

              <section>
                <h3 className="text-lg font-semibold mb-2">Why are Cookies useful?</h3>
                <div className="space-y-4">
                  <p className="text-sm text-muted-foreground">
                    We use functional cookies to analyze how visitors and clients use our website, as well as track and improve our website's performance and function. This allows us to provide a high-quality customer experience by quickly identifying and fixing any issues that may arise. For example, we might use cookies to keep track of which website pages are most popular and which method of linking between website pages is most effective.
                  </p>
                  
                  <p className="text-sm text-muted-foreground">
                    Another use of cookies is to store your login sessions, meaning that when you log in to your account to deposit funds, a "session cookie" is set so that the website remembers that you have already logged in. If the website did not set this cookie, you will be asked for your login details on each new page as you progress through the funding process.
                  </p>
                  
                  <p className="text-sm text-muted-foreground">
                    In addition, functional cookies, for example, are used to allow us to remember your preferences and identify you as a user, ensure your information is secure and operate more reliably and efficiently. For example, cookies save you the trouble of typing in your username every time you access our trading platform, and recall your preferences.
                  </p>
                  
                  <p className="text-sm text-muted-foreground">
                    Here are some of the functions our cookies provide us with:
                  </p>
                  
                  <ul className="list-disc pl-6 text-sm text-muted-foreground">
                    <li>Verifying your identity and detecting the country you are currently visiting from;</li>
                    <li>Checking browser type and device;</li>
                    <li>Tracking which site the user was referred from.</li>
                  </ul>
                </div>
              </section>

              <section>
                <h3 className="text-lg font-semibold mb-2">Types of Cookies we use</h3>
                <div className="space-y-4">
                  <p className="text-sm text-muted-foreground">
                    1. Functional cookies: These cookies are essential for the running of our website. Without these cookies our website would not function properly. These are saved temporarily as login information and expire once the browser is closed.
                  </p>
                  
                  <p className="text-sm text-muted-foreground">
                    2. Analytical cookies: The information provided by analytical cookies allows us to analyze patterns of visitor and client behavior and we use that information to enhance the overall experience or identify areas of the website which may require maintenance. The information is anonymous (i.e. it cannot be used to identify you and does not contain personal information such as your name and email address), and it is only used for statistical purposes. Behavioral cookies are similar to analytical and remember that you have visited the website and use that information to provide you with content which is tailored to your interests.
                  </p>
                </div>
              </section>

              <section>
                <h3 className="text-lg font-semibold mb-2">Manage Cookies</h3>
                <p className="text-sm text-muted-foreground">
                  You can delete cookies any time you want by using the settings in your web browser. You can also choose to disable cookies from your web browser, but this would mean that our website and other websites that you access may not function properly. If you do this, a potential result is that you may not be able to sign in. Further information on deleting or controlling cookies can be found at www.aboutcookies.org.
                </p>
              </section>
            </div>
          </ScrollArea>
        </CardContent>
      </Card>
    </div>
  );
};

export default CookiePolicy;