import { useCallback, useState } from "react";
import { usePatientService } from "@/services/Patient/patientService";

const usePatients = () => {
    const [isPatientModalOpen, setIsPatientModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;

    const { getPatients } = usePatientService(currentPage, pageSize);

    const formatDate = useCallback((date: Date | string) => {
        const d = new Date(date);
        return `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth() + 1).toString().padStart(2, '0')}/${d.getFullYear()}`;
    }, []);

    const { data: patientsData, isLoading, isError } = getPatients;

    const patients = patientsData?.data?.items || [];
    const totalPages = patientsData?.data?.pagination?.totalPages || 1;

    const handlePageChange = useCallback((newPage: number) => {
        if (newPage === currentPage) return;
        setCurrentPage(newPage);
    }, [currentPage]);

    return {
        patients,
        isLoading,
        isError,
        formatDate,
        isPatientModalOpen,
        setIsPatientModalOpen,
        currentPage,
        totalPages,
        setCurrentPage: handlePageChange,
    };
};

export default usePatients;
