import { useQuery } from '@tanstack/react-query';
import { useApi } from '@/hooks/useApi';

interface DashboardStats {
  totalPatients: number;
  activePatients: number;
  totalTestsAssigned: number;
  testsInProgress: number;
  completedTests: number;
}

interface RecentOrder {
  id: string;
  testNames: string;
  patientName: string;
  orderedAt: string;
  doctorName: string;
  totalTests: number;
  completedTests: number;
}

interface RecentPatientVisit {
  id: string;
  firstName: string;
  lastName: string;
  visitDate: string;
}

interface DashboardData {
  stats: DashboardStats;
  recentOrders: RecentOrder[];
  recentPatientVisits: RecentPatientVisit[];
}

const defaultStats: DashboardStats = {
  totalPatients: 0,
  activePatients: 0,
  totalTestsAssigned: 0,
  testsInProgress: 0,
  completedTests: 0,
};

export const useDashboard = () => {
  const api = useApi();

  const { data, isLoading, isError } = useQuery({
    queryKey: ['doctor-dashboard'],
    queryFn: async () => {
      const response = await api.get<DashboardData>('/dashboard');
      return response.data;
    },
  });

  return {
    stats: data?.stats || defaultStats,
    recentOrders: data?.recentOrders || [],
    recentPatientVisits: data?.recentPatientVisits || [],
    isLoading,
    isError,
  };
};

export type { DashboardStats, RecentOrder, RecentPatientVisit };
