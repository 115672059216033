import { useApi } from "@/hooks/useApi";
import { useQuery, useMutation } from "@tanstack/react-query";

export const useTestService = () => {
  const api = useApi();

  const useOrdersQuery = (page: number, pageSize: number) => 
    useQuery({
      queryKey: ["patient-orders", page, pageSize],
      queryFn: async () => {
        const response = await api.get(`/tests/ordered-tests?page=${page}&limit=${pageSize}`);
        const { items, total, pagination } = response.data;
        
        return {
          orders: items || [],
          total: total || 0,
          pagination: pagination || {
            currentPage: page,
            totalPages: Math.ceil((total || 0) / Number(pageSize))
          }
        };
      },
    });

  const makePayment = async (testId: string) => {

    const response = await api.get(`/assessments/payment/order-status/${testId}`);
    return response.data;
  };

  const verifyPayment = async ({ 
    orderId, 
    paymentData 
  }: { 
    orderId: string, 
    paymentData: {
      razorpay_payment_id: string;
      razorpay_order_id: string;
      razorpay_signature: string;
    }
  }) => {
    const response = await api.post(`/assessments/payment/verify-order/${orderId}`, paymentData);
    return response.data;
  };

  const verifyPaymentMutation = useMutation({
    mutationFn: verifyPayment,
  });

  const makePaymentMutation = useMutation({
    mutationFn: makePayment,
  });


  
  const getTestTypes = useQuery({
    queryKey: ["testTypes"],
    queryFn: async () => {
      const response = await api.get("/tests");
      return response.data;
    },
  });

  const createNewTest = useMutation({
    mutationFn: async (testData: any) => {
      const response = await api.post("/tests/order_forself", testData);
      return response.data;
    },
    onSuccess: () => {
     console.log('test created'); 
    },
  });


  return {
    getOrders: useOrdersQuery,
    makePayment: makePaymentMutation,
    verifyPayment: verifyPaymentMutation,
    getTestTypes,
    createNewTest,
  };
};
