import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import React from "react";

const RefundAndCancellation: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <Card className="w-full max-w-4xl mx-auto">
        <CardHeader>
          <CardTitle className="text-3xl font-bold mb-6">Refund and Cancellation Policy</CardTitle>
        </CardHeader>
        <CardContent>
          <ScrollArea className="h-full w-full pr-4">
            <div className="space-y-6">
              <div className="text-sm text-muted-foreground font-montserrat">
                <p className="font-semibold mb-8">Last updated: June 01, 2024</p>

                <section className="mb-8">
                  <h3 className="text-lg font-semibold mb-4">1. Cancellation Policy</h3>
                  <div className="space-y-4">
                    <p>At Vedita.ai, we understand that circumstances may arise requiring you to cancel your order. Please review our cancellation policy guidelines:</p>
                    <ul className="list-disc list-inside space-y-2 ml-4">
                      <li>Cancellation requests are accepted within 24 hours of payment.</li>
                      <li>To cancel your order, please contact our customer support team.</li>
                      <li>After the 24-hour window has passed, cancellations will not be accepted.</li>
                      <li>For subscription-based services, cancellation requests must be submitted at least 24 hours before the next billing cycle.</li>
                    </ul>
                  </div>
                </section>

                <section className="mb-8">
                  <h3 className="text-lg font-semibold mb-4">2. Refund Policy</h3>
                  <div className="space-y-4">
                    <p>Our refund policy is designed to be fair and transparent. Here are the key points:</p>
                    <ul className="list-disc list-inside space-y-2 ml-4">
                      <li>Refunds will be processed for approved cancellation requests only.</li>
                      <li>The refund process typically takes 1-4 working days to complete.</li>
                      <li>The refund will be credited to the original payment method used for the transaction.</li>
                      <li>You will receive a confirmation email once the refund has been processed.</li>
                      <li>Any applicable taxes and fees may be deducted from the refund amount as per local regulations.</li>
                    </ul>
                  </div>
                </section>

                <section className="mb-8">
                  <h3 className="text-lg font-semibold mb-4">3. Contact Information</h3>
                  <div className="space-y-4">
                    <p>For any queries regarding cancellations or refunds, please contact our support team:</p>
                    <p className="font-medium">Email: support@vedita.ai</p>
                    <p>Our customer support team is available Monday through Friday, 9:00 AM to 6:00 PM IST.</p>
                  </div>
                </section>

                <div className="text-center space-y-4 my-8">
                  <p className="font-semibold">
                    PLEASE NOTE: THE ABOVE POLICIES ARE SUBJECT TO CHANGE WITHOUT NOTICE.
                  </p>
                  <p className="font-semibold">
                    BY USING OUR SERVICES, YOU ACKNOWLEDGE AND AGREE TO THESE REFUND AND CANCELLATION POLICIES.
                  </p>
                </div>
              </div>
            </div>
          </ScrollArea>
        </CardContent>
      </Card>
    </div>
  );
};

export default RefundAndCancellation;