import { useState } from "react";
import { useAuth } from "@/contexts/AuthContext";

import {UseNewTestFormProps} from "@/types/doctor/testTypes";

export const useNewTestForm = ({ onSubmit, testTypes }: UseNewTestFormProps) => {

  const {  user } = useAuth();
  const [patientName, setPatientName] = useState("");
  const [patientMobile, setPatientMobile] = useState("");
  const [phoneCountry, setPhoneCountry] = useState(`${user?.countryCode}`);
  const [patientEmail, setPatientEmail] = useState("");
  const [selectedTests, setSelectedTests] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [patientGender, setPatientGender] = useState<'MALE' | 'FEMALE' | 'OTHER'>('MALE');
  const [errors, setErrors] = useState<{
    patientName: string;
    patientMobile: string;
    patientEmail: string;
    selectedTests: string;
    patientGender: string;
  }>({
    patientName: "",
    patientMobile: "",
    patientEmail: "",
    selectedTests: "",
    patientGender: "",
  });

  const handleSelect = (value: string) => {
    setSelectedTests((current) => {
      const newSelection = current.includes(value)
        ? current.filter((item) => item !== value)
        : [...current, value];
      return newSelection;
    });
  };

  const handleRemoveTest = (valueToRemove: string) => {
    setSelectedTests((current) => 
      current.filter((value) => value !== valueToRemove)
    );
  };

  const filteredTests = testTypes.filter((test) =>
    test.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const validateForm = () => {
    const newErrors = {
      patientName: "",
      patientMobile: "",
      patientEmail: "",
      selectedTests: "",
      patientGender: "",
    };

    if (!patientName.trim()) {
      newErrors.patientName = "Patient name is required";
    }

    if (!patientMobile.trim()) {
      newErrors.patientMobile = "Mobile number is required";
    } else {
      const mobileNumberOnly = patientMobile.replace(phoneCountry, '').replace(/\D/g, '');
      if (mobileNumberOnly.length < 10) {
        newErrors.patientMobile = "Invalid mobile number";
      }
    }

    if (patientEmail.trim() && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(patientEmail.trim())) {
      newErrors.patientEmail = "Invalid email address";
    }

    if (selectedTests.length === 0) {
      newErrors.selectedTests = "At least one test must be selected";
    }

    if (!patientGender) {
      newErrors.patientGender = "Gender is required";
    }

    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      const allDigits = patientMobile.replace(/\D/g, '');
      const countryDigits = phoneCountry.replace(/\D/g, '');
      const mobileNumberOnly = allDigits.startsWith(countryDigits) 
        ? allDigits.slice(countryDigits.length) 
        : allDigits;

      onSubmit({
        patientName,
        patientMobile: mobileNumberOnly,
        countryCode: phoneCountry,
        patientEmail,
        selectedTests,
        patientGender,
      });
    }
  };

  return {
    patientName,
    setPatientName,
    patientMobile,
    setPatientMobile,
    phoneCountry,
    setPhoneCountry,
    patientEmail,
    setPatientEmail,
    selectedTests,
    open,
    setOpen,
    searchQuery,
    setSearchQuery,
    errors,
    filteredTests,
    handleSelect,
    handleRemoveTest,
    handleSubmit,
    patientGender,
    setPatientGender,
  };
};
