import React from 'react';
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/Textarea";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useContactUs } from './useContactUs';
import PhoneInput from 'react-phone-input-2';

const ContactUs: React.FC = () => {
  const {
    formData,
    errors,
    isSubmitting,
    submitSuccess,
    handleChange,
    handleSubmit,
    handlePhoneChange
  } = useContactUs();

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 p-4">
      <Card className="w-full max-w-md">
        <CardHeader>
          <CardTitle className="text-center text-2xl">Contact Us</CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit} className="space-y-4">
            {/* Name Field */}
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Full Name
              </label>
              <Input
                id="name"
                name="name"
                placeholder="Your Name"
                value={formData.name}
                onChange={handleChange}
                className={`w-full mt-1 ${errors.name ? 'border-red-500' : ''}`}
              />
              {errors.name && (
                <p className="text-red-500 text-sm mt-1">{errors.name}</p>
              )}
            </div>

            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email Address
              </label>
              <Input
                id="email"
                type="email"
                name="email"
                placeholder="you@example.com"
                value={formData.email}
                onChange={handleChange}
                className={`w-full mt-1 ${errors.email ? 'border-red-500' : ''}`}
              />
              {errors.email && (
                <p className="text-red-500 text-sm mt-1">{errors.email}</p>
              )}
            </div>

            <div className="relative">
              <label htmlFor="phone" className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
                Mobile Number
              </label>
              <style>
                {`
  .phone-input-container {
    position: relative;
    width: 100%;
  }
  .phone-input-container .react-tel-input {
    width: 100%;
  }
  .phone-input-container .form-control {
    width: 100% !important;
    height: 40px !important;
    padding-right: 84px !important;
  }
  .phone-input-container .form-control:focus {
    outline: none !important;
    border-color: var(--primary) !important;
  }
  .phone-input-container .form-control:focus-visible {
    outline: none !important;
    ring: 1px var(--primary) !important;
  }
  .phone-input-container .flag-dropdown {
    border: none !important;
    background: transparent !important;
  }
  .phone-input-container .flag-dropdown.open {
    background: white !important;
  }
  .phone-input-container .selected-flag {
    background: transparent !important;
  }
  .phone-input-container .country-list {
    width: 280px !important;
  }
  @media (min-width: 768px) {
    .phone-input-container .country-list {
      width: 320px !important;
    }
  }
`}
              </style>
              <div className="phone-input-container">
                <PhoneInput
                  country={'in'}
                  value={formData.phone}
                  onChange={handlePhoneChange}
                  inputClass={errors.phone ? 'border-red-500' : 'border-gray-300'}
                  containerClass="w-full"
                  enableSearch={true}
                  disableSearchIcon={true}
                  specialLabel=""
                />
              </div>
              {errors.phone && <p className="text-red-500 text-sm mt-1">{errors.phone}</p>}
            </div>
            <div>
              <label htmlFor="subject" className="block text-sm font-medium text-gray-700">
                Subject
              </label>
              <Input
                id="subject"
                name="subject"
                placeholder="Inquiry Subject"
                value={formData.subject}
                onChange={handleChange}
                className={`w-full mt-1 ${errors.subject ? 'border-red-500' : ''}`}
              />
              {errors.subject && (
                <p className="text-red-500 text-sm mt-1">{errors.subject}</p>
              )}
            </div>

            <div>
              <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                Your Interest
              </label>
              <Textarea
                id="message"
                name="message"
                placeholder="Write your interest here..."
                value={formData.message}
                onChange={handleChange}
                className={`w-full min-h-[120px] mt-1 ${errors.message ? 'border-red-500' : ''}`}
              />
              {errors.message && (
                <p className="text-red-500 text-sm mt-1">{errors.message}</p>
              )}
            </div>

            <Button
              variant="orange"
              type="submit"
              className="w-full"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </Button>

            {/* Success Message */}
            {submitSuccess && (
              <div className="text-center text-green-600 mt-4">
                Submitted successfully!
              </div>
            )}
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default ContactUs;
