import React, { useState, useEffect, useRef } from "react";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { SendIcon, X, Mic, MicOff, Loader2, Languages, User } from "lucide-react";
import { useChatUi } from "./useBookChat";
import { Textarea } from "@/components/ui/Textarea";
import { useAuth } from "@/contexts/AuthContext";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { AIInteraction } from "@/types/doctor/globals";
import { ActionButton, ChatMode } from "@/types/global";
import ReactMarkdown from 'react-markdown';
import remarkGfm from "remark-gfm";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Modes } from "@/components/ChatComponents/Modes";
import { CIRCLE_LOGO_URL, LOADING_LOGO_URL } from "@/constants";

interface ModeButton {
  mode: ChatMode;
  label: string;
  icon: any;
}

interface ChatUIProps {
  chatHistory: AIInteraction[];
  isLoading: boolean;
  isAITyping: boolean;
  onSendMessage: (message: string) => void;
  onClose: () => void;
  performAction: (action: string) => void;
  title?: string;
  description?: string;
  availableActions?: ActionButton[];
  availableModes?: ModeButton[];
  currentMode?: ChatMode;
  onModeChange?: (mode: ChatMode) => void;
}

const TypingIndicator = () => (
  <div className="flex space-x-1">
    <div className="w-2 h-2 bg-muted-foreground rounded-full animate-bounce" style={{ animationDelay: '0s' }}></div>
    <div className="w-2 h-2 bg-muted-foreground rounded-full animate-bounce" style={{ animationDelay: '0.2s' }}></div>
    <div className="w-2 h-2 bg-muted-foreground rounded-full animate-bounce" style={{ animationDelay: '0.4s' }}></div>
  </div>
);

export const BookChat: React.FC<ChatUIProps> = ({
  chatHistory,
  isLoading,
  isAITyping,
  onSendMessage,
  onClose,
  performAction,
  title = "Vedita your AI Assistant",
  description = "Chat with the AI about patient analysis",
  availableActions = [],
  availableModes = [],
  currentMode = 'ask',
  onModeChange
}) => {
  const {
    inputMessage,
    scrollAreaRef,
    messagesEndRef,
    sendMessage,
    isRecording,
    startRecording,
    stopRecording,
    recordingTime,
    canvasRef,
    isProcessing,
    MAX_RECORDING_TIME,
    textareaHeight,
    textareaRef,
    handleTextareaChange,
    containerRef,
    handleActionClick,
    language,
    setLanguage
  } = useChatUi(onSendMessage, performAction, chatHistory);
  const { user } = useAuth();
  const [currentModeState, setCurrentMode] = useState(currentMode);
  const [isClearing, setIsClearing] = useState(false);
  const activeModeRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (currentModeState !== currentMode) {
      setIsClearing(true);
      const timer = setTimeout(() => {
        setCurrentMode(currentMode);
        setIsClearing(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [currentMode]);

  useEffect(() => {
    if (activeModeRef.current) {
      activeModeRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center'
      });
    }
  }, [currentModeState]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [chatHistory, isAITyping]);

  return (
    <Card className="flex-grow flex flex-col h-full">
      <CardHeader className="flex flex-row items-center justify-between">
        <div>
          <CardTitle>{title}</CardTitle>
          <CardDescription>{description}</CardDescription>
        </div>
        <Button
          variant="ghost"
          size="icon"
          className="md:hidden"
          onClick={onClose}
        >
          <X className="h-6 w-6" />
        </Button>
      </CardHeader>
      <CardContent className="flex-grow flex flex-col h-full overflow-hidden">
        <ScrollArea className="flex-grow px-2 py-4" ref={scrollAreaRef}>
          <div className={`flex-grow overflow-y-auto px-1 py-8 space-y-4 transition-opacity duration-300 ${isClearing ? 'opacity-0' : 'opacity-100'}`}>
            {chatHistory.map((message, index) => (
              <div key={index} className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'} mb-4`}>
                {index === chatHistory.length - 1 && <div ref={messagesEndRef} />}
                <div className={`flex items-start ${message.role === 'user' ? 'flex-row-reverse' : ''}`}>
                  <Avatar className="h-8 w-8">
                    <AvatarImage
                      src={message.role === 'user' ? (' ') : CIRCLE_LOGO_URL}
                      alt={message.role === 'user' ? 'User Avatar' : 'Vedita Avatar'}
                    />
                    <AvatarFallback className="text-sm bg-[var(--secondary)] text-[var(--primary)]">   {message.role === 'user' ? (
                      <User />
                    ) : (
                      'V'
                    )}</AvatarFallback>
                  </Avatar>
                  <div className={`mx-2 p-3 rounded-lg overflow-x-auto 
                    bg-[hsl(var(--book-explanation-bg))] text-[hsl(var(--book-explanation-text))] border-l-2 border-[hsl(var(--book-explanation-border))]'
                    `}>
                    <ReactMarkdown
                      className={`prose prose-sm max-w-none whitespace-pre-wrap break-words dark:prose-invert`}
                      remarkPlugins={[remarkGfm]}
                      components={{
                        p: ({ children }) => <p className="mb-1 last:mb-0 min-w-fit">{children}</p>,
                        pre: ({ children }) => <pre className={`p-2 rounded-md overflow-x-auto ${message.role === 'user' ? 'bg-primary-foreground/10' : 'bg-secondary'}`}>{children}</pre>,
                        code: ({ children }) => <code className={`px-1 rounded-sm ${message.role === 'user' ? 'bg-primary-foreground/10' : 'bg-secondary'}`}>{children}</code>
                      }}
                    >
                      {message.content}
                    </ReactMarkdown>
                  </div>
                </div>
              </div>
            ))}
            {isAITyping && (
              <div className="flex justify-start mb-4">
                <div ref={messagesEndRef} />
                <div className="flex items-start">
                  <Avatar className="h-9 w-9">
                    <AvatarImage
                      src={LOADING_LOGO_URL} // Replace this with your GIF URL
                      alt="Vedita Avatar"
                    />
                    <AvatarFallback className="text-sm">AI</AvatarFallback>
                  </Avatar>
                  <div className="mx-2 p-3 rounded-lg bg-muted">
                    <TypingIndicator />
                  </div>
                </div>
              </div>
            )}
          </div>
        </ScrollArea>

        {/* Mode and Action buttons */}
        <div className="relative z-10">
          <div className="absolute bottom-full left-0 right-0 mb-2 bg-background">
            {/* Modes */}

            <ScrollArea className="w-full h-[40px] bg-[var(--secondary)] rounded-lg">
              <Modes availableModes={availableModes} currentModeState={currentModeState} activeModeRef={activeModeRef} onModeChange={onModeChange} />
              <ScrollBar orientation="horizontal" className="h-1 bg-[var(--secondary)] text-[var(--primary-text)]" />
            </ScrollArea>

            {/* Action buttons - only render if there are actions */}
            {availableActions && availableActions.length > 0 && (
              <ScrollArea className="w-full h-[40px] mt-2">
                <div className="flex flex-row-reverse gap-2 px-2 py-1">
                  {availableActions.map((action) => (
                    <Button
                      key={action.command}
                      onClick={() => handleActionClick(action.command)}
                      className="transition-all duration-200 flex items-center gap-2 whitespace-nowrap shrink-0 hover:bg-accent hover:text-accent-foreground"
                      variant="ghost"
                      size="sm"
                    >
                      {action.icon && <action.icon className="h-4 w-4" />}
                      {action.label}
                    </Button>
                  ))}
                </div>
                <ScrollBar orientation="horizontal" className="h-2" />
              </ScrollArea>
            )}
          </div>
        </div>

        {/* Chat Input */}
        <div className="mt-auto sticky bottom-0 bg-background">
          <div className="flex items-start w-full">
            {/* <Select
              value={language}
              onValueChange={setLanguage}
            >
              <SelectTrigger className="w-[70px] mr-2 px-2">
                <SelectValue>
                  <div className="flex items-center gap-1.5">
                    <Languages className="h-3 w-3" />
                    <span className="text-sm">{language === 'en-US' ? 'EN' : 'हि'}</span>
                  </div>
                </SelectValue>
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="en-US">
                  <div className="flex items-center gap-1.5">
                    <Languages className="h-3 w-3" />
                    <span className="text-sm">EN</span>
                  </div>
                </SelectItem>
                <SelectItem value="hi-IN">
                  <div className="flex items-center gap-1.5">
                    <Languages className="h-3 w-3" />
                    <span className="text-sm">हि</span>
                  </div>
                </SelectItem>
              </SelectContent>
            </Select> */}
            <div className="flex-grow relative">
              {isRecording || isProcessing ? (
                <div className="w-full bg-[hsl(var(--book-shloka-bg))] text-[hsl(var(--book-shloka-text))] border-l-2 border-[hsl(var(--book-shloka-border))] rounded-lg flex items-center justify-between">
                  {isRecording ? (
                    <>
                      <div ref={containerRef} className="flex-grow h-6 flex items-center justify-center relative overflow-hidden">
                        <canvas ref={canvasRef} className="absolute inset-0 w-full h-full"></canvas>
                        <span className="z-10">
                          Recording... {recordingTime}s / {MAX_RECORDING_TIME}s
                        </span>
                      </div>
                      <Button
                        onClick={() => stopRecording(false)}
                        variant="ghost"
                        size="icon"
                      >
                        <MicOff className="h-4 w-4" />
                        <span className="sr-only">Stop Recording</span>
                      </Button>
                    </>
                  ) : (
                    <div className="flex items-center justify-center w-full h-10">
                      <Loader2 className="animate-spin h-6 w-6" />
                      <span className="ml-2">Processing...</span>
                    </div>
                  )}
                </div>
              ) : (
                <Textarea
                  ref={textareaRef}
                  title="Ctrl+Space to toggle recording, Enter to send"
                  placeholder="Type here..."
                  value={inputMessage}
                  onChange={handleTextareaChange}
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault();
                      sendMessage();
                    }
                  }}
                  className="w-full pr-10 resize-none bg-[hsl(var(--book-translation-bg))] text-[hsl(var(--book-translation-text))] border-l-2 border-[hsl(var(--book-translation-border))]"
                  style={{ height: textareaHeight, minHeight: "40px", maxHeight: "120px", overflowY: "auto" }}
                  disabled={isLoading || isAITyping}
                  rows={1}
                />
              )}
              {/* {!isRecording && !isProcessing && (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        onClick={startRecording}
                        className="absolute right-2 bottom-2 p-1 top-1 rounded-full transition-colors"
                        disabled={isLoading || isAITyping}
                        variant="ghost"
                        size="icon"
                      >
                        <Mic className="h-4 w-4" />
                        <span className="sr-only">Start Recording</span>
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Double Ctrl/Cmd to start/stop recording</p>
                      <p>Ctrl/Cmd + Space to stop and send</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )} */}
            </div>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>

                  <Button
                    onClick={() => {
                      if (isRecording) {
                        stopRecording(true);
                      } else {
                        sendMessage();
                      }
                    }}
                    className="ml-2 self-end bg-[var(--primary)] hover:bg-[var(--primary-hover)] text-[var(--secondary-text)]"
                    disabled={isLoading || isAITyping || isProcessing}
                    size="icon"
                  >
                    <SendIcon className="h-4 w-4" />
                    <span className="sr-only">Send message</span>
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Ctrl/Cmd + Space to send</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
