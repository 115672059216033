import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Users, Microscope, ClipboardList, ArrowUpRight, ArrowDownRight, Calendar, FileText, User2, Clock, CheckCircle2 } from "lucide-react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useDashboard } from './useDashboard';
import DoctorLayout from "@/layouts/Doctor/DoctorLayout"
import withLayout from "@/hoc/withLayout"
import { Link } from "react-router-dom";
import { useAuth } from "@/contexts/AuthContext";
import { Skeleton } from "@/components/ui/skeleton";
import { format } from "date-fns";
import { Button } from "@/components/ui/button";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from "@/components/ui/table";

const iconMap = {
    Users,
    Microscope,
    ClipboardList,
};

const DoctorDashboard = () => {
    const { stats, recentOrders, recentPatientVisits, isLoading } = useDashboard();
    const { user } = useAuth();
    const isMobileOrTablet = useMediaQuery("(max-width: 1024px)");

    if (isLoading) {
        return (
            <div className="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8">
                {/* Header Skeleton */}
                <div className="mb-8 flex flex-col sm:flex-row sm:items-center justify-between gap-2 sm:gap-0">
                    <div className="flex flex-col gap-2">
                        <Skeleton variant="title" className="h-8 w-48" />
                        <Skeleton variant="title" className="h-8 w-64" />
                    </div>
                    <div className="flex items-center space-x-2">
                        <Skeleton variant="content" className="h-5 w-5" />
                        <Skeleton variant="content" className="h-5 w-32" />
                    </div>
                </div>

                {/* Stats Cards Skeleton */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 mb-8">
                    {[1, 2, 3].map((index) => (
                        <Card key={index} className="hover:shadow-lg transition-shadow duration-200">
                            <CardHeader className="flex flex-row items-center justify-between pb-2">
                                <Skeleton variant="title" className="h-4 w-24" />
                                <Skeleton variant="content" className="h-7 w-7 rounded-full" />
                            </CardHeader>
                            <CardContent>
                                <div className="flex items-center justify-between">
                                    <Skeleton variant="title" className="h-8 w-16" />
                                </div>
                                <div className="pt-2">
                                    <Skeleton variant="content" className="h-4 w-24" />
                                </div>
                            </CardContent>
                        </Card>
                    ))}
                </div>

                {/* Recent Orders and Visits Skeleton */}
                <div className="flex flex-col space-y-6">
                    {/* Recent Orders */}
                    <Card className="hover:shadow-lg transition-shadow duration-300">
                        <CardHeader className="pb-3">
                            <div className="flex items-center gap-2">
                                <Skeleton variant="content" className="h-10 w-10 rounded-lg" />
                                <Skeleton variant="title" className="h-6 w-32" />
                            </div>
                        </CardHeader>
                        <CardContent>
                            <div className="space-y-4">
                                {[1, 2, 3].map((index) => (
                                    <div key={index} className="flex flex-col space-y-3 p-4 border rounded-lg">
                                        <div className="flex justify-between items-start">
                                            <div className="space-y-2">
                                                <div className="flex items-center gap-2">
                                                    <Skeleton variant="content" className="h-4 w-4" />
                                                    <Skeleton variant="title" className="h-5 w-48" />
                                                </div>
                                                <Skeleton variant="content" className="h-4 w-32" />
                                                <div className="flex items-center gap-2">
                                                    <Skeleton variant="content" className="h-2 w-24" />
                                                    <Skeleton variant="content" className="h-4 w-16" />
                                                </div>
                                            </div>
                                            <Skeleton variant="content" className="h-8 w-24 rounded-full" />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </CardContent>
                    </Card>

                    {/* Recent Patient Visits */}
                    <Card className="hover:shadow-lg transition-shadow duration-300">
                        <CardHeader className="pb-3">
                            <div className="flex items-center gap-2">
                                <Skeleton variant="content" className="h-10 w-10 rounded-lg" />
                                <Skeleton variant="title" className="h-6 w-40" />
                            </div>
                        </CardHeader>
                        <CardContent>
                            <div className="space-y-4">
                                {[1, 2, 3].map((index) => (
                                    <div key={index} className="flex flex-col space-y-3 p-4 border rounded-lg">
                                        <div className="flex justify-between items-start">
                                            <div className="space-y-2">
                                                <div className="flex items-center gap-2">
                                                    <Skeleton variant="content" className="h-4 w-4" />
                                                    <Skeleton variant="title" className="h-5 w-48" />
                                                </div>
                                                <div className="flex items-center gap-2">
                                                    <Skeleton variant="content" className="h-4 w-4" />
                                                    <Skeleton variant="content" className="h-4 w-32" />
                                                </div>
                                            </div>
                                            <Skeleton variant="content" className="h-8 w-24 rounded-full" />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>
        );
    }

    const statsCards = [
        {
            title: "Total Patients",
            value: stats.totalPatients,
            icon: "Users",
            subValue: "Active patients",
            bgColor: "bg-[var(--primary-light)]"
        },
        {
            title: "Total Tests",
            value: stats.totalTestsAssigned,
            icon: "Microscope",
            subValue: `${stats.completedTests} completed`,
            bgColor: "bg-[var(--primary-light)]"
        },
        {
            title: "Tests In Progress",
            value: stats.testsInProgress,
            icon: "ClipboardList",
            subValue: `${stats.completedTests} completed`,
            bgColor: "bg-[var(--primary-light)]"
        },
    ];

    return (
        <div className="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8">
            {/* Welcome Section */}
            <div className="mb-8 flex flex-col sm:flex-row sm:items-center justify-between gap-2 sm:gap-0">

                <div className="flex flex-col md:flex-col justify-between  gap-4">
                    <h2 className="text-3xl font-bold">Welcome back!</h2>
                    <h1 className="text-3xl font-bold tracking-tight">
                        {user?.name && user.name.charAt(0).toUpperCase() + user.name.slice(1)}
                    </h1>
                </div>


                <div className="flex items-center space-x-2 text-muted-foreground">
                    <Calendar className="h-5 w-5" />
                    <span>{format(new Date(), 'MMMM d, yyyy')}</span>
                </div>
            </div>

            {/* Stats Section */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 mb-8">
                {statsCards.map((stat, index) => {
                    const Icon = iconMap[stat.icon as keyof typeof iconMap];
                    return (
                        <Card key={index} className="hover:shadow-lg transition-shadow duration-200">
                            <CardHeader className="flex flex-row items-center justify-between pb-2">
                                <div className="flex flex-col space-y-1.5">
                                    <CardTitle className="text-sm font-medium text-muted-foreground">{stat.title}</CardTitle>
                                </div>
                                <div className={`p-3 rounded-full ${stat.bgColor}`}>
                                    <Icon className={`h-7 w-7 text-[var(--primary)]`} />
                                </div>
                            </CardHeader>
                            <CardContent>
                                <div className="flex items-center justify-between">
                                    <div className="text-2xl font-bold">{stat.value.toString()}</div>
                                </div>
                                <p className="text-sm text-muted-foreground mt-2">{stat.subValue}</p>
                            </CardContent>
                        </Card>
                    );
                })}
            </div>

            {/* Recent Orders and Visits Section */}
            <div className="flex flex-col space-y-6">
                {/* Recent Orders */}
                <Card className="hover:shadow-lg transition-shadow duration-300 border-l-4" style={{ borderLeftColor: '#FF9F6A' }}>
                    <CardHeader className="pb-3">
                        <div className="flex items-center gap-2">
                            <div className="p-2 rounded-lg" style={{ backgroundColor: 'rgba(255, 159, 106, 0.1)' }}>
                                <FileText className="w-5 h-5" style={{ color: '#FF9F6A' }} />
                            </div>
                            <CardTitle>Recent Orders</CardTitle>
                        </div>
                    </CardHeader>
                    <CardContent className="overflow-x-auto">
                        {isMobileOrTablet ? (
                            <div className="space-y-4">
                                {recentOrders.length > 0 ? (
                                    recentOrders.map((order) => (
                                        <Card key={order.id} className="p-4 border-l-4 hover:shadow-md transition-all" style={{ borderLeftColor: '#FF9F6A' }}>
                                            <div className="flex flex-col space-y-3">
                                                <div className="flex justify-between items-start">
                                                    <div>
                                                        <div className="flex items-center gap-2">
                                                            <User2 className="w-4 h-4 text-[var(--primary)]" />
                                                            <h3 className="font-medium">{order.patientName}</h3>
                                                        </div>
                                                        <TooltipProvider>
                                                            <Tooltip>
                                                                <TooltipTrigger>
                                                                    <p className="text-sm text-muted-foreground mt-1">
                                                                        {order.testNames.length > 30
                                                                            ? `${order.testNames.substring(0, 30)}...`
                                                                            : order.testNames}
                                                                    </p>
                                                                </TooltipTrigger>
                                                                <TooltipContent>
                                                                    <p>{order.testNames}</p>
                                                                </TooltipContent>
                                                            </Tooltip>
                                                        </TooltipProvider>
                                                        <div className="flex items-center gap-2 mt-2">
                                                            <div className="h-2 w-24 bg-gray-200 rounded-full overflow-hidden">
                                                                <div
                                                                    className="h-full transition-all duration-300"
                                                                    style={{
                                                                        backgroundColor: '#FF9F6A',
                                                                        width: `${(order.completedTests / order.totalTests) * 100}%`
                                                                    }}
                                                                />
                                                            </div>
                                                            <p className="text-xs text-muted-foreground">
                                                                {order.completedTests}/{order.totalTests}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between items-center pt-2 border-t">
                                                    <div className="flex items-center gap-1 text-sm text-muted-foreground">
                                                        <Calendar className="w-4 h-4" />
                                                        <span>{format(new Date(order.orderedAt), 'MMM d, yyyy')}</span>
                                                    </div>
                                                    {/* <Link to={`/doctor/orders/${order.id}`}>
                                                        <Button
                                                            variant="outline"
                                                            size="sm"
                                                            className="rounded-full hover:bg-opacity-10 border flex items-center gap-1"
                                                            style={{
                                                                borderColor: '#FF9F6A',
                                                                color: '#FF9F6A',
                                                                backgroundColor: 'transparent',
                                                                '--hover-bg': 'rgba(255, 159, 106, 0.1)'
                                                            } as React.CSSProperties}
                                                        >
                                                            View Order
                                                        </Button>
                                                    </Link> */}
                                                </div>
                                            </div>
                                        </Card>
                                    ))
                                ) : (
                                    <div className="flex flex-col items-center justify-center py-8 text-center">
                                        <FileText className="w-12 h-12 text-muted-foreground/30 mb-3" />
                                        <p className="text-muted-foreground">No recent orders</p>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        <TableHead>Patient Name</TableHead>
                                        <TableHead>Tests</TableHead>
                                        <TableHead>Progress</TableHead>
                                        <TableHead>Ordered Date</TableHead>
                                        {/* <TableHead>Action</TableHead> */}
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {recentOrders.map((order) => (
                                        <TableRow key={order.id} className="hover:bg-opacity-5" style={{ '--hover-bg': 'rgba(255, 159, 106, 0.05)' } as React.CSSProperties}>
                                            <TableCell>
                                                <div className="flex items-center gap-2">
                                                    <User2 className="w-4 h-4" style={{ color: '#FF9F6A' }} />
                                                    <span className="font-medium">{order.patientName}</span>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <TooltipProvider>
                                                    <Tooltip>
                                                        <TooltipTrigger>
                                                            <span className="text-sm text-muted-foreground">
                                                                {order.testNames.length > 30
                                                                    ? `${order.testNames.substring(0, 30)}...`
                                                                    : order.testNames}
                                                            </span>
                                                        </TooltipTrigger>
                                                        <TooltipContent>
                                                            <p>{order.testNames}</p>
                                                        </TooltipContent>
                                                    </Tooltip>
                                                </TooltipProvider>
                                            </TableCell>
                                            <TableCell>
                                                <div className="flex items-center gap-2">
                                                    <div className="h-2 w-24 bg-gray-200 rounded-full overflow-hidden">
                                                        <div
                                                            className="h-full transition-all duration-300"
                                                            style={{
                                                                backgroundColor: '#FF9F6A',
                                                                width: `${(order.completedTests / order.totalTests) * 100}%`
                                                            }}
                                                        />
                                                    </div>
                                                    <span>{order.completedTests}/{order.totalTests}</span>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div className="flex items-center gap-1">
                                                    <Calendar className="w-4 h-4 text-muted-foreground" />
                                                    {format(new Date(order.orderedAt), 'MMM d, yyyy')}
                                                </div>
                                            </TableCell>
                                            {/* <TableCell>
                                                <Link to={`/doctor/orders/${order.id}`}>
                                                    <Button
                                                        variant="outline"
                                                        size="sm"
                                                        className="rounded-full hover:bg-opacity-10 border flex items-center gap-1"
                                                        style={{
                                                            borderColor: '#FF9F6A',
                                                            color: '#FF9F6A',
                                                            backgroundColor: 'transparent',
                                                            '--hover-bg': 'rgba(255, 159, 106, 0.1)'
                                                        } as React.CSSProperties}
                                                    >
                                                        View Order
                                                    </Button>
                                                </Link>
                                            </TableCell> */}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        )}
                    </CardContent>
                </Card>

                {/* Recent Patient Visits */}
                <Card className="hover:shadow-lg transition-shadow duration-300 border-l-4" style={{ borderLeftColor: '#22C55E' }}>
                    <CardHeader className="pb-3">
                        <div className="flex items-center gap-2">
                            <div className="p-2 rounded-lg" style={{ backgroundColor: 'rgba(34, 197, 94, 0.1)' }}>
                                <Users className="w-5 h-5" style={{ color: '#22C55E' }} />
                            </div>
                            <CardTitle>Recent Patient Visits</CardTitle>
                        </div>
                    </CardHeader>
                    <CardContent className="overflow-x-auto">
                        {isMobileOrTablet ? (
                            <div className="space-y-4">
                                {recentPatientVisits.length > 0 ? (
                                    recentPatientVisits.map((visit) => (
                                        <Card key={visit.id} className="p-4 border-l-4 hover:shadow-md transition-all" style={{ borderLeftColor: '#22C55E' }}>
                                            <div className="flex flex-col space-y-3">
                                                <div className="flex justify-between items-start">
                                                    <div>
                                                        <div className="flex items-center gap-2">
                                                            <User2 className="w-4 h-4" style={{ color: '#22C55E' }} />
                                                            <h3 className="font-medium">{`${visit.firstName} ${visit.lastName}`}</h3>
                                                        </div>
                                                        <div className="flex items-center gap-2 mt-2">
                                                            <Calendar className="w-4 h-4 text-muted-foreground" />
                                                            <p className="text-xs text-muted-foreground">
                                                                {format(new Date(visit.visitDate), 'MMM d, yyyy')}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between items-center pt-2 border-t">
                                                    <Link to={`/doctor/analysis/${visit.id}`} className="w-full">
                                                        <Button
                                                            variant="outline"
                                                            size="sm"
                                                            className="w-full rounded-full hover:bg-opacity-10 border flex items-center gap-1 justify-center"
                                                            style={{
                                                                borderColor: '#22C55E',
                                                                color: '#22C55E',
                                                                backgroundColor: 'transparent',
                                                                '--hover-bg': 'rgba(34, 197, 94, 0.1)'
                                                            } as React.CSSProperties}
                                                        >
                                                            <User2 className="w-4 h-4 mr-1" />
                                                            View Patient
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </Card>
                                    ))
                                ) : (
                                    <div className="flex flex-col items-center justify-center py-8 text-center">
                                        <Users className="w-12 h-12 text-muted-foreground/30 mb-3" />
                                        <p className="text-muted-foreground">No recent visits</p>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        <TableHead>Patient Name</TableHead>
                                        <TableHead>Visit Date</TableHead>
                                        <TableHead>Action</TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {recentPatientVisits.map((visit) => (
                                        <TableRow key={visit.id} className="hover:bg-opacity-5" style={{ '--hover-bg': 'rgba(34, 197, 94, 0.05)' } as React.CSSProperties}>
                                            <TableCell>
                                                <div className="flex items-center gap-2">
                                                    <User2 className="w-4 h-4" style={{ color: '#22C55E' }} />
                                                    <span className="font-medium">{`${visit.firstName} ${visit.lastName}`}</span>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div className="flex items-center gap-1">
                                                    <Calendar className="w-4 h-4 text-muted-foreground" />
                                                    {format(new Date(visit.visitDate), 'MMM d, yyyy')}
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <Link to={`/doctor/analysis/${visit.id}`}>
                                                    <Button
                                                        variant="outline"
                                                        size="sm"
                                                        className="rounded-full hover:bg-opacity-10 border flex items-center gap-1"
                                                        style={{
                                                            borderColor: '#22C55E',
                                                            color: '#22C55E',
                                                            backgroundColor: 'transparent',
                                                            '--hover-bg': 'rgba(34, 197, 94, 0.1)'
                                                        } as React.CSSProperties}
                                                    >
                                                        <User2 className="w-4 h-4 mr-1" />
                                                        View Patient
                                                    </Button>
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        )}
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};

export default withLayout(DoctorLayout)(DoctorDashboard);
