import { useParams, useNavigate } from 'react-router-dom';
import { useOrderService } from '@/services/Patient/orderService';
import { useEffect } from 'react';
import { useAuth } from '@/contexts/AuthContext';

export const useOrderDetails = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();

  const { useGetOrderDetails, makePayment, verifyPayment, startTest } = useOrderService();
  const { data: response, isLoading, error, refetch } = useGetOrderDetails(orderId || '');

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);


  const handleProceedToPayment = async () => {
    if (!orderId) return;

    try {
      const orderData = await makePayment.mutateAsync(orderId);
      if (!orderData.razorpayOrder) {
        throw new Error('Failed to create payment order');
      }
      const options = {
        key: "rzp_test_4MtJ3MvwsXU49D",
        amount: orderData.razorpayOrder.amount,  // Amount in paise
        currency: orderData.razorpayOrder.currency,
        name: 'Your Company Name',
        description: 'Test Payment',
        order_id: orderData.razorpayOrder.id,  // Use the Razorpay order ID here
        handler: async (response: any) => {
          // Verify payment
          const verifyData = await verifyPayment.mutateAsync({
            orderId: orderId,
            paymentData: {
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature
            }
          });

          if (verifyData.status === 'SUCCESS') {
            refetch()
          } else {
            console.log("Payment verification failed", verifyData);
          }
        },
        prefill: {
          name: user?.name,
          email: user?.email,
          contact: user?.mobile
        },
        theme: {
          color: '#3399cc'
        }
      };

      const rzp = new (window as any).Razorpay(options);
      rzp.open();
    } catch (error) {
      console.log("Payment failed", error);
    }
  };

  const handleStartTest = async (testId: string, assessment?: {
    _id: string,
    progress?: {
      progressPercentage: number,
      status: string
    }
  }) => {
    if (!orderId) return;
    try {
      if (assessment) {
        
        const progressPercentage = assessment.progress?.progressPercentage;
        if (typeof progressPercentage === 'number' && progressPercentage > 0) {
          navigate(`/patient/test/resume/${assessment._id}`);
        } else {

          navigate(`/patient/test/assessment/${assessment._id}`);

        }
      } else {
        const response = await startTest.mutateAsync({ orderId, testId });
        if (response.data?.id) {
          navigate(`/patient/test/assessment/${response.data.id}`);
        }
      }
    } catch (error) {
      console.error('Failed to start/resume test:', error);
    }
  };

  if (!orderId) {
    return {
      orderDetails: null,
      isLoading: false,
      error: new Error('Order ID is required'),
    };
  }

  return {
    orderDetails: response?.data?.order,
    isLoading,
    error,
    handleProceedToPayment,
    handleStartTest
  };
};
