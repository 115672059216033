import React, { useState, useEffect } from 'react'
import { ScrollArea } from '@/components/ui/scroll-area'
import { BookOpen, ChevronLeft, ChevronRight, ChevronDown, ChevronUp, Table } from 'lucide-react'
import { cn } from '@/lib/utils'
import { useBookReader } from '@/contexts/BookReaderContext'
import { useBookContent } from '@/services/bookService'
import { Skeleton } from '@/components/ui/skeleton'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { hexToText } from '@/utils/toolkit'
import { Shloka, PageContent } from '@/types/book'

interface QnASectionProps {
  qa: any;
  qaIndex: number;
}

const QnASection = ({ qa, qaIndex }: QnASectionProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div key={qaIndex} className="mb-4 last:mb-0">
      <button 
        onClick={() => setIsOpen(!isOpen)}
        className="w-full flex items-center justify-between text-left hover:bg-[hsl(var(--book-qna-border))] rounded-sm p-1"
      >
        <div className="question font-semibold">{qa.question}</div>
        {isOpen ? (
          <ChevronUp className="h-5 w-5 flex-shrink-0" />
        ) : (
          <ChevronDown className="h-5 w-5 flex-shrink-0" />
        )}
      </button>
      {isOpen && (
        <div className="answer ml-4 mt-2 text-[hsl(var(--book-qna-text))]">
          {qa.answer}
        </div>
      )}
    </div>
  );
};

const ShlokaExplanation = ({ shloka }: { shloka: Shloka }) => {
  const [isOpen, setIsOpen] = useState(true);
  
  if (!shloka.excryptedText4) return null;
  const explanation = hexToText(shloka.excryptedText4) || hexToText(shloka.excryptedText3) || '';

  return (
    <div className="explanation mt-4">
      <button 
        onClick={() => setIsOpen(!isOpen)}
        className="w-full flex items-center justify-between text-left hover:bg-[hsl(var(--book-qna-border))] rounded-sm p-1"
      >
        <span className="font-semibold text-[hsl(var(--book-explanation-heading))]">Explanation</span>
        {isOpen ? (
          <ChevronUp className="h-4 w-4 flex-shrink-0" />
        ) : (
          <ChevronDown className="h-4 w-4 flex-shrink-0" />
        )}
      </button>
      {isOpen && (
        <div className="mt-2">
          <ReactMarkdown 
            className="prose dark:prose-invert prose-sm max-w-none"
            remarkPlugins={[remarkGfm]}
            components={{
              p: ({children}) => (
                <p className="text-[hsl(var(--book-explanation-text))] leading-relaxed">
                  {children}
                </p>
              ),
              a: ({children, href}) => (
                <a href={href} className="text-primary hover:underline">
                  {children}
                </a>
              ),
            }}
          >
            {explanation}
          </ReactMarkdown>
        </div>
      )}
    </div>
  );
};


export function BookWindow() {
  const { book, currentPage, pages, setCurrentPage, tableOfContents, setPageContent } = useBookReader()

  const nextPage = () => {
    if (currentPage < pages.length) {
      setCurrentPage(currentPage + 1)
    }
  }

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  // Get current page data
  const currentPageData = pages.find(p => p.page === currentPage)
  const currentTocItem = tableOfContents.find(item => item.page === currentPage)
  const { data: pageContent, isLoading } = useBookContent(book?.bookId, currentPage)

  // Store pageContent in context whenever it changes
  useEffect(() => {
    if (pageContent) {
      setPageContent(pageContent as PageContent);
    }
  }, [pageContent, setPageContent]);

  const renderTopics = (topics: string[] | undefined) => {
    if (!topics || topics.length === 0) return null;
    return (
      <div className="book-topic">
        <h3 className="text-lg font-semibold mb-2">Topics Covered:</h3>
        <ul className="list-disc list-inside space-y-1">
          {topics.map((topic, index) => (
            <li key={index}>{topic}</li>
          ))}
        </ul>
      </div>
    );
  };

  const renderShlokas = (shlokas: Shloka[]) => {
    if (!shlokas.length) return null;
    return (
      <div className="mt-8 space-y-8">
        {shlokas.map((shloka, index) => (
          <div key={index} className="content mb-8">
            <div className="shloka-number text-sm text-muted-foreground font-semibold mb-1">
              Shloka {shloka.numberTo ? `${shloka.numberFrom}-${shloka.numberTo}` : shloka.numberFrom}
            </div>
            <div className="shloka">
              {hexToText(shloka.excryptedText1) || shloka.excryptedText1}
            </div>
            <ShlokaExplanation shloka={shloka} />
            {shloka.qna?.length > 0 && (
              <div className="qna">
                <div className="font-semibold mb-2">Questions & Answers:</div>
                {shloka.qna.map((qa: any, qaIndex: number) => (
                  <QnASection key={qaIndex} qa={qa} qaIndex={qaIndex} />
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const renderContent = (content: PageContent) => {
    if (!content) return null;

    switch (currentPageData?.pageType) {
      case 'CoverPage':
        return (
          <div className="h-full flex flex-col items-center justify-center text-center">
            <h1 className="header text-4xl font-bold mb-4">{content.header}</h1>
            <p className="book-description whitespace-pre-line">{content.description}</p>
          </div>
        );

      case 'Sthanam':
        return (
          <div className="h-full flex flex-col items-center justify-center text-center">
            <h1 className="header text-4xl font-bold mb-4">{content.header}</h1>
            {content.description && (
              <p className="book-description mt-4">{content.description}</p>
            )}
            {renderTopics(content.topicsCovered)}
          </div>
        );

      case 'Chapter':
        return (
          <div className="space-y-6">
            {content.header && (
              <h1 className="header text-4xl font-bold mb-4">{content.header}</h1>
            )}
            {content.description && (
              <p className="book-description">{content.description}</p>
            )}
            {renderTopics(content.topicsCovered)}
          </div>
        );

      case 'Section':
        return (
          <div className="space-y-6">
            <h1 className="subheader text-2xl font-bold mb-4">{content.header}</h1>
            {content.description && (
              <p className="book-description">{content.description}</p>
            )}
            {renderTopics(content.topicsCovered)}
            {renderShlokas(content.shlokas)}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="flex-1 border-l border-[hsl(var(--book-topic-border))] h-screen">
      <div className="h-full flex flex-col">
        <div className="sticky top-0 bg-[hsl(var(--book-topic-bg))] z-10 border-b border-[hsl(var(--book-topic-border))]">
          <div className="px-6 py-3 flex items-center justify-between">
            <div className="flex items-center gap-2 text-[hsl(var(--book-topic-text))]">
              <BookOpen className="h-5 w-5" />
              <h2 className="text-lg font-semibold">{book?.title}</h2>
            </div>
            <div className="flex items-center gap-2">
              <button
                onClick={prevPage}
                disabled={currentPage === 1}
                className={cn(
                  "p-2 rounded-full hover:bg-[hsl(var(--book-topic-border))] transition-colors text-[hsl(var(--book-topic-text))]",
                  currentPage === 1 && "opacity-50 cursor-not-allowed"
                )}
              >
                <ChevronLeft className="h-5 w-5" />
              </button>
              <span className="text-sm text-[hsl(var(--book-topic-text))]">
                Page {currentPage} of {pages.length}
              </span>
              <button
                onClick={nextPage}
                disabled={currentPage === pages.length}
                className={cn(
                  "p-2 rounded-full hover:bg-[hsl(var(--book-topic-border))] transition-colors text-[hsl(var(--book-topic-text))]",
                  currentPage === pages.length && "opacity-50 cursor-not-allowed"
                )}
              >
                <ChevronRight className="h-5 w-5" />
              </button>
            </div>
          </div>
          {currentTocItem && (
            <div className="px-6 py-2 border-t border-[hsl(var(--book-topic-border))] bg-[hsl(var(--book-topic-bg))]">
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-2 text-sm text-[hsl(var(--book-topic-text))]">
                  {currentPageData?.pageType === 'Sthanam' && (
                    <span>{currentTocItem.header}</span>
                  )}
                  {currentPageData?.pageType === 'Chapter' && (
                    <>
                      <span>{currentPageData.sthanam}</span>
                      <ChevronRight className="h-4 w-4" />
                      <span>Chapter {currentPageData.chapter?.match(/\d+/)?.[0] || currentPageData.chapter}</span>
                    </>
                  )}
                  {currentPageData?.pageType === 'Section' && (
                    <>
                      <span>{currentPageData.sthanam}</span>
                      <ChevronRight className="h-4 w-4" />
                      <span>Chapter {currentPageData.chapter?.match(/\d+/)?.[0] || currentPageData.chapter}</span>
                      <ChevronRight className="h-4 w-4" />
                      <span>Section {currentPageData.input.sectionNumber || ''}</span>
                    </>
                  )}
                </div>
                {currentPageData?.pageType === 'Section' && (
                  <p className="font-medium">{currentTocItem.header}</p>
                )}
              </div>
            </div>
          )}
        </div>
        <ScrollArea className="flex-1">
          <div className="p-6 max-w-4xl mx-auto">
            {isLoading ? (
              <div className="space-y-6">
                {/* Title Skeleton */}
                <div>
                  <Skeleton variant="title" className="h-10 w-3/4" />
                </div>

                {/* Description Skeleton */}
                <div className="space-y-3">
                  <Skeleton variant="content" className="h-4 w-full" />
                  <Skeleton variant="content" className="h-4 w-5/6" />
                  <Skeleton variant="content" className="h-4 w-4/5" />
                </div>

                {/* Content Blocks Skeleton */}
                <div className="space-y-8">
                  {[1, 2].map((index) => (
                    <div key={index} className="space-y-4">
                      <Skeleton variant="content" className="h-16 w-full" />
                      <div className="pl-4 space-y-3">
                        <Skeleton variant="content" className="h-4 w-full" />
                        <Skeleton variant="content" className="h-4 w-11/12" />
                        <Skeleton variant="content" className="h-4 w-4/5" />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              renderContent(pageContent as PageContent)
            )}
          </div>
        </ScrollArea>
      </div>
    </div>
  )
}
