import React, { createContext, useContext } from 'react';
import { useBook, useBookGeneratePagesAndToc } from '@/services/bookService';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { BookPage, PageContent, TableOfContents } from '@/types/book';


interface BookReaderContextType {
  book: any; // Replace with proper Book type
  tableOfContents: TableOfContents[];
  pages: BookPage[];
  currentPage: number;
  pageContent: PageContent | null;
  language: string;
  setLanguage: (language: string) => void;
  setPageContent: (content: PageContent | null) => void;
  setCurrentPage: (page: number) => void;
  isLoadingBook: boolean;
  isLoadingToc: boolean;
  handleBackToLibrary: () => void;
  handleViewBookDetails: () => void;
  handlePageSelect: (page: number) => void;
}

const BookReaderContext = createContext<BookReaderContextType | undefined>(undefined);

export function BookReaderProvider({ children }: { children: React.ReactNode }) {
  const { bookId = '' } = useParams();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageContent, setPageContent] = useState<PageContent | null>(null);
  const [language, setLanguage] = useState('en-US');

  const { data: book, isLoading: isLoadingBook } = useBook(bookId);
  const { data: tableOfContents, isLoading: isLoadingToc } = useBookGeneratePagesAndToc(bookId);

  const handleBackToLibrary = () => navigate('/my-library');
  const handleViewBookDetails = () => navigate(`/books/${bookId}`);
  const handlePageSelect = (page: number) => {
    setCurrentPage(page);
  };


  return (
    <BookReaderContext.Provider
      value={{
        book,
        tableOfContents: tableOfContents?.toc || [],
        pages: tableOfContents?.pages || [],
        currentPage,
        pageContent,
        setPageContent,
        setCurrentPage,
        isLoadingBook,
        isLoadingToc,
        handleBackToLibrary,
        handleViewBookDetails,
        handlePageSelect,
        setLanguage,
        language,
      }}
    >
      {children}
    </BookReaderContext.Provider>
  );
}

export const useBookReader = () => {
  const context = useContext(BookReaderContext);
  if (context === undefined) {
    throw new Error('useBookReader must be used within a BookReaderProvider');
  }
  return context;
};