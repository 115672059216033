import { useApi } from "@/hooks/useApi";
import { DoctorDetails, DashboardStats } from '@/types/doctor';




export const useAdminService = () => {
  const api = useApi();

  const getDashboardStats = async (page: number = 1, perPage: number = 10): Promise<DashboardStats> => {
    try {
      const response = await api.get('/dashboard', {
        params: {
          page,
          perPage
        }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };


  const getDoctor = async (doctorId: string): Promise<DoctorDetails> => {
    try {
      const response = await api.get(`/profile/get-doctor/${doctorId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const updateDoctorStatus = async (
    doctorId: string, 
    isApproved: boolean
  ): Promise<any> => {
    try {
      const response = await api.patch(`/profile/update-approval/${doctorId}`, { isApproved });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  return {
    getDashboardStats,
    getDoctor,
    updateDoctorStatus
  };
};