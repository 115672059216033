import React from 'react';
import { Button } from '@/components/ui/button';
import { ResizablePanel } from '@/components/ui/resizable';
import { BookWindow } from '@/components/book/book-window';
import { CopilotWindow } from '@/components/book/CopilotWindow/CopilotWindow';
import { BookReaderProvider, useBookReader } from '@/contexts/BookReaderContext';

function BookReaderContent() {
  const {
    book,
    isLoadingBook,
    handleBackToLibrary,
    handleViewBookDetails,
  } = useBookReader();

  if (!book && !isLoadingBook) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-foreground">Book Not Found</h2>
          <p className="mt-2 text-muted-foreground">
            The book you're looking for doesn't exist or you don't have access to it.
          </p>
          <Button className="mt-4" onClick={handleBackToLibrary}>
            Return to Library
          </Button>
        </div>
      </div>
    );
  }

  if (book && !book.purchased) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-foreground">Purchase Required</h2>
          <p className="mt-2 text-muted-foreground">
            You need to purchase this book to access its content.
          </p>
          <div className="mt-4 space-x-4">
            <Button onClick={handleViewBookDetails}>View Book Details</Button>
            <Button variant="outline" onClick={handleBackToLibrary}>
              Return to Library
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex bg-background">
      <ResizablePanel defaultSize={40} minSize={10} maxSize={60}>
        <CopilotWindow />
      </ResizablePanel>
      <BookWindow />
    </div>
  );
}

export function BookReader() {
  return (
    <BookReaderProvider>
      <BookReaderContent />
    </BookReaderProvider>
  );
} 
