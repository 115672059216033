import React from 'react';
import ScrollReveal from '../ScrollReveal/ScrollReveal';

interface FeatureItemProps {
    title: string;
    description: string;
}

const FeatureItem: React.FC<FeatureItemProps> = ({ title, description }) => (
    <div className="flex-1 px-8 first:border-r border-gray-200 md:first:border-r first:border-b md:first:border-b-0 pb-8 md:pb-0">
        <h3 className="text-2xl font-semibold text-[#2D3748] mb-4">{title}</h3>
        <p className="text-gray-600 text-lg leading-relaxed">{description}</p>
    </div>
);

const AyurvedicEducation: React.FC = () => {
    const educationFeatures = [
        {
            title: "AI Chatbooks",
            description: "Engage with classical Ayurvedic texts through interactive AI chatbooks. Search topics, get summaries, and explore shlokas from various sources. Vedita.AI acts as a mentor, facilitating discussions on Ayurvedic branches."
        },
        {
            title: "Patient Simulator",
            description: "Practice diagnosis and treatment planning with AI-generated patient cases based on classical texts. Receive feedback to refine your knowledge and skills in Ayurvedic medicine."
        }
    ];

    return (
        <section className="relative py-20 min-h-[60vh] flex items-center w-full bg-[#FDF6F4]">
            <div className="container mx-auto px-4 md:px-6">
                <ScrollReveal>
                    <div className="flex justify-center">
                        <div className="bg-white rounded-3xl p-8 md:p-12 shadow-lg max-w-[1200px] w-full hover:shadow-xl transition-shadow duration-300">
                            <h2 className="text-4xl md:text-5xl font-semibold text-[#2D3748] text-center mb-12">
                                Revolutionizing Ayurvedic Education
                            </h2>
                            <div className="flex flex-col md:flex-row gap-8 md:gap-0">
                                {educationFeatures.map((feature, index) => (
                                    <FeatureItem
                                        key={index}
                                        title={feature.title}
                                        description={feature.description}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </ScrollReveal>
            </div>
        </section>
    );
};

export default AyurvedicEducation;
