import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle, CardDescription, CardFooter } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { Badge } from "@/components/ui/badge";
import { format } from "date-fns";
import { useDoctorDetails } from "./useDoctorDetails";
import AdminLayout from "@/layouts/Admin/AdminLayout";
import withLayout from "@/hoc/withLayout";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { FileText, ArrowLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useState } from "react";
import { Skeleton } from "@/components/ui/skeleton";

function DoctorDetailsPage() {
  const { doctor, isLoading, handleStatusUpdate } = useDoctorDetails();
  const [showApproveDialog, setShowApproveDialog] = useState(false);
  const [showRejectDialog, setShowRejectDialog] = useState(false);
  const navigate = useNavigate();

  if (isLoading) {
    return (
      <div className="space-y-4">
        <Skeleton className="h-20 w-20 rounded-full" />
        <Skeleton className="h-6 w-1/2" />
        <Skeleton className="h-6 w-1/3" />
        <Skeleton className="h-6 w-2/3" />
      </div>
    );
  }

  const renderValue = (value: any) => {
    if (value === undefined || value === null || value === "") {
      return "-";
    }
    return value;
  };

  const formatDate = (date: string | undefined) => {
    if (!date) return "-";
    return format(new Date(date), "PPP");
  };

  return (
    <div className="container mx-auto px-2 sm:px-4 pt-6 pb-6">
      <Button 
        variant="ghost" 
        className="mb-4"
        onClick={() => navigate(-1)}
      >
        <ArrowLeft className="mr-2 h-4 w-4" />
        Back
      </Button>
      <Card className="w-full">
        <CardHeader className="flex flex-col sm:flex-row items-start sm:items-center justify-between space-y-2 sm:space-y-0 border-b pb-4">
          <div className="flex items-center gap-4">
            <div className="relative">
              <Avatar className="h-20 w-20">
                <AvatarImage src={doctor?.avatar} alt={doctor?.name} />
                <AvatarFallback>{doctor?.name?.charAt(0)}</AvatarFallback>
              </Avatar>
            </div>
            <div className="text-center sm:text-left">
              <CardTitle className="text-2xl flex items-center gap-2">
                {renderValue(doctor?.name)}
                <Badge variant={doctor?.isApproved ? "default" : "destructive"}>
                  {doctor?.isApproved ? "Approved" : "Not Approved"}
                </Badge>
              </CardTitle>
              <CardDescription className="text-lg">
                {renderValue(doctor?.specialization)}
              </CardDescription>
              <div className="flex flex-wrap gap-2 mt-2">
                {doctor?.experience && (
                  <Badge variant="secondary">{doctor.experience} years Experience</Badge>
                )}
                <Badge variant="outline">{renderValue(doctor?.email)}</Badge>
              </div>
            </div>
          </div>
        </CardHeader>

        <CardContent className="space-y-8 pt-6">
          {/* Contact & Professional Info */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="space-y-6">
              <div>
                <h3 className="text-lg font-semibold mb-4">Contact Information</h3>
                <div className="space-y-4">
                  <div>
                    <Label>Phone</Label>
                    <p className="text-sm text-muted-foreground">{renderValue(doctor?.countryCode)} {renderValue(doctor?.mobile)}</p>
                  </div>
                  <div>
                    <Label>Role</Label>
                    <p className="text-sm text-muted-foreground">{renderValue(doctor?.role)}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="space-y-6">
              <div>
                <h3 className="text-lg font-semibold mb-4">Professional Information</h3>
                <div className="space-y-4">
                  <div>
                    <Label>Specialization</Label>
                    <p className="text-sm text-muted-foreground">{renderValue(doctor?.specialization)}</p>
                  </div>
                  <div>
                    <Label>Experience</Label>
                    <p className="text-sm text-muted-foreground">{renderValue(doctor?.experience)} years</p>
                  </div>
                  <div>
                    <Label>Registration Date</Label>
                    <p className="text-sm text-muted-foreground">{formatDate(doctor?.createdAt)}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* About Section */}
          {doctor?.about && (
            <div>
              <h3 className="text-lg font-semibold mb-4">About</h3>
              <p className="text-sm text-muted-foreground whitespace-pre-wrap">{renderValue(doctor.about)}</p>
            </div>
          )}

          {/* Education */}
          {doctor?.education && doctor.education.length > 0 && (
            <div>
              <h3 className="text-lg font-semibold mb-4">Education</h3>
              <ul className="list-disc list-inside space-y-2">
                {doctor.education.map((edu, index) => (
                  <li key={index} className="text-sm text-muted-foreground">{renderValue(edu)}</li>
                ))}
              </ul>
            </div>
          )}

          {/* Clinic Details */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Clinic Details</h3>
            <div className="space-y-4">
              <div>
                <Label>Name</Label>
                <p className="text-sm text-muted-foreground">{renderValue(doctor?.clinicDetails?.name)}</p>
              </div>
              <div>
                <Label>Address</Label>
                <p className="text-sm text-muted-foreground">{renderValue(doctor?.clinicDetails?.address)}</p>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                <div>
                  <Label>City</Label>
                  <p className="text-sm text-muted-foreground">{renderValue(doctor?.clinicDetails?.city)}</p>
                </div>
                <div>
                  <Label>State</Label>
                  <p className="text-sm text-muted-foreground">{renderValue(doctor?.clinicDetails?.state)}</p>
                </div>
                <div>
                  <Label>Pincode</Label>
                  <p className="text-sm text-muted-foreground">{renderValue(doctor?.clinicDetails?.pincode)}</p>
                </div>
              </div>
            </div>
          </div>

          {/* Billing Details */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Billing Details</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              <div>
                <Label>Bank Name</Label>
                <p className="text-sm text-muted-foreground">{renderValue(doctor?.billingDetails?.bankName)}</p>
              </div>
              <div>
                <Label>Account Number</Label>
                <p className="text-sm text-muted-foreground">{renderValue(doctor?.billingDetails?.accountNo)}</p>
              </div>
              <div>
                <Label>Branch</Label>
                <p className="text-sm text-muted-foreground">{renderValue(doctor?.billingDetails?.branchCode)}</p>
              </div>
              <div>
                <Label>UPI ID</Label>
                <p className="text-sm text-muted-foreground">{renderValue(doctor?.billingDetails?.upiId)}</p>
              </div>
              <div>
                <Label>IFSC Code</Label>
                <p className="text-sm text-muted-foreground">{renderValue(doctor?.billingDetails?.ifscCode)}</p>
              </div>
            </div>
          </div>

          {/* Documents */}
          {doctor?.documents && doctor.documents.length > 0 && (
            <div>
              <h3 className="text-lg font-semibold mb-4">Documents</h3>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                {doctor.documents.map((doc, index) => (
                  <div key={index} className="flex flex-col items-center justify-between p-3 border rounded hover:border-primary/50 transition-colors">
                    {doc.url?.endsWith('.pdf') ? (
                      <div className="w-full h-48 flex items-center justify-center bg-muted/20">
                        <a
                          href={doc.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex flex-col items-center gap-2 text-muted-foreground hover:text-primary transition-colors"
                        >
                          <FileText className="h-16 w-16" />
                          <span className="text-sm">Click to view PDF</span>
                        </a>
                      </div>
                    ) : (
                      <a
                        href={doc.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="w-full h-48 block hover:opacity-90 transition-opacity"
                      >
                        <img
                          src={doc.url}
                          alt={`Document ${index + 1}`}
                          className="w-full h-full object-cover rounded"
                        />
                      </a>
                    )}
                    <div className="flex justify-between w-full mt-2">
                      <span className="truncate">Document {index + 1}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </CardContent>

        <CardFooter className="flex justify-end gap-4 border-t pt-6">
          {!doctor?.isApproved ? (
            <>
              <Dialog open={showApproveDialog} onOpenChange={setShowApproveDialog}>
                <DialogTrigger asChild>
                  <Button
                    variant="default"
                    className="bg-green-600 hover:bg-green-700 text-white"
                  >
                    Approve
                  </Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Approve Doctor</DialogTitle>
                    <DialogDescription>
                      Are you sure you want to approve Dr. {doctor?.name}? This will allow them to start accepting consultations.
                    </DialogDescription>
                  </DialogHeader>
                  <DialogFooter>
                    <Button variant="outline" onClick={() => setShowApproveDialog(false)}>
                      Cancel
                    </Button>
                    <Button
                      variant="default"
                      className="bg-green-600 hover:bg-green-700 text-white"
                      onClick={() => {
                        handleStatusUpdate(true);
                        setShowApproveDialog(false);
                      }}
                    >
                      Confirm Approval
                    </Button>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
            </>
          ) : (
            <>
              <Dialog open={showRejectDialog} onOpenChange={setShowRejectDialog}>
                <DialogTrigger asChild>
                  <Button variant="destructive">
                    Reject
                  </Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Reject Doctor</DialogTitle>
                    <DialogDescription>
                      Are you sure you want to reject Dr. {doctor?.name}? This will prevent them from accepting any new consultations.
                    </DialogDescription>
                  </DialogHeader>
                  <DialogFooter>
                    <Button variant="outline" onClick={() => setShowRejectDialog(false)}>
                      Cancel
                    </Button>
                    <Button
                      variant="destructive"
                      onClick={() => {
                        handleStatusUpdate(false);
                        setShowRejectDialog(false);
                      }}
                    >
                      Confirm Rejection
                    </Button>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
            </>
          )}
        </CardFooter>
      </Card>
    </div>
  );
}

export default withLayout(AdminLayout)(DoctorDetailsPage);