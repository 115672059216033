import { Button } from "@/components/ui/button";
import { LogOut, Menu, User } from "lucide-react";
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from '@/components/ui/dropdown-menu';
import { useAuth } from "@/contexts/AuthContext";
import { useState } from "react";
import { LogoutModal } from "@/components/LogoutModal";
import { Link } from "react-router-dom";

interface AppBarProps {
  toggleSidebar: () => void;
}

export function AppBar({ toggleSidebar }: AppBarProps) {
  const { logout } = useAuth();
  const [isLogoutModalOpen, setLogoutModalOpen] = useState(false);

  const handleLogout = () => {
    setLogoutModalOpen(true);
  };

  const handleConfirmLogout = () => {
    logout();
    setLogoutModalOpen(false);
  };

  const handleCloseModal = () => {
    setLogoutModalOpen(false);
  };

  return (
    <header className="bg-[var(--background)] border-[var(--primary-hover)] border-b p-4 flex justify-between items-center">
      <div className="flex items-center">
        <Button onClick={toggleSidebar} className="mr-4 hover:bg-[var(--primary)] hover:text-[var(--secondary-text)]  text-[var(--primary-hover)]" variant="ghost" size="icon">
          <Menu className="h-6 w-6 " />
        </Button>
        
        <div className="flex items-center">
          <h2 className="text-2xl font-semibold text-[var(--primary-hover)]">Vedita AI</h2>
          <span className="text-[0.6rem] text-[var(--primary-hover)] ml-1 mt-1">Beta</span>
        </div>
      </div>
      <div className="flex items-center space-x-4">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
          <Button  className=" hover:bg-[var(--primary)] hover:text-[var(--secondary-text)]  text-[var(--primary-hover)]" variant="ghost" size="icon">
          <User className="h-6 w-6 " />
        </Button>
           
          </DropdownMenuTrigger>
          <DropdownMenuContent className="bg-[var(--background)]">
            <DropdownMenuItem className="hover:bg-[var(--primary)] text-[var(--primary-hover)] hover:text-white focus:bg-[var(--primary)] focus:text-white">
              <User className="mr-2 h-4 w-4 " />
              <Link to={"/doctor/profile"}>Profile</Link>

            </DropdownMenuItem>
            <DropdownMenuItem onSelect={handleLogout} className="hover:bg-[var(--primary)] hover:text-[var(--secondary-text)] focus:bg-[var(--primary)] focus:text-white text-[var(--primary-hover)]">
              <LogOut className="mr-2 h-4 w-4  " />
              <span >Logout</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <LogoutModal
        isOpen={isLogoutModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleConfirmLogout}
      />
    </header>
  );
}