import React from 'react';
import ScrollReveal from '../ScrollReveal/ScrollReveal';
import { FEATURES_IMAGE_URL } from '../../constants';
import { Link } from 'react-router-dom';
import { Button } from '../ui/button';

interface FeatureCardProps {
    title: string;
    description: string;
}

const FeatureCard: React.FC<FeatureCardProps> = ({ title, description }) => (
    <div className="bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300 h-[200px] sm:h-[220px] md:h-[240px]">
        <div className="p-6 md:p-8 h-full flex flex-col">
            <h3 className="text-xl md:text-2xl font-semibold text-[#2D3748] mb-4">{title}</h3>
            <p className="text-base md:text-lg text-gray-600 leading-relaxed">{description}</p>
        </div>
    </div>
);

const VeditaFeatures: React.FC = () => {
    const features = [
        {
            title: "AI-Enabled EMR System",
            description: "Speech-to-text note-taking, AI diagnostics with insights from classical texts, and automated treatment plans"
        },
        {
            title: "Analytics Labs",
            description: "Order tests for Prakriti, Vikriti, and lifestyle assessments, receive comprehensive reports"
        },
        {
            title: "AI Consultation Tool",
            description: "Discuss cases with AI support during diagnosis and treatment planning"
        }
    ];

    return (
        <section className="relative py-16 md:py-20 w-full">
            {/* Background image with overlay */}
            <div
                className="absolute inset-0 z-0"
                style={{
                    backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0.9), rgba(200, 220, 255, 0.85)), url(${FEATURES_IMAGE_URL})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            />
            <div className="container mx-auto px-4 md:px-6 relative z-10">
                <div className="text-center mb-12 md:mb-16">
                    <h2 className="text-3xl md:text-4xl lg:text-5xl font-semibold text-[#2D3748] mb-4">
                        Vedita For Docs: AI Assistant for Ayurvedic Excellence
                    </h2>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-7xl mx-auto">
                    {features.map((feature, index) => (
                        <div key={index}>
                            <ScrollReveal>
                                <FeatureCard
                                    title={feature.title}
                                    description={feature.description}
                                />
                                {index === 0 && (
                                    <div className="hidden md:block mt-8 text-left">
                                        <Link to="/register?role=doctor">
                                            <Button  variant="orange" className=" px-6 md:px-5 py-3  font-['DM_Sans']">
                                                Sign Up
                                            </Button>
                                        </Link>
                                    </div>
                                )}
                            </ScrollReveal>
                        </div>
                    ))}
                </div>
                <div className="md:hidden mt-8 text-center">
                    <Link to="/register?role=doctor">
                        <button className="bg-[#FF9F6A] text-white px-6 py-3 rounded-full font-['DM_Sans'] hover:bg-[#FF8A4D] transition-colors text-sm">
                            Sign Up
                        </button>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default VeditaFeatures;
