import React from 'react';
import { BookOpen } from 'lucide-react';
import { Book } from '@/types/book';

interface BookCardProps {
    book: Book;
    onClick?: (bookId: string) => void;
    index: number;
}

export function BookCard({ book, onClick, index }: BookCardProps) {
    const getBookPlaceholderBg = (index: number) => {
        const colors = [
            'bg-gradient-to-br from-blue-100 to-blue-200',
            'bg-gradient-to-br from-emerald-100 to-emerald-200',
            'bg-gradient-to-br from-orange-100 to-orange-200',
            'bg-gradient-to-br from-rose-100 to-rose-200'
        ];
        return colors[index % colors.length];
    };

    return (
        <div
            className="group relative cursor-pointer bg-card shadow-sm transition-shadow hover:shadow-md"
            onClick={() => onClick?.(book.bookId)}
        >
            <div className="flex sm:block">
                <div className="w-24 sm:w-full">
                    <div className="aspect-[2/3] overflow-hidden">
                        {book.image ? (
                            <img
                                src={book.image}
                                alt={book.title}
                                className="h-full w-full object-cover transition duration-300 group-hover:scale-105"
                            />
                        ) : (
                            <div className={`flex h-full w-full items-center justify-center ${getBookPlaceholderBg(index)}`}>
                                <BookOpen className="h-6 w-6 sm:h-8 sm:w-8 text-muted-foreground" />
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex-1 sm:block">
                    <div className="h-full p-3 sm:p-4">
                        <div className="flex h-full flex-col">
                            <div>
                                <h3 className="font-semibold text-base sm:text-lg text-foreground line-clamp-2 sm:line-clamp-1">
                                    {book.title}
                                </h3>
                                <p className="mt-1 text-sm text-muted-foreground line-clamp-1">{book.author}</p>
                            </div>
                            <div className="mt-auto pt-2">
                                <div className="text-sm text-muted-foreground">{book.type}</div>
                                <div className="mt-1">
                                    {book.price === 0 ? (
                                        <span className="text-sm font-medium text-success">Free</span>
                                    ) : book.discountedPrice < book.price ? (
                                        <div>
                                            <span className="text-sm text-muted-foreground line-through">₹{book.price}</span>
                                            <span className="ml-2 text-sm font-medium text-success">₹{book.discountedPrice}</span>
                                            <span className="ml-2 rounded-full bg-success/10 px-3 py-0.5 text-sm font-medium text-success">
                                                {Math.round(((book.price - book.discountedPrice) / book.price) * 100)}% OFF
                                            </span>
                                        </div>
                                    ) : (
                                        <span className="text-sm font-medium text-foreground">₹{book.price}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
} 