import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

interface BarChartProps {
  categories: (string | string[])[];
  series: { data: number[] }[];
  title?: string;
  colors?: string[];
}

const BarChart: React.FC<BarChartProps> = ({
  categories,
  series,
  title,
  colors,
}) => {
  const defaultColors = [
    "#ff5733",
    "#f86d35",
    "#f07f38",
    "#e78f3a",
    "#de9e3d",
    "#d3ac40",
    "#c8b943",
    "#bac546",
    "#abd149",
    "#99dd4c",
    "#83e950",
    "#66f453",
    "#33ff57",
  ];

  const chartOptions: ApexOptions = {
    chart: {
      height: 350,
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    colors: colors || defaultColors,
    plotOptions: {
      bar: {
        columnWidth: "45%",
        distributed: true,
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    yaxis: {
      labels: {
        formatter: function (value: number) {
          return `${value}%`;
        },
        style: {
          fontSize: "12px",
        },
      },
    },
    xaxis: {
      categories: categories,
      labels: {
        style: {
          fontSize: "12px",
        },
      },
    },
    title: {
      text: title,
      align: "center",
      style: {
        fontSize: "18px",
        fontWeight: "bold",
        color: "#333",
      },
    },
  };

  return (
    <div className="bar-chart">
      <ReactApexChart
        options={chartOptions}
        series={series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default BarChart;
