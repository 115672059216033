import React from 'react';
import ScrollReveal from '../ScrollReveal/ScrollReveal';
import { AYURVEDIC_HEALTH_IMAGE_URL } from '../../constants';
interface JourneyStepProps {
    number: number;
    title: string;
    description: string;
}

const JourneyStep: React.FC<JourneyStepProps> = ({ number, title, description }) => (
    <div className="flex-1 text-center px-4 md:px-8 relative">
        <div className="relative">
            <div className="inline-flex items-center justify-center w-12 h-12 rounded-lg bg-white text-[#2D3748] font-semibold text-xl relative z-10 border border-[#C0C0C0]">
                {number}
            </div>
            {/* Vertical line below number */}
            <div className="hidden md:block absolute left-1/2 top-[2.95rem] w-[2px] h-12 bg-[#C0C0C0] -translate-x-1/2"></div>
        </div>
        <div className="mt-16">
            <h3 className="text-2xl font-semibold text-[#2D3748] mb-4">{title}</h3>
            <p className="text-gray-600 text-lg leading-relaxed">{description}</p>
        </div>
    </div>
);

const HealthJourney: React.FC = () => {
    const journeySteps = [
        {
            number: 1,
            title: "Quantified Ayurveda",
            description: "Get measurable insights into your wellness with understandable metrics for Prakriti, Vikriti, and other health parameters."
        },
        {
            number: 2,
            title: "Personalized Diet Plans",
            description: "Receive tailored diet charts based on your treatment plan, with seasonal and festive dietary suggestions."
        },
        {
            number: 3,
            title: "AI Dietitian Assistance",
            description: "Enjoy personalized meal planning and guidance aligned with Ayurvedic principles and your unique needs."
        }
    ];


    return (
        <section className="relative py-20 min-h-[60vh] w-full">
            {/* Background image with overlay */}
            <div 
                className="absolute inset-0 z-0"
                style={{
                    backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0.9), rgba(200, 220, 255, 0.85)), url(${AYURVEDIC_HEALTH_IMAGE_URL})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    width: '100%'
                }}
            />
            <div className="container mx-auto px-4 md:px-6 relative z-10">
                <ScrollReveal>
                    <h2 className="text-4xl md:text-5xl font-semibold text-[#2D3748] text-center mb-16">
                        Personalized Ayurvedic Health Journey for Patients
                    </h2>
                    <div className="flex flex-col md:flex-row gap-8 md:gap-12 justify-between items-start relative">
                        {/* Horizontal connecting line */}
                        <div className="hidden md:block absolute top-6 left-0 right-0 h-[2px] bg-[#C0C0C0] z-0"></div>
                        
                        {journeySteps.map((step) => (
                            <JourneyStep
                                key={step.number}
                                number={step.number}
                                title={step.title}
                                description={step.description}
                            />
                        ))}
                    </div>
                </ScrollReveal>
            </div>
        </section>
    );
};

export default HealthJourney;
