import { useState } from 'react';
import { useAuth } from '@/contexts/AuthContext';
import axios from 'axios';
import { validateEmail, validatePassword, handleApiErrors } from '@/utils/toolkit';
import { useApi } from '@/hooks/useApi';
import { useMutation } from '@tanstack/react-query';

interface LoginCredentials {
  email: string;
  password: string;
}

const useLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({ email: '', password: '' });
  const { setAuthInfo } = useAuth();
  const api = useApi();
  const [showPassword, setShowPassword] = useState(false);

  const validateInputs = () => {
    const emailValidation = validateEmail(email);
    const passwordValidation = validatePassword(password);

    setErrors({
      email: emailValidation.error ? emailValidation.errorText : '',
      password: passwordValidation.error ? passwordValidation.errorText : '',
    });

    return !emailValidation.error && !passwordValidation.error;
  };

  const loginMutation = useMutation({
    mutationFn: async (credentials: LoginCredentials) => {
      const response = await api.post('/auth/login', credentials);
      return response.data;
    },
    onSuccess: (data) => {
      const { token, refreshToken, data: userData } = data;
      setAuthInfo(token, refreshToken, userData);
    },
    onError: (error) => {
      handleApiErrors(error);
      console.log(error);
      if (axios.isAxiosError(error) && error.response) {
        const errorData = error.response.data;
        setErrors({
          email: errorData.email || '',
          password: errorData.password || '',
        });
      }
    }
  });

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateInputs()) {
      return;
    }

    loginMutation.mutate({ email, password });
  };

  const togglePassword = () => setShowPassword(prev => !prev);

  return {
    email,
    setEmail,
    password,
    setPassword,
    errors,
    handleLogin,
    loading: loginMutation.isPending,
    showPassword,
    togglePassword
  };
};

export default useLogin;