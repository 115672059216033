import { usePatientService } from '@/services/Patient/patientService';
import { DashboardData } from '@/types/patient/dashboard';

export function useDashboard() {
  const { getDashboardData } = usePatientService();
  
  const { data, isLoading, isError } = getDashboardData;

  return {
    data: data?.data as DashboardData,
    isLoading,
    isError,
  };
}
