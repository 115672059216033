import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { CategoryDetailsProps } from '@/types/assessment';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { useMediaQuery } from '@/hooks/useMediaQuery';

export const CategoryDetails: React.FC<CategoryDetailsProps> = ({
  title,
  description,
  isActive,
  section,
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  const content = section ? (
    <>
      <CardHeader className="space-y-1">
        <CardTitle className="text-xl font-['DM_Sans'] text-[hsl(var(--foreground))]">{section.name}</CardTitle>
      </CardHeader>
      <CardContent>
        <div 
          className="prose prose-sm max-w-none text-[hsl(var(--muted-foreground))] font-['DM_Sans']"
          dangerouslySetInnerHTML={{
            __html: section.description || "",
          }}
        />
      </CardContent>
    </>
  ) : (
    <>
      <CardHeader className="space-y-1">
        <CardTitle className="text-xl font-['DM_Sans'] text-[hsl(var(--foreground))]">{title}</CardTitle>
      </CardHeader>
      <CardContent>
        <div 
          className="prose prose-sm max-w-none text-[hsl(var(--muted-foreground))] font-['DM_Sans']"
          dangerouslySetInnerHTML={{
            __html: description || "",
          }}
        />
      </CardContent>
    </>
  );

  if (isMobile) {
    return (
      <Card className={`transition-all`}>
        <CardContent className="p-4">
          <Accordion type="single" collapsible className="w-full">
            <AccordionItem value="description" className="border-none">
              <AccordionTrigger 
                className="text-base font-medium py-2 hover:no-underline text-[hsl(var(--foreground))] font-['DM_Sans']"
              >
                {section?.name}
              </AccordionTrigger>
              <AccordionContent>
                <div className="space-y-4">
                 
                  <div 
                    className="prose prose-sm max-w-none text-[hsl(var(--muted-foreground))] font-['DM_Sans']"
                    dangerouslySetInnerHTML={{
                      __html: section ? section.description || "" : description || "",
                    }}
                  />
                </div>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card 
      className={`h-full transition-all `}
    >
      {content}
    </Card>
  );
};
