import React from 'react';
import { Card, CardContent, CardHeader, CardTitle, CardFooter } from "@/components/ui/card";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import { QuestionsProps, QuestionAnswer } from '@/types/assessment';
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { ChevronLeft, ChevronRight } from "lucide-react";


export const Questions: React.FC<QuestionsProps> = ({
  question,
  answers,
  onAnswer,
  onPrevious,
  onNext,
  isFirstQuestion,
  isLastQuestion,
  completeAssessment,
  totalQuestions,
  currentQuestionNumber
}) => {
  const currentAnswer = answers[question._id] || question.selectedAnswer;


  return (
    <Card className="h-full flex flex-col">
      <CardHeader>
        <div className="flex justify-between items-center">
          <CardTitle>{question.text}</CardTitle>
        </div>
        <div className="text-sm text-muted-foreground mt-2">
          Question {currentQuestionNumber} of {totalQuestions}
        </div>
      </CardHeader>
      <CardContent className="flex-grow">
        <RadioGroup
          value={currentAnswer || ""}

          className="space-y-3"
        >

          {question.answers.map((answer: QuestionAnswer) => (
            <div
              key={answer.id}
              onClick={() => onAnswer(question._id, answer.id)}
              className={cn(
                "flex items-center space-x-3 p-4 rounded-lg border transition-colors",
                currentAnswer?.trim().toLocaleLowerCase() == answer.id.trim().toLocaleLowerCase()
                  ? "bg-[var(--background)] border-[var(--primary)]"
                  : "hover:bg-muted/50 border-transparent"
              )}
            >
              <RadioGroupItem value={answer.id} id={`answer-${answer.id}`} className={currentAnswer?.trim().toLocaleLowerCase() == answer.id.trim().toLocaleLowerCase() ? "text-[var(--primary)]" : "text-black"} />
              {/* <img
                src="https://www.7dliving.com/images/landingpage/Company-Square-Logo.png"
                alt={answer.text}
                className="w-16 h-16 object-cover rounded-md"
              /> */}
              <Label
                htmlFor={`answer-${answer.id}`}
                className="flex-grow cursor-pointer"
              >
                {answer.text}
              </Label>

            </div>
          ))}
        </RadioGroup>
      </CardContent>
      <CardFooter className="flex justify-between pt-6">
        <Button
          variant="outline"
          onClick={onPrevious}
          disabled={isFirstQuestion}
        >
          <ChevronLeft className="h-4 w-4 mr-2" />
          Previous
        </Button>
        {
          isLastQuestion ? <Button
            variant="orange"
            onClick={completeAssessment}

          >
            Complete

          </Button> : <Button
            variant="orange"
            onClick={onNext}
            disabled={!currentAnswer}
          >Next
            <ChevronRight className="h-4 w-4 ml-2" />
          </Button>
        }

      </CardFooter>
    </Card>
  );
};
