import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Search, BookOpen, X } from 'lucide-react';
import { useBooks, useSearchBooks } from '@/services/bookService';
import { Book } from '@/types/book';
import { useDebounce } from '@/hooks/useDebounce';
import { useAuth } from '@/contexts/AuthContext';
import withLayout from '@/hoc/withLayout';
import DoctorLayout from '@/layouts/Doctor/DoctorLayout';
import PatientLayout from '@/layouts/Patient/PatientLayout';
import GeneralUserLayout from '@/layouts/GeneralUser/GeneralUserLayout';
import { BookCard } from '@/components/book/BookCard';
import { Skeleton } from '@/components/ui/skeleton';

export function LibraryPage() {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearch = useDebounce(searchTerm, 300);

    const { data: allBooks, isLoading: isLoadingBooks } = useBooks({
        page: 1,
        perPage: 50
    });

    const { data: searchResults, isLoading: isSearching } = useSearchBooks(debouncedSearch);

    const books = debouncedSearch ? searchResults?.items : allBooks?.items;
    const isLoading = isSearching || isLoadingBooks;

    const handleBookClick = (bookId: string) => {
        navigate(`/books/${bookId}`);
    };

    return (
        <div className="min-h-screen bg-background">
            <div className="container mx-auto px-4 py-6 sm:py-8">
                {/* Header Section */}
                <div className="mb-6 sm:mb-8 space-y-4 sm:space-y-6">
                    <div className="flex flex-col gap-4 sm:flex-row sm:items-center sm:justify-between">
                        <div>
                            <h1 className="text-2xl sm:text-4xl font-bold text-foreground">Vedita Books</h1>
                            <p className="mt-1 sm:mt-2 text-base sm:text-lg text-muted-foreground">Discover ancient wisdom in modern format</p>
                        </div>
                        <div className="relative w-full sm:w-72">
                            <Search className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-muted-foreground" />
                            <Input
                                placeholder="Search books..."
                                className="w-full pl-10 pr-10"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            {searchTerm && (
                                <button
                                    onClick={() => setSearchTerm('')}
                                    className="absolute right-3 top-1/2 -translate-y-1/2 text-muted-foreground hover:text-foreground"
                                >
                                    <X className="h-4 w-4" />
                                </button>
                            )}
                        </div>
                    </div>

                    {debouncedSearch && (
                        <div className="text-sm text-muted-foreground">
                            Found {books?.length || 0} results for "{debouncedSearch}"
                        </div>
                    )}
                </div>

                {/* Books Grid */}
                {isLoading ? (
                    <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-3 sm:gap-4">
                        {[...Array(12)].map((_, index) => (
                            <div key={index} className="flex flex-col space-y-3 rounded-lg border p-4">
                                <Skeleton variant="title" className="h-[180px] w-full rounded-md" />
                                <div className="space-y-2">
                                    <Skeleton variant="content" className="h-4 w-3/4" />
                                    <Skeleton variant="content" className="h-3 w-1/2" />
                                </div>
                            </div>
                        ))}
                    </div>
                ) : books?.length === 0 ? (
                    <div className="flex h-[400px] flex-col items-center justify-center gap-4">
                        <div className="text-center">
                            <p className="text-lg font-medium text-foreground">No books found</p>
                            <p className="mt-1 text-muted-foreground">
                                {debouncedSearch
                                    ? `No results found for "${debouncedSearch}". Try a different search term.`
                                    : 'No books available at the moment.'}
                            </p>
                        </div>
                        {debouncedSearch && (
                            <Button variant="outline" onClick={() => setSearchTerm('')}>
                                Clear Search
                            </Button>
                        )}
                    </div>
                ) : (
                    <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-3 sm:gap-4">
                        {books?.map((book: Book, index: number) => (
                            <BookCard
                                key={book.bookId}
                                book={book}
                                onClick={handleBookClick}
                                index={index}
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}

// Export the component with conditional layout
export function Library() {
    const { user } = useAuth();

    if (user?.role === 'DOCTOR') {
        return withLayout(DoctorLayout)(LibraryPage)({});
    }

    if (user?.role === 'PATIENT') {
        return withLayout(PatientLayout)(LibraryPage)({});
    }

    return withLayout(GeneralUserLayout)(LibraryPage)({});

}