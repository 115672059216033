import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "@/hooks/useApi";
// import { TestResultResponse } from "@/types/testResults";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useState } from "react";



export function useTestResults() {
  const api = useApi();
  const [isDownloading, setIsDownloading] = useState(false);
  const { assessmentId, testType, gender } = useParams<{
    assessmentId: string;
    testType: string;
    gender: string;
  }>();

  const { data: testResults, isLoading } = useQuery<any>({
    queryKey: ["testResults", assessmentId],
    queryFn: async () => {
      const response = await api.post(
        `/assessments/report/${testType}/${assessmentId}`,
        {
          gender: gender,
          vikritiType: testType,
        }
      );

      return response.data;
    },
  });

  const downloadPDF = async () => {
    const element = document.getElementById("test-results-container");
    if (!element) return;

    try {
      setIsDownloading(true);

      // Scroll to top to ensure the element is fully in view
      window.scrollTo(0, 0);

      // Capture the element as a canvas
      const canvas = await html2canvas(element, {
        allowTaint: true,
        useCORS: true,
        logging: false,
        background: "#ffffff", // Ensure white background
      });

      const imageData = canvas.toDataURL("image/png");

      // Initialize jsPDF
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      // Calculate dimensions for scaling
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const aspectRatio = canvas.width / canvas.height;

      let imgWidth = pageWidth;
      let imgHeight = imgWidth / aspectRatio;

      // Adjust dimensions to fit within the page
      if (imgHeight > pageHeight) {
        imgHeight = pageHeight;
        imgWidth = imgHeight * aspectRatio;
      }

      // Center the image on the page
      const xOffset = (pageWidth - imgWidth) / 2;
      const yOffset = (pageHeight - imgHeight) / 2;

      // Add image to PDF
      pdf.addImage(imageData, "PNG", xOffset, yOffset, imgWidth, imgHeight);

      // Save the PDF
      const fileName = `${testType || "test"}-results.pdf`;
      pdf.save(fileName);
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setIsDownloading(false);
    }
  };

  return {
    testResults,
    isLoading,
    isDownloading,
    downloadPDF,
  };
}
