import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { ChevronsUpDown, X } from "lucide-react";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useNewTestForm } from "./useNewTestForm";
import { getCountryShortName } from "@/utils/countryUtils";
import {NewTestFormProps} from "@/types/doctor/testTypes";
import { useEffect, useRef } from "react";

const NewTestForm = ({ onSubmit, isLoading, testTypes, isLoadingTestTypes }: NewTestFormProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const {
    patientName,
    setPatientName,
    patientMobile,
    setPatientMobile,
    phoneCountry,
    setPhoneCountry,
    patientEmail,
    setPatientEmail,
    selectedTests,
    open,
    setOpen,
    searchQuery,
    setSearchQuery,
    errors,
    filteredTests,
    handleSelect,
    handleRemoveTest,
    handleSubmit,
    patientGender,
    setPatientGender,
  } = useNewTestForm({ onSubmit, testTypes });

  useEffect(() => {
    const handleResize = () => {
      // Close dropdown on resize to prevent positioning issues
      setOpen(false);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [open]);

  const defaultCountry = getCountryShortName(phoneCountry) || 'in';
  
  return (
    <form onSubmit={handleSubmit} className="space-y-4 mt-4">
      <div>
        <label
          htmlFor="patientName"
          className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1"
        >
          Patient Name
        </label>
        <Input
          id="patientName"
          placeholder="Enter patient name"
          value={patientName}
          onChange={(e) => setPatientName(e.target.value)}
          className={errors.patientName ? "border-red-500" : ""}
        />
        {errors.patientName && (
          <p className="text-red-500 text-sm mt-1">{errors.patientName}</p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
          Gender 
        </label>
        <div className="flex space-x-4">
  {['MALE', 'FEMALE', 'OTHER'].map((gender) => (
    <label key={gender} className="flex items-center space-x-2">
      <input
        type="radio"
        name="gender"
        value={gender}
        checked={patientGender === gender}
        onChange={(e) => setPatientGender(e.target.value as 'MALE' | 'FEMALE' | 'OTHER')}
        className={`
          appearance-none w-4 h-4 rounded-full border 
          checked:border-4 transition-all duration-200
          focus:ring-offset-0 focus:ring-0
          ${patientGender === gender 
            ? 'border-[var(--primary)] checked:border-[var(--primary)] checked:bg-white' 
            : 'border-gray-300 checked:border-[var(--primary)]'
          }
        `}
      />
      <span className="text-sm">{gender}</span>
    </label>
  ))}
</div>
        {errors.patientGender && (
          <p className="text-red-500 text-sm mt-1">{errors.patientGender}</p>
        )}
      </div>

      <div>
        <label
          className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1"
        >
          Mobile Number
        </label>
        <div className="phone-input-container">
          <PhoneInput
            country={defaultCountry}
            value={patientMobile}
            onChange={(phone, data: any) => {
              setPatientMobile(phone);
              if (data?.dialCode) {
                setPhoneCountry(`+${data.dialCode}`);
              }
            }}
            inputClass={errors.patientMobile ? 'border-red-500' : 'border-gray-300'}
            containerClass="w-full"
            buttonClass="!border-0 !bg-transparent"
            dropdownClass="!w-[280px] md:!w-[320px]"
            searchClass="!m-2"
            enableSearch={true}
            searchPlaceholder="Search country..."
          />
        </div>
        {errors.patientMobile && (
          <p className="text-red-500 text-sm mt-1">{errors.patientMobile}</p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
          Email (Optional)
        </label>
        <Input
          type="email"
          placeholder="Enter email address (optional)"
          value={patientEmail}
          onChange={(e) => setPatientEmail(e.target.value)}
          className={errors.patientEmail ? "border-red-500" : ""}
        />
        {errors.patientEmail && (
          <p className="text-red-500 text-sm mt-1">{errors.patientEmail}</p>
        )}
      </div>

      <div>
        <label
          className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1"
        >
          Tests
        </label>
        <div className="relative" ref={dropdownRef}>
          <Button
            type="button"
            onClick={() => setOpen(!open)}
            className="w-full px-3 py-2 text-left border rounded-md flex items-center justify-between bg-white dark:bg-gray-800"
            disabled={isLoadingTestTypes}
            variant="outline"
          >
            {selectedTests.length > 0 ? (
              <span>{selectedTests.length} test(s) selected</span>
            ) : (
              <span className="text-gray-500">Select tests</span>
            )}
            <ChevronsUpDown className="h-4 w-4 shrink-0 opacity-50" />
          </Button>

          {open && (
            <div className="absolute mt-1 w-full bg-white dark:bg-gray-800 border rounded-md shadow-lg z-10 max-h-[60vh] overflow-hidden flex flex-col" 
              style={{
                maxHeight: '300px',
                bottom: 'auto',
                top: '100%',
                transform: 'translateY(0)',
                // If bottom of dropdown goes below viewport, position it above the button instead
                ...(dropdownRef.current && 
                  dropdownRef.current.getBoundingClientRect().bottom + 300 > window.innerHeight && {
                    bottom: '100%',
                    top: 'auto',
                    transform: 'translateY(-8px)' // Small offset for visual spacing
                })
              }}>
              <div className="p-2 sticky top-0 bg-white dark:bg-gray-800 z-20">
                <Input
                  type="text"
                  className="w-full"
                  placeholder="Search tests..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  disabled={isLoadingTestTypes}
                />
              </div>
              <ul className="overflow-y-auto flex-1">
                {isLoadingTestTypes ? (
                  <li className="px-3 py-2 text-gray-500">Loading available tests...</li>
                ) : filteredTests.length === 0 ? (
                  <li className="px-3 py-2 text-gray-500">No tests found.</li>
                ) : (
                  filteredTests.map((test) => (
                    <li
                      key={test.value}
                      onClick={() => handleSelect(test.value)}
                      className="px-3 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer flex items-center"
                    >
                      <input
                        type="checkbox"
                        checked={selectedTests.includes(test.value)}
                        onChange={() => {}}
                        className="mr-2"
                      />
                      {test.label}
                    </li>
                  ))
                )}
              </ul>
            </div>
          )}
        </div>

        <div className="mt-2" style={{ maxHeight: '60px', overflowY: 'auto', overflowX: 'hidden' }}>
          <div className="flex flex-wrap gap-2">
            {selectedTests.map((value) => (
              <span
                key={value}
                className="inline-flex items-center gap-1 bg-gray-100 dark:bg-gray-700 px-2 py-1 rounded-md text-sm"
              >
                {testTypes.find((t) => t.value === value)?.label}
                <X
                  className="h-3 w-3 cursor-pointer hover:text-gray-700 dark:hover:text-gray-300"
                  onClick={() => handleRemoveTest(value)}
                />
              </span>
            ))}
          </div>
        </div>
        
        {errors.selectedTests && (
          <p className="text-red-500 text-sm mt-1">{errors.selectedTests}</p>
        )}
        
        <p className="text-sm text-gray-500 mt-1">
          Select one or more tests to be conducted.
        </p>
      </div>

      <div className="flex justify-end space-x-2 mt-6">
        <Button type="submit" disabled={isLoading} variant="orange">
          {isLoading ? "Ordering..." : "OrderTest"}
        </Button>
      </div>
    </form>
  );
}

// Add global styles for phone input
const phoneInputStyles = `
  
  .phone-input-container {
    position: relative;
    width: 100%;
  }
  .phone-input-container .react-tel-input {
    width: 100%;
  }
  .phone-input-container .form-control {
    width: 100% !important;
    height: 40px !important;
    padding-right: 84px !important;
  }
  .phone-input-container .form-control:focus {
    outline: none !important;
    border-color: var(--primary) !important;
  }
  .phone-input-container .form-control:focus-visible {
    outline: none !important;
    ring: 1px var(--primary) !important;
  }
  .phone-input-container .flag-dropdown {
    border: none !important;
    background: transparent !important;
  }
  .phone-input-container .flag-dropdown.open {
    background: white !important;
  }
  .phone-input-container .selected-flag {
    background: transparent !important;
  }
  .phone-input-container .country-list {
    width: 280px !important;
  }
  @media (min-width: 768px) {
    .phone-input-container .country-list {
      width: 320px !important;
    }
  }

`;

// Create a style element and inject the styles
if (typeof document !== 'undefined') {
  const styleElement = document.createElement('style');
  styleElement.textContent = phoneInputStyles;
  document.head.appendChild(styleElement);
}

export { NewTestForm };
