import React, { useEffect, useCallback, useRef } from "react";
import { useAnalysis } from "./useAnalysis";
import { PatientInfoForm } from "@/components/patient/PatientInfoForm";
import { SymptomPane } from "@/components/patient/SymptomPane";
import { HistoryPane } from "@/components/patient/HistoryPane";
import { FamilyHistoryPane } from "@/components/patient/FamilyHistoryPane";
import withLayout from "@/hoc/withLayout";
import DoctorLayout from "@/layouts/Doctor/DoctorLayout";
import { Button } from "@/components/ui/button";
import { ChatUI } from "@/components/chatbox/ChatUI";
import { MessageCircle, Save, Loader2, ChevronLeft, ChevronRight, FileSearch, User, ListChecks, ClipboardCheck, Microscope, Stethoscope, ClipboardList, RefreshCw } from "lucide-react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { BreadcrumbNav } from "@/components/ui/BreadcrumbNav";
import { SaveChangesDialog } from "@/components/ui/SaveChangesDialog";
import { dummyDehsavidPariksha, dummyDiagnosis, dummyTreatment } from "./constants";
import { MarkdownCard } from "@/components/patient/MarkdownCard";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import { ModeButton } from "@/types/global";

const Analysis: React.FC = () => {
  const {
    caseData,
    updateCaseData,
    saveChanges,
    unsavedChanges,
    isSaving,
    chatHistory,
    isLoading,
    isAITyping,
    handleAIInteraction,
    isChatOpen,
    toggleChat,
    breadcrumbItems,
    currentMode,
    handleModeChange,
    activeTab,
    handleActiveTabs,
    tabsRef,
    performAction
  } = useAnalysis();


  const patientName = `${caseData.patientDetails.firstName} ${caseData.patientDetails.lastName}`.trim();

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if ((event.ctrlKey || event.metaKey) && event.key === 's') {
      event.preventDefault();
      if (unsavedChanges && !isSaving && !isLoading) {
        saveChanges();
      }
    }
  }, [unsavedChanges, isSaving, isLoading, saveChanges]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

   const AVAILABLE_MODES: ModeButton[] = [
    { mode: 'treatment', label: '7. Treatment', icon: ClipboardList,modeDisabled:(caseData.initialAnalysis ?? '').length === 0 },
    { mode: 'diagnosis', label: '6. Diagnosis', icon: Stethoscope,modeDisabled:(caseData.initialAnalysis ?? '').length === 0 },
    { mode: 'investigation', label: '5. Investigation', icon: Microscope,modeDisabled:(caseData.initialAnalysis ?? '').length === 0 },
    { mode: 'examination', label: '4. Examination', icon: ClipboardCheck,modeDisabled:(caseData.initialAnalysis ?? '').length === 0 },
    { mode: 'initialAnalysis', label: '3. Initial Analysis', icon: FileSearch,modeDisabled:false },
    { mode: 'symptoms', label: '2. Symptoms Analysis', icon: ListChecks,modeDisabled:false },
    { mode: 'patientInfo', label: '1. Patient Info', icon: User,modeDisabled:false },
];


  return (
    <div className="flex flex-col lg:flex-row w-full h-full relative p-2 py-4 md:p-4">
      {/* AI Chat Box */}
      <div className="w-full lg:w-1/3 lg:fixed lg:left-0 lg:top-16 lg:bottom-0 lg:overflow-y-auto">
        <div className={`h-full p-2 md:p-4 ${isChatOpen ? 'fixed inset-0 z-50 bg-white' : 'hidden lg:block'}`}>
          <ChatUI
            onSendMessage={handleAIInteraction}
            performAction={performAction}
            availableActions={(currentMode === 'initialAnalysis' && (caseData.initialAnalysis ?? '').length > 0) ? [{ icon: RefreshCw, label: '', command: 'doInitialAnalysis' }] : []}
            chatHistory={chatHistory}
            isLoading={isLoading}
            isAITyping={isAITyping}
            onClose={toggleChat}
            availableModes={AVAILABLE_MODES}
            currentMode={currentMode}
            onModeChange={handleModeChange}
          />
        </div>
      </div>

      {/* Analysis Components with Tabs */}
      <div className="w-full lg:w-2/3 lg:ml-[33.333%] pb-16 md:pb-20 lg:pb-[120px] lg:p-6 py-4">
        <div className="flex justify-between items-center mb-2 md:mb-4">
          <BreadcrumbNav items={breadcrumbItems} />
        </div>

        <h1 className="text-xl md:text-2xl font-bold my-3 md:my-6 text-[hsl(var(--primary))]">
          Case Analysis: {patientName}
        </h1>

        <Tabs
          value={activeTab}
          onValueChange={handleActiveTabs}
          className="w-full space-y-2 md:space-y-4"
        >
          <div className="relative">
            <ScrollArea className="w-full h-[40px] bg-[var(--secondary)] rounded-lg relative">
              <TabsList className="inline-flex w-max gap-1 p-1 bg-[var(--secondary)] rounded-md">
                <TabsTrigger
                  ref={el => tabsRef.current['patientInfo'] = el}
                  value="patientInfo"
                  className="w-[160px] text-sm whitespace-nowrap px-3 py-1.5 text-[var(--primary-text)] hover:bg-[var(--primary-text)] data-[state=active]:bg-[var(--primary)] data-[state=active]:text-[var(--secondary-text)] data-[state=active]:shadow-sm hover:text-[var(--secondary-text)]"
                >
                  <User className="w-4 h-4 mr-2" />
                  1. Patient Info
                </TabsTrigger>
                <TabsTrigger
                  ref={el => tabsRef.current['symptoms'] = el}
                  value="symptoms"
                  className="w-[160px] md:w-auto flex-shrink-0 text-sm whitespace-nowrap px-3 py-1.5 md:py-2  text-[var(--primary-text)] hover:bg-[var(--primary-text)] data-[state=active]:bg-[var(--primary)] data-[state=active]:text-[var(--secondary-text)] data-[state=active]:shadow-sm hover:text-[var(--secondary-text)]"
                >
                  <ListChecks className="w-4 h-4 mr-2" />
                  2. Symptoms Analysis
                </TabsTrigger>
                <TabsTrigger
                  ref={el => tabsRef.current['initialAnalysis'] = el}
                  value="initialAnalysis"
                  className="w-[160px] md:w-auto flex-shrink-0 text-sm whitespace-nowrap px-3 py-1.5 md:py-2  text-[var(--primary-text)] hover:bg-[var(--primary-text)] data-[state=active]:bg-[var(--primary)] data-[state=active]:text-[var(--secondary-text)] data-[state=active]:shadow-sm hover:text-[var(--secondary-text)]"
                >
                  <FileSearch className="w-4 h-4 mr-2" />
                  3. Initial Analysis
                </TabsTrigger>
                <TabsTrigger
                  ref={el => tabsRef.current['examination'] = el}
                  value="examination"
                  disabled={(caseData.initialAnalysis ?? '').length === 0}
                  className="w-[160px] md:w-auto flex-shrink-0 text-sm whitespace-nowrap px-3 py-1.5 md:py-2  text-[var(--primary-text)] hover:bg-[var(--primary-text)] data-[state=active]:bg-[var(--primary)] data-[state=active]:text-[var(--secondary-text)] data-[state=active]:shadow-sm hover:text-[var(--secondary-text)] disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <ClipboardCheck className="w-4 h-4 mr-2" />
                  4. Examination
                </TabsTrigger>
                <TabsTrigger
                  ref={el => tabsRef.current['investigation'] = el}
                  value="investigation"
                  disabled={(caseData.initialAnalysis ?? '').length === 0}
                  className="w-[160px] md:w-auto flex-shrink-0 text-sm whitespace-nowrap px-3 py-1.5 md:py-2  text-[var(--primary-text)] hover:bg-[var(--primary-text)] data-[state=active]:bg-[var(--primary)] data-[state=active]:text-[var(--secondary-text)] data-[state=active]:shadow-sm hover:text-[var(--secondary-text)] disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <Microscope className="w-4 h-4 mr-2" />
                  5. Investigation
                </TabsTrigger>
                <TabsTrigger
                  ref={el => tabsRef.current['diagnosis'] = el}
                  value="diagnosis"
                  disabled={(caseData.initialAnalysis ?? '').length === 0}
                  className="w-[160px] md:w-auto flex-shrink-0 text-sm whitespace-nowrap px-3 py-1.5 md:py-2 text-[var(--primary-text)] hover:bg-[var(--primary-text)] data-[state=active]:bg-[var(--primary)] data-[state=active]:text-[var(--secondary-text)] data-[state=active]:shadow-sm hover:text-[var(--secondary-text)] disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <Stethoscope className="w-4 h-4 mr-2" />
                  6. Diagnosis
                </TabsTrigger>
                <TabsTrigger
                  ref={el => tabsRef.current['treatment'] = el}
                  value="treatment"
                  disabled={(caseData.initialAnalysis ?? '').length === 0}
                  className="w-[160px] md:w-auto flex-shrink-0 text-sm whitespace-nowrap px-3 py-1.5 md:py-2 text-[var(--primary-text)] hover:bg-[var(--primary-text)] data-[state=active]:bg-[var(--primary)] data-[state=active]:text-[var(--secondary-text)] data-[state=active]:shadow-sm hover:text-[var(--secondary-text)] disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <ClipboardList className="w-4 h-4 mr-2" />
                  7. Treatment
                </TabsTrigger>
              </TabsList>
              <ScrollBar orientation="horizontal" className="h-1 bg-[var(--secondary)] text-[var(--primary-text)]" />
            </ScrollArea>
          </div>

          <TabsContent value="patientInfo">
            <div className="space-y-4 md:space-y-8">
              <div>
                <PatientInfoForm
                  data={caseData.patientDetails}
                />
              </div>

            </div>
          </TabsContent>

          <TabsContent value="symptoms">
            <div className="space-y-4 md:space-y-8">
              <div>
                <SymptomPane
                  symptoms={caseData.symptoms}
                  onUpdate={(symptoms) => updateCaseData(draft => {
                    draft.symptoms = symptoms;
                  })}
                />
              </div>

              <div>
                <HistoryPane
                  history={caseData.medicalHistory ?? []}
                  onUpdate={(history) => updateCaseData(draft => {
                    draft.medicalHistory = history;
                  })}
                />
              </div>
              <div>
                <FamilyHistoryPane
                  familyHistory={caseData.familyHistory}
                  onUpdate={(familyHistory) => updateCaseData(draft => {
                    draft.familyHistory = familyHistory;
                  })}
                />
              </div>
            </div>
          </TabsContent>
          <TabsContent value="initialAnalysis">
            <div className="space-y-4 md:space-y-8">
              <div>
                <MarkdownCard
                  title="Initial Analysis"
                  markdown={caseData.initialAnalysis ?? ''}
                />
              </div>
            </div>
          </TabsContent>
          <TabsContent value="examination">
            <div className="space-y-4 md:space-y-8">
              <div>
                <MarkdownCard
                  title="Examination"
                  markdown={dummyDehsavidPariksha}
                />
              </div>
            </div>
          </TabsContent>
          <TabsContent value="investigation">
            <div className="space-y-4 md:space-y-8">
              <div>
                <MarkdownCard
                  title="Investigation"
                  markdown={''}
                />
              </div>
            </div>
          </TabsContent>

          <TabsContent value="diagnosis">
            <div className="space-y-4 md:space-y-8">
              <MarkdownCard
                title="Diagnosis"
                markdown={dummyDiagnosis}
              />

            </div>
          </TabsContent>

          <TabsContent value="treatment">
            <div className="space-y-4 md:space-y-8">
              <MarkdownCard
                title="Treatment Plan"
                markdown={dummyTreatment}
              />
            </div>
          </TabsContent>

          <TabsContent value="tests-results">
            <div className="space-y-4 md:space-y-8">
              <div>
                <h2 className="text-lg md:text-xl font-semibold mb-2 md:mb-4">Tests & Results</h2>
                <div className="text-gray-500 text-center py-4 md:py-8">
                  No test results available yet.
                </div>
              </div>
            </div>
          </TabsContent>
        </Tabs>
      </div>

      {/* Floating chat button for mobile */}
      <button
        className="lg:hidden fixed bottom-2 right-4 bg-[var(--primary)] text-white p-3 rounded-full shadow-lg z-49"
        onClick={toggleChat}
      >
        <MessageCircle size={24} />
      </button>

      {/* Floating save button */}
      {/* <Button
        onClick={saveChanges}
        disabled={!unsavedChanges || isLoading || isSaving}
        className={`fixed bottom-4 right-4 z-49 ${unsavedChanges && !isSaving ? '' : 'opacity-50'
          } flex items-center shadow-lg rounded-full p-3 group`}
        title="Save Changes"
      >
        {isSaving ? (
          <Loader2 className="h-5 w-5 md:h-6 md:w-6 animate-spin" />
        ) : (
          <Save className="h-5 w-5 md:h-6 md:w-6" />
        )}
        <span className="ml-2 hidden lg:inline">
          {isSaving ? 'Saving...' : unsavedChanges ? 'Save Changes' : 'No Changes'}
        </span>
        <span className="absolute bottom-full right-0 mb-2 bg-gray-800 text-white text-xs py-1 px-2 rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200 hidden lg:block">
          Ctrl+S
        </span>
      </Button> */}

      {/* <SaveChangesDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onSave={handleSave}
        onDiscard={handleDiscard}
      /> */}
    </div>
  );
};

export default withLayout(DoctorLayout)(Analysis);
